<template>
  <span>{{ dateDiff }}</span>
</template>
<script lang="js">
import moment from "moment-timezone";

export default {
  name: 'StartAndEndDateDifferences',
  props: {
    startDate: {
      type: [Object, String, Date],
      required: true,
    },
    endDate: {
      type: [Object, String, Date],
      default: null,
      required: false,
    },
    format: {
      type: String,
      default: 'dddd [d.] D[.] MMM[.]',
      required: false,
    },
    showEndFormat: {
      type: Boolean,
      default: true,
      required: false,
    },
    isAllDayEvent: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  computed: {
    startDateDateInstance() {
      return moment(this.startDate);
    },
    endDateDateInstance() {
      return moment(this.endDate);
    },
    startDateFormat() {
      return this.isAllDayEvent ?
      this.startDateDateInstance.utc().format(this.format) :
      this.startDateDateInstance.format(this.format);
    },
    endDateFormat() {
      return this.isAllDayEvent ?
       this.endDateDateInstance.utc().format(this.format) :
       this.endDateDateInstance.format(this.format);
    },
    dateDiff() {
      let date = this.startDateFormat;
      const endDate = this.endDateFormat;
      if (date !== endDate && this.showEndFormat) {
        date += ` - ${endDate}`;
      }
      return date;
    },
  }
}
</script>
