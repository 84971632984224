
















import { PropType } from 'vue';
import { ButtonToggleOption } from './ButtonToggleOption.model';
export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    options: { type: Array as PropType<ButtonToggleOption[]>, required: true },
    selected: { type: String, default: null },
  },
  emits: ['change'],
  computed: {},
  methods: {
    setSelected(selected: string) {
      this.$emit('change', selected);
    },
  },
};
