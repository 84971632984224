<template>
  <div class="calendar-meeting-event-drawer">
    <b-form>
      <b-row>
        <b-col
          v-if="institutionsThatCanBeSeen.length > 1 && delegatedContext.institutionProfileId == null && !isCoOrganizer"
          class="form-margin-bottom col-12 col-md-6"
        >
          <label class="thin">{{ 'CALENDAR_TITLE_CHOOSE_INSTITUTION' | fromTextKey }}</label>
          <aula-select
            v-model="eventForm.institutionCode"
            placeholder="Select"
            :disabled="isEditMode"
            @change="isEdited ? $refs.confirmSwitchInstitution.show() : buttonConfirmSwitchInstitution()"
          >
            <aula-option
              v-for="institution in institutionsThatCanBeSeen"
              :id="institution.institutionCode"
              :key="institution.institutionCode"
              :label="institution.name"
              :value="institution.institutionCode"
              :disabled="!institution.hasCreatingMeetingPermission"
            />
          </aula-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="form-margin-bottom col-12 col-md-6">
          <label class="thin">{{ 'CALENDAR_LABEL_EVENT_TYPE' | fromTextKey }}</label>
          <aula-select
            v-model="eventForm.type"
            :disabled="isEditMode"
            @change="isEdited ? $refs.confirmChangeEventTypeModal.show() : changeEventType()"
          >
            <aula-option
              v-for="meetingEventType in meetingEventTypes"
              :key="meetingEventType.type"
              :label="meetingEventType.label"
              :value="meetingEventType.type"
            />
          </aula-select>
        </b-col>
        <b-col md="5" class="form-margin-bottom col-12 col-md-6">
          <label>{{ 'CALENDAR_LABEL_EVENT_TITLE' | fromTextKey }} <span class="mandatory">*</span></label>
          <b-form-input
            id="eventTitle"
            ref="eventTitle"
            v-model="eventForm.title"
            :placeholder="'CALENDAR_LABEL_EVENT_PLACEHOLDER_TITLE' | fromTextKey"
            type="text"
            :disabled="isHandlingOthersEvent"
            :class="showMissingTitleAlert ? 'red-border' : ''"
            @input="showMissingTitleAlert = false"
          />
          <b-alert variant="danger" :show="showMissingTitleAlert">
            {{ 'CALENDAR_ALERT_TITLE' | fromTextKey }}
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="form-margin-bottom col-12 col-md-6">
          <label>{{ 'CALENDAR_LABEL_EVENT_PERSONS' | fromTextKey }} <span class="mandatory">*</span></label>
          <b-input-group id="search-invitees" class="search-module">
            <aula-search-recipients
              id="eventInvitees"
              ref="calendarInviteeSearch"
              :css-class="showMissingMembersAlert ? 'red-border' : ''"
              :disabled="isDisabledInviteesSearch"
              :from-module="moduleTypes.EVENT"
              :scope-employees-to-institution="true"
              :reset-input="resetRecipientsInput"
              :clearable="true"
              :invite-members-when-selecting-group="true"
              :invite-members-when-selecting-group-portal-role="true"
              :invite-group-when-adding-members="true"
              :include-self="shouldIncludeSelf"
              :close-dropdown-after-select="false"
              :filter-portal-roles-when-adding-members="apiPortalRoles"
              :hide-portal-roles-when-expanding-group="disabledPortalRolesWhenInviting"
              :disable-portal-roles-members-when-expanding-group="disabledPortalRolesWhenInviting"
              :existing-participants="existingInvitees"
              :extra-a-p-i-params="{ groupSearchScope: groupTypes.INSTITUTIONAL }"
              :add-existing-participants="false"
              :portal-roles="apiPortalRoles"
              :institution-code="eventForm.institutionCode"
              :select-all="false"
              url="findRecipients"
              @emitSelected="addInvitees"
            />
            <b-input-group-addon>
              <i class="icon-Aula_search input-group-search-addon" />
            </b-input-group-addon>
          </b-input-group>
          <b-alert variant="danger" :show="showMissingMembersAlert">
            {{ 'CALENDAR_ALERT_ATTENDEES' | fromTextKey }}
          </b-alert>
          <b-alert variant="danger" :show="isInviteeCoOrganizer">
            {{ 'CALENDAR_ALERT_INVITEES_ARE_CORGANIZERS' | fromTextKey }}
          </b-alert>
        </b-col>
        <b-col v-if="isMobile && (isEditMode || parent === parentTypes.GROUP)" cols="12" class="form-margin-bottom">
          <div class="event_resources">
            <span v-for="(invitee, i) in existingInvitees" :key="i" class="el-tag el-tag--resource">{{
              invitee.name
            }}</span>
          </div>
        </b-col>
        <!-- CO ORGANIZER START -->
        <b-col v-if="canEditCoOrganizers" class="form-margin-bottom col-12 col-md-6">
          <label for="eventOrganizers">{{ 'CALENDAR_LABEL_ORGANIZERS' | fromTextKey }}</label>
          <b-input-group class="search-module">
            <aula-search-recipients
              id="eventOrganizers"
              :doc-types-api="[docTypes.PROFILE]"
              :portal-roles="[portalRoles.EMPLOYEE]"
              :existing-participants="selectedOrganizers"
              :hidden-participants="hiddenOrganizers"
              :reset-input="resetRecipientsInput"
              :close-dropdown-after-select="false"
              :clearable="true"
              :institution-code="eventForm.institutionCode"
              :scope-employees-to-institution="true"
              placeholder-textkey="ARIA_LABEL_CALENDAR_SEARCH"
              hover-text="ARIA_LABEL_CALENDAR_SEARCH_FOR_ORGANIZERS"
              :include-self="false"
              :from-module="moduleTypes.EVENT"
              :select-all="false"
              @emitSelected="onSelectOrganizers"
              @emitRemoveTag="removeExistingOrganizer"
            >
              <template v-if="isEditMode" #reset-warning>
                {{ $t('warning.remove_all_organizers_from_meeting_event') }}
                <br />
                {{ $t('warning.are_you_sure_you_want_to_continue') }}
              </template>
            </aula-search-recipients>
            <b-input-group-addon>
              <i class="icon-Aula_search input-group-search-addon" />
            </b-input-group-addon>
          </b-input-group>
        </b-col>
        <!-- CO ORGANIZER END -->
      </b-row>
      <b-row v-if="!isMobile && (isEditMode || parent === parentTypes.GROUP)">
        <b-col cols="12" class="form-margin-bottom">
          <div class="event_resources">
            <span v-for="(invitee, i) in existingInvitees" :key="i" class="el-tag el-tag--resource">{{
              invitee.name
            }}</span>
          </div>
        </b-col>
      </b-row>
      <hr class="line mb-0" />
      <b-row v-if="isParentalMeeting" class="mt-4">
        <b-col cols="12" md="7" lg="6">
          <div class="d-flex align-items-center">
            <b-checkbox v-model="isAllowedMultipleParticipants" :disabled="isEditMode">
              {{ 'CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS' | fromTextKey }}
            </b-checkbox>
            <help-box
              id="tooltip-multiple-participants"
              :title="'CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS_HELP_TEXT_TITLE' | fromTextKey"
            >
              <template #body>
                <p>{{ 'CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS_HELP_TEXT_1' | fromTextKey }}</p>
                <p>{{ 'CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS_HELP_TEXT_2' | fromTextKey }}</p>
              </template>
            </help-box>
          </div>
          <template v-if="isAllowedMultipleParticipants">
            <b-input
              v-model.number="eventForm.numberOfParticipantsPerTimeSlot"
              type="number"
              class="mt-2"
              :class="{ 'red-border': !isValidNumberOfParticipants }"
              :disabled="isEditMode"
              :placeholder="'CALENDAR_LABEL_EVENT_WRITE_NUMBER_OF_PARTICIPANTS' | fromTextKey"
            />
            <b-alert variant="danger" :show="!isValidNumberOfParticipants" class="mt-1">
              {{ 'CALENDAR_ALERT_NUMBER_OF_TIMESLOT_PARTICIPANTS' | fromTextKey }}
            </b-alert>
          </template>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" md="7" sm="12" class="form-margin-bottom">
          <label id="event-timeslots" class="thin"
            >{{ 'CALENDAR_TITLE_MEETING_DURATION' | fromTextKey }} <span class="mandatory">*</span></label
          >
          <aula-select
            v-model="eventForm.meetingDuration"
            :disabled="isEditMode"
            :class="showMissingMeetingDuration ? 'red-border' : ''"
            @change="changeMeetingDuration"
          >
            <aula-option v-for="duration in durations" :key="duration" :label="duration + ' min'" :value="duration" />
          </aula-select>
        </b-col>
        <b-col lg="4" md="7" sm="12" class="form-margin-bottom">
          <label class="thin">{{ 'CALENDAR_TITLE_PAUSE_AFTER' | fromTextKey }} </label>
          <aula-select v-model="eventForm.meetingsBetweenBreaks" :disabled="isEditMode">
            <aula-option key="0" label="0" :value="0" />
            <aula-option v-for="index in 99" :key="index" :label="index" :value="index" />
          </aula-select>
        </b-col>
        <b-col lg="4" md="7" sm="12" class="form-margin-bottom">
          <label class="thin">{{ 'CALENDAR_TITLE_PAUSE_DURATION' | fromTextKey }}</label>
          <aula-select v-model="eventForm.breakLength" :disabled="isEditMode || eventForm.meetingsBetweenBreaks === 0">
            <aula-option v-for="meeting in meetingTimes" :key="meeting" :label="meeting + ' min'" :value="meeting" />
          </aula-select>
        </b-col>
      </b-row>
      <b-alert variant="danger" :show="showMissingMeetingDuration">
        {{ 'CALENDAR_ALERT_INVALID_MEETING_DURATION' | fromTextKey }}
      </b-alert>
      <hr class="line mb-0" />
      <b-row v-if="isParentalMeeting">
        <b-col>
          <label>{{ 'CALENDAR_MEETING_SUGGESTION_LABEL' | fromTextKey }}</label>
          <div v-if="invitedPartcipants.length > 0">
            {{ 'CALENDAR_MEETING_SUGGESTED_TIMESLOTS' | fromTextKey }}
            <strong
              >{{ numberOfSlotsSuggested }}
              {{ numberOfSlotsSuggested === 1 ? 'CALENDAR_MEETING' : 'CALENDAR_MEETINGS' | fromTextKey }}</strong
            >.
          </div>
          <div>
            {{ 'CALENDAR_MEETING_ADDED_TIMESLOTS' | fromTextKey }}
            <strong
              >{{ numberOfSlotsAdded }}
              {{ numberOfSlotsAdded === 1 ? 'CALENDAR_MEETING' : 'CALENDAR_MEETINGS' | fromTextKey }}</strong
            >.
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showTimeSlotHeader" class="hide-tablet-down">
        <b-col md="3" cols="4">
          <label class="thin">{{ 'CALENDAR_LABEL_MEETING_DATE' | fromTextKey }} <span class="mandatory">*</span></label>
        </b-col>
        <b-col md="2" cols="4">
          <label class="thin"
            >{{ 'CALENDAR_LABEL_MEETING_FROM_TIME' | fromTextKey }} <span class="mandatory">*</span></label
          >
        </b-col>
        <b-col md="2" cols="4">
          <label class="thin"
            >{{ 'CALENDAR_LABEL_MEETING_TO_TIME' | fromTextKey }} <span class="mandatory">*</span></label
          >
        </b-col>
        <b-col md="4" cols="11">
          <label class="thin"
            >{{ 'CALENDAR_LABEL_MEETING_LOCATION' | fromTextKey }} <span class="mandatory">*</span></label
          >
        </b-col>
        <b-col md="1" cols="1" class="remove_timeslot" />
      </b-row>
      <b-row
        v-for="(allocationTime, index) in eventForm.timeSlots"
        :key="allocationTime.timeSlotIndex"
        class="form-margin-bottom"
      >
        <b-col lg="3" cols="9" class="margin-bottom">
          <label class="hide-tablet-up">
            {{ 'CALENDAR_LABEL_MEETING_DATE' | fromTextKey }} <span class="mandatory">*</span>
          </label>
          <el-date-picker
            v-model="allocationTime.date"
            :data-test="`timeslot-picker${index + 1}`"
            :disabled="allocationTime.isEdit"
            :format="$parent.defaultDateFormat"
            :value-format="$parent.defaultDateValueFormat"
            type="date"
            :picker-options="$parent.availableDate"
            :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
            :class="{
              'red-border': allocationTime.showDateAlert,
              elPickerActive: isMeetingDateClicked,
            }"
            @change="validateTimeslotDate(index)"
            @focus="isMeetingDateClicked = true"
            @blur="isMeetingDateClicked = false"
          />
        </b-col>
        <b-col v-if="!allocationTime.isEdit" cols="1" class="remove_timeslot hide-mobile-up">
          <i class="icon-Aula_close" :aria-label="'ARIA_LABEL_DELETE' | fromTextKey" @click="removeTimeSlot(index)" />
        </b-col>
        <b-col lg="4" cols="12" class="margin-bottom">
          <b-row>
            <b-col>
              <label class="hide-tablet-up"
                >{{ 'CALENDAR_LABEL_MEETING_FROM_TIME' | fromTextKey }} <span class="mandatory">*</span></label
              >
              <AulaTimepicker
                id="startTime"
                v-model="allocationTime.startTime"
                format="HH:mm"
                :hour-label="'HOUR' | fromTextKey"
                :minute-label="'MINUTE' | fromTextKey"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                :aria-label="'ARIA_LABEL_CALENDAR_START_TIME' | fromTextKey"
                :disabled="allocationTime.isEdit"
                :class="{
                  'red-border': allocationTime.showStartTimeAlert || allocationTime.showOverlapAlert,
                }"
                hide-clear-button
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :scroll-to-hour="12"
                advanced-keyboard
                @change="validateTimeslotStartTime(index)"
              />
            </b-col>
            <b-col>
              <label class="hide-tablet-up"
                >{{ 'CALENDAR_LABEL_MEETING_TO_TIME' | fromTextKey }} <span class="mandatory">*</span></label
              >
              <AulaTimepicker
                id="endTime"
                v-model="allocationTime.endTime"
                format="HH:mm"
                :hour-label="'HOUR' | fromTextKey"
                :minute-label="'MINUTE' | fromTextKey"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                :aria-label="'ARIA_LABEL_CALENDAR_END_TIME' | fromTextKey"
                :disabled="allocationTime.isEdit"
                :class="{
                  'red-border': allocationTime.showEndTimeAlert || allocationTime.showOverlapAlert,
                }"
                hide-clear-button
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :scroll-to-hour="12"
                advanced-keyboard
                @change="validateTimeslotEndTime(index)"
              />
            </b-col>
          </b-row>
          <b-alert variant="danger" :show="allocationTime.showOverlapAlert">
            {{ 'CALENDAR_ALERT_CONFLICT_TIMESLOT' | fromTextKey }}
          </b-alert>
        </b-col>
        <b-col lg="4" cols="12" class="margin-bottom">
          <label class="hide-tablet-up"
            >{{ 'CALENDAR_LABEL_MEETING_LOCATION' | fromTextKey }} <span class="mandatory">*</span></label
          >
          <b-input-group class="search-module" style="width: 100%">
            <aula-search
              ref="timeSlotLocation"
              :data-test="`timeslot-location${index + 1}`"
              :class="allocationTime.showLocationAlert ? 'red-border' : ''"
              :search-result-loader="searchLocations"
              :results="primaryResourceSearchResults[index]"
              :existing-participants="existingLocation(index)"
              :allow-create="true"
              :multiple="true"
              :multiple-limit="1"
              :instance="index"
              :reset-search-input="resetResources"
              :instance-to-reset="instanceToReset"
              :select-all="false"
              :disabled="!allocationTime.date || !allocationTime.startTime || !allocationTime.endTime"
              style="width: 100%"
              css-class="wraptext"
              @emitFocus="searchLocations('', index)"
              @emitSelected="addPrimaryResourceToList"
            >
              <template #optionContent="optionData">
                <span v-html="optionData.label" />
                <span class="text-grey">({{ 'CALENDAR_AVAILABLE' | fromTextKey }})</span>
              </template>
            </aula-search>
            <b-input-group-addon>
              <i class="icon-Aula_search input-group-search-addon" />
            </b-input-group-addon>
          </b-input-group>
        </b-col>
        <b-col v-if="!allocationTime.isEdit" cols="1" class="remove_timeslot hide-mobile-down">
          <i class="icon-Aula_close" :aria-label="'ARIA_LABEL_DELETE' | fromTextKey" @click="removeTimeSlot(index)" />
        </b-col>
        <b-col lg="7" cols="12">
          <b-alert
            variant="danger"
            :show="allocationTime.showDateAlert || allocationTime.showStartTimeAlert || allocationTime.showEndTimeAlert"
          >
            {{ 'CALENDAR_ALERT_INVALID_TIMESLOT' | fromTextKey }}
          </b-alert>
        </b-col>
        <b-col lg="5" cols="12">
          <b-alert variant="danger" :show="allocationTime.showLocationAlert">
            <span>{{ 'CALENDAR_ALERT_INVALID_MEETING_LOCATION' | fromTextKey }}</span>
          </b-alert>
        </b-col>
      </b-row>
      <b-row v-if="!isHandlingOthersEvent" class="margin-top">
        <b-col md="3" cols="12" class="form-margin-bottom">
          <b-button variant="primary" class="send-btn" @click="addTimeSlot()">
            {{ 'CALENDAR_LABEL_MEETING_EVENT_ADD_TIME' | fromTextKey }}
          </b-button>
        </b-col>
        <b-col cols="12" md="9">
          <b-alert variant="danger" :show="isMissingTimeslots">
            {{ 'CALENDAR_ALERT_MISSING_TIMESLOT' | fromTextKey }}
          </b-alert>
        </b-col>
      </b-row>
      <template v-if="isParentalMeeting">
        <b-row v-if="missingTime > 0" class="mt-2">
          <b-col>
            <AulaAlert>
              {{ missingTimeslotAlert }}
            </AulaAlert>
          </b-col>
        </b-row>
        <b-row v-if="excessTime > 0" class="mt-2">
          <b-col>
            <AulaAlert>
              {{ excessTimeslotAlert }}
            </AulaAlert>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col lg="4" md="7" sm="12" class="form-margin-bottom">
          <label>{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}</label>
          <el-date-picker
            id="deadline-date"
            v-model="eventForm.responseDeadline"
            :format="$parent.defaultDateFormat"
            :value-format="$parent.defaultDateValueFormat"
            type="date"
            :aria-label="'ARIA_LABEL_CALENDAR_START_DATE' | fromTextKey"
            :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
            :picker-options="availableDeadlineDate"
            :disabled="isHandlingOthersEvent || isResponseDeadlineDisabled"
            @change="showInvalidDeadlineAlert = false"
          />
          <b-alert variant="danger" :show="showInvalidDeadlineAlert">
            {{ 'CALENDAR_LABEL_EVENT_INVALID_DEADLINE' | fromTextKey }}
          </b-alert>
        </b-col>
      </b-row>
      <hr class="line mb-0" />
      <b-row class="mb-2">
        <b-col>
          <label>{{ 'CALENDAR_LABEL_EVENT_DESCRIPTION' | fromTextKey }}</label>
          <editor
            v-if="!isLoadingEdge"
            id="message-textarea"
            ref="message-textarea"
            :key="institutionCode"
            v-model="eventForm.description"
            toolbar-type="basic"
            :max-length="10000"
            :value="eventForm.description"
            :clean="eventForm.description === ''"
            :aria-label="'ARIA_LABEL_WRITE_EVENT_CONTENT'"
            :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
            :disabled="isHandlingOthersEvent"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="isHandlingOthersEvent">
            <attachment-thumbnails :attachments="existingAttachments" :parent="parentTypes.CALENDAR" />
            <attachment-list :attachments="existingAttachments" :show-media="true" />
          </div>
          <upload
            v-else
            ref="mediaUpload"
            class="upload"
            tooltip-text="CALENDAR_MEETING_UPLOAD_HELP"
            :selected-institution-code-for-filter="eventForm.institutionCode"
            :limit-file-upload="200"
            :existing-media="existingAttachments"
            :institution-code-for-tagging="eventForm.institutionCode"
            @mediaUpdated="filesChanged"
          />
        </b-col>
      </b-row>
      <template v-if="isSettingSectionVisible">
        <hr class="line mb-0" />
        <label class="font-weight-bold"> {{ 'CALENDAR_LABEL_EVENT_SETTINGS_FOR_TIME_SLOT_EVENT' | fromTextKey }}</label>
        <b-row
          v-if="eventForm.type !== eventTypes.PERFORMANCE_MEETING && !isCoOrganizer"
          class="form-margin-bottom mb-4 mb-lg-2"
        >
          <b-col class="event-settings col-12 col-lg-6">
            <div v-if="!isMobile" class="d-flex align-items-center">
              <b-form-checkbox
                id="childRequired"
                v-model="eventForm.childRequired"
                class="mr-0"
                :disabled="isHandlingOthersEvent"
                @input="onUpdateChildRequired"
              >
                {{ 'CALENDAR_TITLE_WITH_CHILDREN' | fromTextKey }}
              </b-form-checkbox>
              <help-box
                id="tooltip-mobile-children-help"
                :title="$options.filters.fromTextKey('CALENDAR_TITLE_WITH_CHILDREN_HELP_TITLE')"
                :body="$options.filters.fromTextKey('CALENDAR_TITLE_WITH_CHILDREN_HELP')"
              />
            </div>
            <div v-else class="mobile-switch">
              <div class="custom-label">
                <label> {{ 'CALENDAR_TITLE_WITH_CHILDREN' | fromTextKey }} </label>
                <help-box
                  id="tooltip-children-help"
                  :title="$options.filters.fromTextKey('CALENDAR_TITLE_WITH_CHILDREN_HELP_TITLE')"
                  :body="$options.filters.fromTextKey('CALENDAR_TITLE_WITH_CHILDREN_HELP')"
                />
              </div>
              <el-switch
                v-model="eventForm.childRequired"
                :aria-label="'CALENDAR_TITLE_WITH_CHILDREN' | fromTextKey"
                :width="(width = 48)"
                class="pull-right"
                :disabled="isHandlingOthersEvent"
                @change="onUpdateChildRequired"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="canAddEventToInstitutionCalendar" class="form-margin-bottom mb-4 mb-lg-2">
          <b-col class="event-settings col-12 col-lg-6">
            <b-form-checkbox
              v-if="!isMobile"
              v-model="addToMineAndInstitutionCalendar"
              :disabled="isEditMode && !isCreator"
              @input="changedAddToMineAndInstitutionCalendar"
            >
              {{ 'CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR' | fromTextKey }}
              <template
                v-if="
                  delegatedContext.institutionCode != null &&
                  institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                "
              >
                {{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
              </template>
              <template
                v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
              >
                {{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
              </template>
            </b-form-checkbox>
            <div v-else class="mobile-switch">
              <label>
                {{ 'CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR' | fromTextKey }}
                <template
                  v-if="
                    delegatedContext.institutionCode != null &&
                    institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                  "
                >
                  {{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
                </template>
                <template
                  v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
                >
                  {{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
                </template>
              </label>
              <el-switch
                v-model="addToMineAndInstitutionCalendar"
                :aria-label="'CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR' | fromTextKey"
                :width="(width = 48)"
                class="pull-right"
                :disabled="isEditMode && !isCreator"
                @change="changedAddToMineAndInstitutionCalendar"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="canAddEventOnlyToInstitutionCalendar" class="form-margin-bottom mb-4 mb-lg-2">
          <b-col class="event-settings col-12 col-lg-6">
            <b-form-checkbox
              v-if="!isMobile"
              v-model="addOnlyToInstitutionCalendar"
              :disabled="isEditMode && !isCreator"
              @input="changedAddToOnlyInstitutionCalendar"
            >
              {{ 'CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR' | fromTextKey }}
              <template
                v-if="
                  delegatedContext.institutionCode != null &&
                  institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                "
              >
                {{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
              </template>
              <template
                v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
              >
                {{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
              </template>
            </b-form-checkbox>
            <div v-else class="mobile-switch">
              <label>
                {{ 'CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR' | fromTextKey }}
                <template
                  v-if="
                    delegatedContext.institutionCode != null &&
                    institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                  "
                >
                  {{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
                </template>
                <template
                  v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
                >
                  {{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
                </template>
              </label>
              <el-switch
                v-model="addOnlyToInstitutionCalendar"
                :aria-label="'CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR' | fromTextKey"
                :width="(width = 48)"
                class="pull-right"
                :disabled="isEditMode && !isCreator"
                @change="changedAddToOnlyInstitutionCalendar"
              />
            </div>
          </b-col>
        </b-row>
      </template>
      <hr class="line" />
      <aula-form-footer
        :show-line="false"
        :is-loading="isLoading"
        :submit-text="isEditMode ? 'BUTTON_SAVE' : 'BUTTON_CREATE'"
        @cancelClicked="closeEventForm()"
        @submitClicked="onSaveClicked"
      />
    </b-form>
    <aula-modal
      ref="confirmChangeEventTypeModal"
      @cancelClicked="buttonCancelChangeEventType"
      @closeClicked="buttonCancelChangeEventType"
      @okClicked="buttonConfirmChangeEventType"
    >
      {{ 'CALENDAR_NOTIFY_MESSAGE_CHANGE_EVENT_TYPE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="confirmSwitchInstitution"
      @cancelClicked="buttonCancelSwitchInstitution"
      @okClicked="buttonConfirmSwitchInstitution"
    >
      {{ 'CALENDAR_CONFIRM_SWITCH_INSTITUTION_1' | fromTextKey }} <br />
      {{ 'CALENDAR_CONFIRM_SWITCH_INSTITUTION_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="cancelForm()"
    >
      {{ 'CALENDAR_CANCEL_INFO' | fromTextKey }} <br />
      {{ 'CALENDAR_CANCEL_INFO_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="removeResourcesWhenChangingDateTimeModal"
      ok-text="BUTTON_YES"
      :disable-close="true"
      @okClicked="
        $refs.removeResourcesWhenChangingDateTimeModal.hide();
        removeResources();
      "
      @cancelClicked="
        $refs.removeResourcesWhenChangingDateTimeModal.hide();
        resetDateTime();
      "
    >
      {{ 'CALENDAR_REMOVE_RESOURCES_WHEN_CHANGING_DATE_TIME' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="tooManyRecipientsModal" :show-cancel="false" @okClicked="$refs.tooManyRecipientsModal.hide()">
      {{ 'CALENDAR_ALERT_TOO_MANY_RECIPIENTS' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="removingCoOrganizerWarning"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="BUTTON_YES"
      cancel-text="CANCEL"
      @cancelClicked="cancelRemovalOfExistingOrganizer"
      @okClicked="acceptRemovalOfExistingCoOrganizer()"
    >
      {{ 'CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_MEETING_EVENT' | fromTextKey }}<br />
      {{ 'CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_EVENT_CONFIRM' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="unavailableResourcesModal"
      :show-cancel="false"
      :disable-close="true"
      @okClicked="
        $refs.unavailableResourcesModal.hide();
        removeUnavailableResources();
      "
    >
      {{ 'CALENDAR_ALERT_UNAVAILABLE_RESOURCES_1' | fromTextKey }} ({{
        unavailableResources.map(r => r.name).join(', ')
      }})
      {{ 'CALENDAR_ALERT_UNAVAILABLE_RESOURCES_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="editOthersEventWarningModal"
      @cancelClicked="$refs.editOthersEventWarningModal.hide()"
      @okClicked="onConfirmEditOthersEvent"
    >
      {{ 'CALENDAR_EDIT_OTHERS_EVENT_WARNING_1' | fromTextKey }}<br />
      {{ 'CALENDAR_EDIT_OTHERS_EVENT_WARNING_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="suggestedTimeslotsWarning"
      ok-text="YES"
      @cancelClicked="$refs.suggestedTimeslotsWarning.hide()"
      @okClicked="onConfirmCreateTimeslots"
    >
      <template v-if="numberOfSlotsAdded < numberOfSlotsSuggested">{{
        'CALENDAR_MISSING_TIMESLOTS_WARNING' | fromTextKey
      }}</template>
      <template v-if="numberOfSlotsAdded > numberOfSlotsSuggested">{{
        'CALENDAR_EXCESS_TIMESLOTS_WARNING' | fromTextKey
      }}</template
      ><br />
      {{ 'CALENDAR_SAVE_EVENT_CONFIRMATION' | fromTextKey }}
    </aula-modal>
    <toastr ref="externalRecipientsToastr" variant="warning" icon-font-size="1em" circle-background="white">
      {{ 'MESSAGE_ADD_RECIPIENTS_EXTERNAL' | fromTextKey }}
    </toastr>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { docTypes } from '../../../shared/enums/docTypes';
import moment from 'moment-timezone';
import Vue from 'vue';
import editor from '../../../shared/components/Editor';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import upload from '../../../shared/components/Upload.vue';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import AulaTimepicker from '../../../shared/components/Timepicker';
import cloneDeep from 'lodash/cloneDeep';
import unionBy from 'lodash/unionBy';
import uniqueId from 'lodash/uniqueId';
import isEqualWith from 'lodash/isEqualWith';
import { filterGroupMembershipInstitutions, getDefaultInstitution } from '../../../shared/utils/institutionUtil';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import AttachmentList from '../../../shared/components/AttachmentList';
import * as dateUtil from '../../../shared/utils/dateUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import { calendarUtil } from '../../../shared/utils/calendarUtil';
import AulaAlert from '../../../shared/components/AulaAlert.vue';
import { isAfter } from '../../../shared/utils/dateUtil';

export default {
  components: {
    AulaAlert,
    AttachmentList,
    AttachmentThumbnails,
    editor,
    upload,
    AulaSearchRecipients,
    AulaTimepicker,
  },
  inject: {
    getIsCreator: providerKeyTypes.isCurrentEventCreator,
    getIsCoOrganizer: providerKeyTypes.isCurrentEventCoOrganizer,
    getCanHandleOthersEvents: { from: providerKeyTypes.canHandleOthersEvents, default: () => () => false },
    loadEventLocations: providerKeyTypes.loadEventLocations,
  },
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
    canEditCoOrganizers: { type: Boolean, default: false },
  },
  data() {
    return {
      docTypes,
      emptyEventForm: {},
      resetRecipientsInput: false,
      availableDeadlineDate: {},
      hiddenOrganizers: [],
      eventForm: {
        id: '',
        institutionCode: null,
        creatorInstProfileId: null,
        title: '',
        type: '',
        startDateTime: '',
        endDateTime: '',
        invitees: [],
        invitedGroups: [],
        invitedGroupIds: [],
        meetingDuration: null,
        meetingsBetweenBreaks: 0,
        timeSlots: [],
        breakLength: 0,
        childRequired: false,
        addToInstitutionCalendar: false,
        responseDeadline: null,
        hideInOwnCalendar: false,
        description: '',
        attachments: [],
        oldStartDateTime: '',
        oldEndDateTime: '',
        numberOfParticipantsPerTimeSlot: null,
      },
      addOnlyToInstitutionCalendar: false,
      addToMineAndInstitutionCalendar: false,
      eventType: '',
      isUploaded: false,
      primaryResourceResults: [],
      existingOrganizers: [],
      tmpOrganizers: [],
      selectedOrganizers: [],
      existingPrimaryResources: [],
      existingInvitees: [],
      existingAttachments: [],
      institutionCode: null,
      isMeetingDateClicked: false,
      timepickerSettings: timepickerSettings,
      permissionEnum: permissionEnum,
      portalRoles: portalRoles,
      eventTypes: eventTypeEnum,
      parentTypes: parentTypes,
      moduleTypes: moduleTypes,
      groupTypes: groupTypes,
      meetingEventTypes: [],
      durations: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      meetingTimes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      showMissingMembersAlert: false,
      showMissingTitleAlert: false,
      showMissingMeetingDuration: false,
      showInvalidDeadlineAlert: false,
      isInviteeCoOrganizer: false,
      isLoading: false,
      isLoadingEdge: true,
      resetResources: false,
      instanceToReset: null,
      changedTimeSlot: null,
      clonedTimeSlots: [],
      hasExternalRecipient: false,
      unavailableResources: [],
      isMissingTimeslots: false,
      isAllowedMultipleParticipants: false,
      isValidNumberOfParticipants: true,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      group: types.GET_ACTIVE_GROUP,
      activeEvent: types.GET_CURRENT_EVENT,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS_LIGHT,
      sameAuthorityInstitutions: types.GET_SAME_AUTHORITY_INSTITUTIONS,
      unavailableResourceIds: types.GET_UNAVAILABLE_RESOURCES,
      hasPermissionOnInstitutionGroup: types.HAS_PERMISSION_ON_INSTITUTION_GROUP,
    }),
    isDisabledInviteesSearch() {
      return (
        !this.hasPermission(permissionEnum.INVITE_TO_EVENT) ||
        this.parent === parentTypes.GROUP ||
        this.isHandlingOthersEvent
      );
    },
    numberOfMeetingsPerPause() {
      return this.eventForm.meetingsBetweenBreaks || Infinity;
    },
    missingTimeslotAlert() {
      const isApproximate = this.eventForm.timeSlots.length > 1;
      let calendarMeetingText = this.$options.filters.fromTextKey('CALENDAR_MEETING');
      if (this.numberOfSlotsMissing !== 1) {
        calendarMeetingText = this.$options.filters.fromTextKey('CALENDAR_MEETINGS');
      }
      return this.$options.filters.fromTextKey('CALENDAR_WARNING_MISSING_TIMESLOT', {
        missingSlots: `${this.numberOfSlotsMissing} ${calendarMeetingText}`,
        missingTime: this.formatSlotTime(this.missingTime, isApproximate),
      });
    },
    excessTimeslotAlert() {
      let calendarMeetingText = this.$options.filters.fromTextKey('CALENDAR_MEETING');
      if (this.numberOfSlotsMissing !== 1) {
        calendarMeetingText = this.$options.filters.fromTextKey('CALENDAR_MEETINGS');
      }
      return this.$options.filters.fromTextKey('CALENDAR_WARNING_EXCESS_TIMESLOT', {
        excessSlots: `${this.numberOfSlotsExcess} ${calendarMeetingText}`,
        excessTime: this.formatSlotTime(this.excessTime, true),
      });
    },
    isParentalMeeting() {
      return calendarUtil.getIsParentalMeeting(this.eventForm.type);
    },
    invitedPartcipants() {
      const invitees = unionBy(this.eventForm.invitees, this.existingInvitees, 'id');
      let portalRole = portalRoles.CHILD;
      if (this.eventForm.type === eventTypeEnum.PERFORMANCE_MEETING) {
        portalRole = portalRoles.EMPLOYEE;
      }
      return invitees.filter(invitee => invitee.portalRole === portalRole);
    },
    meetingSections() {
      return this.eventForm.timeSlots.filter(slot => slot.startTime && slot.endTime);
    },
    numberOfSlotsSuggested() {
      let numberOfParticipantsPerTimeSlot = 1;
      if (this.isAllowedMultipleParticipants && this.eventForm.numberOfParticipantsPerTimeSlot) {
        numberOfParticipantsPerTimeSlot = this.eventForm.numberOfParticipantsPerTimeSlot;
      }
      return Math.ceil(this.invitedPartcipants.length / numberOfParticipantsPerTimeSlot);
    },
    numberOfSlotsAdded() {
      if (!this.eventForm.meetingDuration) {
        return 0;
      }

      let numberOfSlotsAdded = 0;
      for (const section of this.meetingSections) {
        const sectionTime = Math.max(dateUtil.getTimeDifference(section.startTime, section.endTime), 0);
        let totalTime = 0;
        let numberOfMeetingsUntilBreak = this.numberOfMeetingsPerPause;

        do {
          numberOfMeetingsUntilBreak--;
          totalTime = totalTime + this.eventForm.meetingDuration;
          if (totalTime <= sectionTime) {
            numberOfSlotsAdded++;
          }
          if (numberOfMeetingsUntilBreak === 0) {
            totalTime = totalTime + this.eventForm.breakLength;
            numberOfMeetingsUntilBreak = this.numberOfMeetingsPerPause;
          }
        } while (totalTime < sectionTime);
      }
      return numberOfSlotsAdded;
    },
    suggestedTime() {
      return this.getDurationForSlots(this.numberOfSlotsSuggested);
    },
    missingTime() {
      if (!this.eventForm.meetingDuration) {
        return 0;
      }

      if (this.meetingSections.length === 1 && this.eventForm.timeSlots.length === 1) {
        const firstSection = this.meetingSections[0];
        const sectionTime = Math.max(dateUtil.getTimeDifference(firstSection.startTime, firstSection.endTime), 0);
        return this.suggestedTime - sectionTime;
      }

      return this.getDurationForSlots(this.numberOfSlotsMissing);
    },
    excessTime() {
      return this.getDurationForSlots(this.numberOfSlotsExcess);
    },
    numberOfSlotsMissing() {
      return Math.max(this.numberOfSlotsSuggested - this.numberOfSlotsAdded, 0);
    },
    numberOfSlotsExcess() {
      return Math.max(this.numberOfSlotsAdded - this.numberOfSlotsSuggested, 0);
    },
    canAddEventToInstitutionCalendar() {
      return (
        this.profile.role === portalRoles.EMPLOYEE &&
        this.hasPermissionOnInstitution(
          permissionEnum.CREATE_EVENTS_IN_INSTITUTION_CALENDAR,
          this.eventForm.institutionCode
        ) &&
        !this.isCoOrganizer
      );
    },
    canAddEventOnlyToInstitutionCalendar() {
      return (
        this.profile.role === portalRoles.EMPLOYEE &&
        this.hasPermissionOnInstitution(
          permissionEnum.CREATE_EVENTS_ONLY_IN_INSTITUTION_CALENDAR,
          this.eventForm.institutionCode
        ) &&
        !this.isCoOrganizer
      );
    },

    isSettingSectionVisible() {
      return (
        this.canSetChildrenCalendar ||
        this.canAddEventToInstitutionCalendar ||
        this.canAddEventOnlyToInstitutionCalendar
      );
    },
    canSetChildrenCalendar() {
      return this.eventForm.type !== eventTypeEnum.PERFORMANCE_MEETING && !this.isCoOrganizer;
    },
    isCreator() {
      return this.getIsCreator();
    },
    isCoOrganizer() {
      return this.getIsCoOrganizer();
    },
    canHandleOthersEvents() {
      return this.getCanHandleOthersEvents();
    },
    isHandlingOthersEvent() {
      return this.canHandleOthersEvents && this.isEditMode && !this.isCreator && !this.isCoOrganizer;
    },
    isResponseDeadlineDisabled() {
      if (this.eventForm.timeSlots.length === 0) {
        return false;
      }
      const timeSlots = this.eventForm.timeSlots;
      const lastTimeSlotStartDate = timeSlots[timeSlots.length - 1].startDate;
      return isAfter(new Date(), lastTimeSlotStartDate);
    },
    institutionsThatCanBeSeen() {
      let institutions = this.institutions;
      institutions = institutions.filter(institution => !institution.communicationBlock);
      const group = this.group;
      if (this.delegatedContext.institutionCode != null) {
        institutions = institutions.filter(
          institution => institution.institutionCode === this.delegatedContext.institutionCode
        );
      } else if (this.parent === parentTypes.GROUP) {
        institutions = filterGroupMembershipInstitutions(institutions, group).filter(institution => {
          const canInviteToEvent = this.hasPermissionOnInstitutionGroup({
            permissionId: permissionEnum.INVITE_TO_EVENT,
            institution,
            group,
          });
          const hasDistributionRights = this.hasPermissionOnInstitution(
            permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
            institution.institutionCode
          );
          if (!canInviteToEvent && !hasDistributionRights) {
            return false;
          }

          return (
            this.hasPermissionOnInstitution(
              permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE,
              institution.institutionCode
            ) ||
            this.hasPermissionOnInstitution(
              permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL,
              institution.institutionCode
            ) ||
            this.hasPermissionOnInstitution(permissionEnum.HANDLE_PERFORMANCE_MEETING, institution.institutionCode)
          );
        });
      }
      return institutions;
    },
    primaryResourceSearchResults() {
      const searchResults = [];
      const primaryResourceResultsIndex = this.primaryResourceResults.length;
      for (let i = 0; i < primaryResourceResultsIndex; i++) {
        const timeslot = this.eventForm.timeSlots[i];
        const primaryResource = timeslot.primaryResource;
        let primarySearchResults = this.primaryResourceResults[i] ?? [];
        if (primaryResource) {
          const filteredSearchResults = [];
          for (const primarySearchResult of primarySearchResults) {
            filteredSearchResults.push({
              ...primarySearchResult,
              options: primarySearchResult.options.filter(({ id }) => primaryResource.id !== id),
            });
          }
          primarySearchResults = filteredSearchResults;
        }
        searchResults.push(primarySearchResults ?? []);
      }
      return searchResults;
    },
    canSelectInstitutionInGroup() {
      return this.parent === parentTypes.GROUP && this.group.membershipInstitutions?.length > 1;
    },
    apiPortalRoles() {
      if (
        this.eventForm.type == eventTypeEnum.SCHOOL_HOME_MEETING ||
        this.eventForm.type == eventTypeEnum.PARENTAL_MEETING
      ) {
        return [portalRoles.CHILD];
      } else if (this.eventForm.type == eventTypeEnum.PERFORMANCE_MEETING) {
        return [portalRoles.EMPLOYEE];
      }
      return [];
    },
    disabledPortalRolesWhenInviting() {
      if (
        this.eventForm.type == eventTypeEnum.SCHOOL_HOME_MEETING ||
        this.eventForm.type == eventTypeEnum.PARENTAL_MEETING
      ) {
        return [portalRoles.EMPLOYEE, portalRoles.GUARDIAN, portalRoles.OTP];
      } else if (this.eventForm.type == eventTypeEnum.PERFORMANCE_MEETING) {
        return [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP];
      }
      return [];
    },
    isEditMode: function () {
      return this.$route.path.includes('redigersamtale');
    },
    showTimeSlotHeader() {
      return this.eventForm.timeSlots.length > 0;
    },
    isEdited() {
      return (
        this.eventForm.childRequired ||
        this.eventForm.invitees.length > 0 ||
        this.eventForm.description !== '' ||
        this.eventForm.attachments.length > 0 ||
        this.isUploaded
      );
    },
    shouldIncludeSelf() {
      return this.delegatedContext.institutionProfileId != null;
    },
  },
  watch: {
    activeEvent() {
      if (this.isEditMode) {
        this.loadEditedEvent();
      }
    },
    unavailableResourceIds() {
      if (Array.isArray(this.unavailableResourceIds) && this.unavailableResourceIds.length > 0) {
        this.showUnavailableResources();
      }
    },
    numberOfSlotsAdded(value) {
      if (value > 0) {
        this.isMissingTimeslots = false;
      }
    },
  },
  created() {
    if (!this.isEditMode) {
      const checkExist = setInterval(() => {
        if (this.isProfileLoaded) {
          this.setInstitutionCodeAndEventType();
          clearInterval(checkExist);
        }
      }, 100);
    }
  },
  mounted() {
    this.resetForm();
    if (this.isEditMode) {
      this.loadEditedEvent();
    }
    if (this.eventForm.timeSlots.length > 0) {
      const date = this.eventForm.timeSlots[0].date;
      this.availableDeadlineDate = {
        disabledDate(time) {
          return moment(time).isBefore(moment().subtract(1, 'days')) || moment(time).isAfter(moment(date));
        },
        firstDayOfWeek: 1,
      };
    }
    this.emptyEventForm = cloneDeep(this.eventForm);
    this.isLoadingEdge = false; // fix Edge rendering bug
  },
  methods: {
    ...mapActions({
      createTimeSlotEvent: types.CREATE_TIMESLOT_EVENT,
      updateTimeSlotEvent: types.UPDATE_TIMESLOT_EVENT,
      createAttachments: types.CREATE_ATTACHMENTS,
      updateAttachments: types.UPDATE_ATTACHMENTS,
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS_LIGHT,
    }),
    ...mapMutations({
      updateUnavailableResources: types.MUTATE_UNAVAILABLE_RESOURCES,
    }),
    getDurationForSlots(numberOfSlots) {
      const totalMeetingDuration = numberOfSlots * this.eventForm.meetingDuration;
      const numberOfBreaksRequired = Math.max(Math.ceil(numberOfSlots / this.numberOfMeetingsPerPause - 1), 0);
      const totalBreakTime = numberOfBreaksRequired * this.eventForm.breakLength;
      return totalMeetingDuration + totalBreakTime;
    },
    formatSlotTime(slotTime, isApproximate) {
      const numberOfHours = Math.floor(slotTime / 60);
      const numberOfMinutes = slotTime % 60;
      const approximateText = this.$options.filters.fromTextKey('CALENDAR_APPROXIMATE_TEXT');
      const hourUnit = this.$options.filters.fromTextKey('CALENDAR_HOUR_UNIT');
      const minuteUnit = this.$options.filters.fromTextKey('CALENDAR_MINUTE_UNIT');
      const conjunction = this.$options.filters.fromTextKey('AND');
      let result = '';

      if (isApproximate) {
        result += `${approximateText} `;
      }
      if (numberOfHours > 0) {
        result += `${numberOfHours} ${hourUnit} ${conjunction} `;
      }
      result += `${numberOfMinutes} ${minuteUnit}`;
      return result;
    },
    searchLocations(query, index) {
      this.loadEventLocations(query, index).then(locations => {
        Vue.set(this.primaryResourceResults, index, locations);
      });
    },
    resetValidations() {
      this.showMissingMeetingDuration = false;
      this.showMissingMembersAlert = false;
    },
    onUpdateChildRequired() {
      this.$forceUpdate();
    },
    changedAddToMineAndInstitutionCalendar(addToMineAndInstitutionCalendar) {
      if (addToMineAndInstitutionCalendar && this.addOnlyToInstitutionCalendar) {
        this.addOnlyToInstitutionCalendar = false;
      }
    },
    changedAddToOnlyInstitutionCalendar(addOnlyToInstitutionCalendar) {
      if (addOnlyToInstitutionCalendar && this.addToMineAndInstitutionCalendar) {
        this.addToMineAndInstitutionCalendar = false;
      }
    },
    closeEventForm() {
      if (isEqualWith(this.emptyEventForm, this.eventForm)) {
        this.resetForm();
        if (this.parent === parentTypes.PROFILE) {
          this.$router.push({ path: '/kalender' });
        } else {
          this.$router.push({ name: 'group-calendar' });
        }
      } else {
        this.$refs.cancelModal.show();
      }
    },
    filesChanged(attachments) {
      this.eventForm.attachments = attachments;
      this.existingAttachments = attachments;
    },
    cancelForm() {
      this.resetForm();
      this.$refs.cancelModal.hide();
      if (this.parent === parentTypes.PROFILE) {
        this.$router.push({ path: '/kalender' });
      } else {
        this.$router.push({ name: 'group-calendar' });
      }
    },
    checkForExistingResourcesWhenChangingDateTime(index) {
      this.changedTimeSlot = index;
      if (
        this.eventForm.timeSlots[index].primaryResource != null &&
        this.eventForm.timeSlots[index].primaryResource != '' &&
        Object.keys(this.eventForm.timeSlots[index].primaryResource).length > 0
      ) {
        this.$refs.removeResourcesWhenChangingDateTimeModal.show();
        return;
      }
      this.validateTimeSlot(index);
    },
    removeResources() {
      this.eventForm.timeSlots[this.changedTimeSlot].primaryResource = '';
      this.$emit('savePrimaryLocation', { primaryLocation: null, index: this.changedTimeSlot });

      this.instanceToReset = this.changedTimeSlot;
      this.resetResources = !this.resetResources;
      this.validateTimeSlot(this.changedTimeSlot);
      this.timeSlotDurationValidation(false);
    },
    resetDateTime() {
      this.eventForm.timeSlots[this.changedTimeSlot].date = this.clonedTimeSlots[this.changedTimeSlot].date;
      this.eventForm.timeSlots[this.changedTimeSlot].startTime = this.clonedTimeSlots[this.changedTimeSlot].startTime;
      this.eventForm.timeSlots[this.changedTimeSlot].endTime = this.clonedTimeSlots[this.changedTimeSlot].endTime;
    },
    isTheSameAuthorityInstitutions(invitee) {
      if (this.eventForm.institutionCode != invitee.institutionCode) {
        if (
          this.sameAuthorityInstitutions.length == 0 ||
          (this.sameAuthorityInstitutions.length > 0 &&
            this.sameAuthorityInstitutions.filter(institution => institution.institutionCode == invitee.institutionCode)
              .length == 0)
        ) {
          return false;
        }
      }
      return true;
    },
    addInvitees(invitees) {
      this.hasExternalRecipient = false;
      if (invitees.length > 0) {
        this.showMissingMembersAlert = false;
      }
      if (invitees.length > 2000) {
        this.$refs.tooManyRecipientsModal.show();
      }
      const eventInvitees = [];
      const groupInvitees = [];

      for (const invitee of invitees) {
        if (invitee.type == 'group') {
          groupInvitees.push(invitee);
        } else {
          if (
            (this.eventForm.type == eventTypeEnum.SCHOOL_HOME_MEETING ||
              this.eventForm.type == eventTypeEnum.PARENTAL_MEETING) &&
            invitee.portalRole == portalRoles.CHILD &&
            (invitee.relatedProfiles != null || invitee.relations != null)
          ) {
            const relations = invitee.relatedProfiles != null ? invitee.relatedProfiles : invitee.relations;
            for (const relation of relations) {
              if (
                relation.id != null &&
                (relation.relationType == portalRoles.GUARDIAN || relation.role == portalRoles.GUARDIAN)
              ) {
                if (eventInvitees.filter(inv => inv.id == relation.id).length == 0) {
                  eventInvitees.push({
                    label: Vue.filter('displayProfileNameWithMetadata')(relation),
                    value: parentTypes.PROFILE + relation.id,
                    id: relation.id,
                    type: parentTypes.PROFILE,
                  });
                }
              }
            }
          }
          if (eventInvitees.filter(inv => inv.id == invitee.id).length == 0) {
            eventInvitees.push(invitee);
          }
        }

        if (invitee.institutionCode != null && this.hasExternalRecipient === false) {
          if (this.eventForm.institutionCode !== invitee.institutionCode) {
            this.hasExternalRecipient = !this.isTheSameAuthorityInstitutions(invitee);
          }
        }
      }
      if (this.hasExternalRecipient) {
        this.$refs.externalRecipientsToastr.show();
      }
      this.eventForm.invitees = eventInvitees;
      this.eventForm.invitedGroups = groupInvitees;
      this.checkInviteesNotCoOrganizers();
    },
    checkInviteesNotCoOrganizers() {
      if (this.eventForm.type !== eventTypeEnum.PERFORMANCE_MEETING) {
        return;
      }

      const eventInviteeIds = this.eventForm.invitees.map(invitee => invitee.id);
      const existingInviteeIds = this.existingInvitees.map(invitee => invitee.id);
      const inviteeIds = eventInviteeIds.concat(existingInviteeIds);
      const coOrganizerIds = this.eventForm.coOrganizerIds || [];
      const intersect = inviteeIds.filter(inviteeId => coOrganizerIds.includes(inviteeId));

      this.isInviteeCoOrganizer = intersect.length > 0;
    },
    onSelectOrganizers(organizers) {
      this.tmpOrganizers = organizers;
      this.setOrganizers(organizers);
      this.checkInviteesNotCoOrganizers();
    },
    removeExistingOrganizer(removedOrganizer) {
      const isRemovingExistingOrganizer =
        this.existingOrganizers.filter(organizer => organizer.id === removedOrganizer.id).length === 1;

      if (isRemovingExistingOrganizer) {
        this.selectedOrganizers = [...this.tmpOrganizers, removedOrganizer];
        this.$refs.removingCoOrganizerWarning.show();
        return;
      }

      this.selectedOrganizers = this.tmpOrganizers;
      this.setOrganizers(this.tmpOrganizers);
    },
    acceptRemovalOfExistingCoOrganizer() {
      const coOrganizerSet = new Set();
      for (const organizer of this.tmpOrganizers) {
        coOrganizerSet.add(`${organizer.profileId},${organizer.id}`);
      }

      this.existingOrganizers = this.existingOrganizers.filter(
        existingOrganizer => coOrganizerSet.has(`${existingOrganizer.profileId},${existingOrganizer.id}`) === true
      );

      this.selectedOrganizers = this.tmpOrganizers;
      this.setOrganizers(this.tmpOrganizers);
      this.$refs.removingCoOrganizerWarning.hide();
    },
    cancelRemovalOfExistingOrganizer() {
      this.setOrganizers(this.selectedOrganizers);
      this.$refs.removingCoOrganizerWarning.hide();
    },
    setOrganizers(organizers) {
      this.eventForm.coOrganizerIds = organizers.map(organizer => organizer.id);
    },
    resetForm() {
      this.eventForm.id = '';
      this.eventForm.title = '';
      this.eventForm.type = '';
      this.eventForm.invitees = [];
      this.eventForm.invitedGroups = [];
      this.eventForm.invitedGroupIds = [];
      this.eventForm.resources = [];
      this.eventForm.attachments = [];
      this.existingPrimaryResources = [];
      this.existingInvitees = [];
      this.eventForm.responseRequired = true;
      this.eventForm.description = '';
      this.existingAttachments = [];
      this.existingOrganizers = [];
      this.emptyEventForm = {};
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.$emit('savePrimaryLocation', { resetAll: true });
      this.resetTimeslots();
      this.resetCoOrganizers();
    },
    resetCoOrganizers() {
      this.selectedOrganizers = [];
      this.setOrganizers([]);
    },
    updateMeetingEventType() {
      const dropdownEventTypes = [];
      const institutionCode = this.institutionCode;
      const isEditMode = this.isEditMode;

      if (institutionCode == null) {
        return;
      }

      if (
        this.hasPermissionOnInstitution(permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL, institutionCode) ||
        isEditMode
      ) {
        dropdownEventTypes.push({
          type: eventTypeEnum.SCHOOL_HOME_MEETING,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_school_home_meeting'),
        });
      }
      if (
        this.hasPermissionOnInstitution(permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE, institutionCode) ||
        isEditMode
      ) {
        dropdownEventTypes.push({
          type: eventTypeEnum.PARENTAL_MEETING,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_parental_meeting'),
        });
      }
      if (this.hasPermissionOnInstitution(permissionEnum.HANDLE_PERFORMANCE_MEETING, institutionCode) || isEditMode) {
        dropdownEventTypes.push({
          type: eventTypeEnum.PERFORMANCE_MEETING,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_performance_meeting'),
        });
      }

      this.meetingEventTypes = dropdownEventTypes;
    },
    buttonCancelSwitchInstitution() {
      this.eventForm.institutionCode = this.institutionCode;
      this.$refs.confirmSwitchInstitution.hide();
    },
    buttonConfirmSwitchInstitution() {
      this.institutionCode = this.eventForm.institutionCode;
      this.resetForm();
      this.updateMeetingEventType();
      if (this.meetingEventTypes.length > 0) {
        this.changeEventType(this.meetingEventTypes[0].type);
      }
      this.$refs.mediaUpload.removeUploadedMedia();
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.$refs.confirmSwitchInstitution.hide();
    },
    changeMeetingDuration() {
      if (this.eventForm.meetingDuration !== null) {
        this.showMissingMeetingDuration = false;
      }
    },
    existingLocation(index) {
      return this.existingPrimaryResources[index];
    },
    changeEventType(newVal, initial) {
      if (newVal) {
        this.eventForm.type = newVal;
        if (initial) {
          this.emptyEventForm.type = newVal;
        }
      }
      this.eventType = this.eventForm.type;
      this.isAllowedMultipleParticipants = false;
      this.eventForm.numberOfParticipantsPerTimeSlot = null;
      this.eventForm.title = Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_' + this.eventForm.type);
      if (initial) {
        this.emptyEventForm.title = this.eventForm.title;
      }
      if (this.parent == parentTypes.GROUP) {
        this.inviteMembersFromGroup();
      }
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.resetValidations();
    },
    resetTimeslots() {
      this.eventForm.timeSlots = [
        {
          timeSlotIndex: uniqueId(),
          date: '',
          startTime: '',
          endTime: '',
          primaryResource: '',
          showDateAlert: false,
          showStartTimeAlert: false,
          showEndTimeAlert: false,
          showOverlapAlert: false,
          showLocationAlert: false,
          isEdit: false,
        },
      ];
    },
    buttonConfirmChangeEventType() {
      this.eventForm.invitees = [];
      this.eventForm.childRequired = false;
      this.eventForm.attachments = [];
      this.isInviteeCoOrganizer = false;
      this.eventForm.description = '';
      this.existingAttachments = [];
      this.primaryResourceResults = [];
      this.$refs.mediaUpload.reset();
      this.$refs.confirmChangeEventTypeModal.hide();
      this.changeEventType();
    },
    buttonCancelChangeEventType() {
      // reset eventType
      this.eventForm.type = this.eventType;
      this.$refs.confirmChangeEventTypeModal.hide();
    },
    checkOverlapTimeSlot(index, startDate, endDate) {
      let isOverlap = false;
      let i = 0;
      for (const timeslot of this.eventForm.timeSlots) {
        if (i != index && timeslot.date != '' && timeslot.startTime != '' && timeslot.endTime != '') {
          const compareStartTime = moment(timeslot.date + ' ' + timeslot.startTime + ':00');
          const compareEndTime = moment(timeslot.date + ' ' + timeslot.endTime + ':00');
          if (
            (startDate.isSameOrAfter(compareStartTime) && startDate.isBefore(compareEndTime)) ||
            (endDate.isAfter(compareStartTime) && endDate.isSameOrBefore(compareEndTime)) ||
            (startDate.isSameOrBefore(compareStartTime) && endDate.isSameOrAfter(compareEndTime))
          ) {
            isOverlap = true;
            break;
          }
        }
        i++;
      }
      return isOverlap;
    },
    validateTimeSlot(index) {
      const nowDateTimeTimeStamp = moment();
      const timeSlotIndex = this.eventForm.timeSlots[index];

      if (timeSlotIndex.startTime && timeSlotIndex.endTime) {
        const startDateTime = moment(timeSlotIndex.date + ' ' + timeSlotIndex.startTime + ':00');
        const endDateTime = moment(timeSlotIndex.date + ' ' + timeSlotIndex.endTime + ':00');
        timeSlotIndex.showStartTimeAlert = nowDateTimeTimeStamp.isSameOrAfter(startDateTime);
        if (this.eventForm.meetingDuration) {
          timeSlotIndex.showEndTimeAlert = endDateTime.diff(startDateTime, 'minutes') < this.eventForm.meetingDuration;
        }
        if (!timeSlotIndex.showStartTimeAlert && !timeSlotIndex.showEndTimeAlert) {
          timeSlotIndex.showOverlapAlert = this.checkOverlapTimeSlot(index, startDateTime, endDateTime);
        }
      }
      this.clonedTimeSlots = cloneDeep(this.eventForm.timeSlots);
      let date = this.eventForm.timeSlots[index].date;
      for (const timeSlot of this.eventForm.timeSlots) {
        if (moment(timeSlot.date).isAfter(moment(date), 'day')) {
          date = timeSlot.date;
        }
      }
      this.availableDeadlineDate = {
        disabledDate(time) {
          return moment(time).isBefore(moment().subtract(1, 'days')) || moment(time).isAfter(moment(date));
        },
        firstDayOfWeek: 1,
      };
      if (
        this.eventForm.responseDeadline != null &&
        this.eventForm.responseDeadline != '' &&
        moment(this.eventForm.responseDeadline).isAfter(date, 'day')
      ) {
        this.showInvalidDeadlineAlert = true;
      }
    },
    addTimeSlot() {
      this.primaryResourceResults.push([]);
      this.eventForm.timeSlots.push({
        timeSlotIndex: uniqueId(),
        date: null,
        startTime: null,
        endTime: null,
        primaryResource: '',
        showDateAlert: false,
        showStartTimeAlert: false,
        showEndTimeAlert: false,
        showOverlapAlert: false,
        showLocationAlert: false,
        isEdit: false,
      });
      this.clonedTimeSlots = cloneDeep(this.eventForm.timeSlots);
      if (this.eventForm.timeSlots.length > 0) {
        const date = this.eventForm.timeSlots[0].date;
        this.availableDeadlineDate = {
          disabledDate(time) {
            return moment(time).isBefore(moment().subtract(1, 'days')) || moment(time).isAfter(moment(date));
          },
          firstDayOfWeek: 1,
        };
      }
    },
    removeTimeSlot(index) {
      const primaryResources = this.eventForm.timeSlots.map(timeslot =>
        timeslot.primaryResource != null ? timeslot.primaryResource : timeslot.primaryResourceText
      );
      this.primaryResourceResults.splice(index, 1);
      if (!this.eventForm.timeSlots[index].isEdit) {
        this.eventForm.timeSlots.splice(index, 1);
        this.existingPrimaryResources.splice(index, 1);
        primaryResources.splice(index, 1);
        for (let i = 0; i < primaryResources.length; i++) {
          if (primaryResources[i] != null) {
            if (primaryResources[i].id != null) {
              primaryResources[i].label = primaryResources[i].name;
              primaryResources[i].value = 'resource' + primaryResources[i].id + primaryResources[i].institutionCode;
            } else {
              primaryResources[i].label = primaryResources[i];
              primaryResources[i].value = primaryResources[i];
            }
          }
        }
      }
      this.timeSlotDurationValidation();
    },
    addPrimaryResourceToList(primaryResources) {
      this.$nextTick(() => {
        const index = primaryResources.instance;
        const primaryResource = primaryResources.selectedItems;
        if (primaryResource.length > 0) {
          if (primaryResource[0].id != null) {
            this.eventForm.timeSlots[index].primaryResource = primaryResource[0];
          } else {
            this.eventForm.timeSlots[index].primaryResourceText = primaryResource[0];
          }
          this.eventForm.timeSlots[index].showLocationAlert = false;
        } else {
          this.eventForm.timeSlots[index].primaryResource = null;
          this.eventForm.timeSlots[index].primaryResourceText = '';
        }
      });
    },
    validateSlot(slot) {
      const meetingDuration = this.eventForm.meetingDuration || 0;
      const isInThePast = dateUtil.isBefore(slot.startDate, dateUtil.now());
      slot.showDateAlert = !dateUtil.isValid(slot.date);
      slot.showStartTimeAlert =
        !dateUtil.isValid(slot.startTime, dateFormatEnum.SHORT_TIME) || (isInThePast && !slot.isEdit);
      slot.showEndTimeAlert =
        !dateUtil.isValid(slot.endTime, dateFormatEnum.SHORT_TIME) ||
        dateUtil.getTimeDifference(slot.startTime, slot.endTime) < meetingDuration;
      return !slot.showDateAlert && !slot.showStartTimeAlert && !slot.showEndTimeAlert;
    },
    validateTimeslotDate(timeslotIndex) {
      const timeslot = this.eventForm.timeSlots[timeslotIndex];
      timeslot.showDateAlert = !dateUtil.isValid(timeslot.date);
      this.checkForExistingResourcesWhenChangingDateTime(timeslotIndex);
    },
    validateTimeslotStartTime(timeslotIndex) {
      const timeslot = this.eventForm.timeSlots[timeslotIndex];
      timeslot.showStartTimeAlert = !dateUtil.isValid(timeslot.startTime, dateFormatEnum.SHORT_TIME);
      this.checkForExistingResourcesWhenChangingDateTime(timeslotIndex);
    },
    validateTimeslotEndTime(timeslotIndex) {
      const timeslot = this.eventForm.timeSlots[timeslotIndex];
      const meetingDuration = this.eventForm.meetingDuration || 0;
      timeslot.showEndTimeAlert =
        !dateUtil.isValid(timeslot.endTime, dateFormatEnum.SHORT_TIME) ||
        dateUtil.getTimeDifference(timeslot.startTime, timeslot.endTime) < meetingDuration;
      this.checkForExistingResourcesWhenChangingDateTime(timeslotIndex);
    },
    timeSlotDurationValidation(endTimeValidation, saveButton) {
      const DateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';

      let isSaveEvent = true;
      const allocationTimesArray = this.eventForm.timeSlots;
      for (const allocation of allocationTimesArray) {
        const startDate = moment(allocation.date + ' ' + allocation.startTime + ':00').format(DateTimeFormat);
        const endDate = moment(allocation.date + ' ' + allocation.endTime + ':00').format(DateTimeFormat);
        allocation.startDate = startDate;
        allocation.endDate = endDate;
        const isValidSlot = this.validateSlot(allocation);

        if (!allocation.isEdit && !isValidSlot) {
          isSaveEvent = false;
        }
        if (!allocation.primaryResource && !allocation.primaryResourceText) {
          if (allocation !== allocationTimesArray[allocationTimesArray.length - 1] || saveButton) {
            allocation.showLocationAlert = true;
            document.getElementById('event-timeslots').scrollIntoView(true);
          }
          isSaveEvent = false;
        }
      }
      if (allocationTimesArray.length > 0 && allocationTimesArray[allocationTimesArray.length - 1] != null) {
        this.eventForm.endDateTime = moment(
          allocationTimesArray[allocationTimesArray.length - 1].date +
            ' ' +
            allocationTimesArray[allocationTimesArray.length - 1].endTime +
            ':00'
        ).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      }

      return isSaveEvent;
    },
    onSaveClicked() {
      if (this.validateEvent() === false) {
        return;
      }
      if (this.isHandlingOthersEvent) {
        this.$refs.editOthersEventWarningModal.show();
        return;
      }
      if (this.numberOfSlotsAdded !== this.numberOfSlotsSuggested) {
        this.$refs.suggestedTimeslotsWarning.show();
        return;
      }
      this.saveEvent();
    },
    onConfirmEditOthersEvent() {
      this.$refs.editOthersEventWarningModal.hide();
      this.saveEvent();
    },
    onConfirmCreateTimeslots() {
      this.$refs.suggestedTimeslotsWarning.hide();
      this.saveEvent();
    },
    validateEvent() {
      let isSaveEvent = true;

      if (this.isEditMode) {
        for (const existingAttendee of this.existingInvitees) {
          if (this.eventForm.invitees.filter(invitee => invitee.id == existingAttendee.id).length == 0) {
            this.eventForm.invitees.push(existingAttendee);
          }
        }
      }

      this.isValidNumberOfParticipants =
        !this.isAllowedMultipleParticipants ||
        (Number.isInteger(this.eventForm.numberOfParticipantsPerTimeSlot) &&
          this.eventForm.numberOfParticipantsPerTimeSlot > 1 &&
          this.eventForm.numberOfParticipantsPerTimeSlot < 11);
      if (!this.isValidNumberOfParticipants) {
        isSaveEvent = false;
      }

      this.showMissingTitleAlert = !this.eventForm.title.trim();
      if (this.showMissingTitleAlert) {
        this.$el.querySelector('#eventTitle').scrollIntoView(true);
        isSaveEvent = false;
      }

      if (this.eventForm.invitees.length === 0) {
        this.showMissingMembersAlert = true;
        document.getElementById('search-invitees').scrollIntoView(true);
        isSaveEvent = false;
      } else if (this.eventForm.invitees.length > 2000) {
        this.$refs.tooManyRecipientsModal.show();
        document.getElementById('eventInvitees').scrollIntoView(true);
        isSaveEvent = false;
      } else {
        this.showMissingMembersAlert = false;
      }

      // Validate whether timeSlots are overlapping.
      let timeSlotIndex = 0;
      for (const timeSlot of this.eventForm.timeSlots) {
        if (timeSlot.startTime && timeSlot.endTime) {
          const startDateTime = moment(timeSlot.date + ' ' + timeSlot.startTime + ':00');
          const endDateTime = moment(timeSlot.date + ' ' + timeSlot.endTime + ':00');
          if (!timeSlot.isEdit && this.checkOverlapTimeSlot(timeSlotIndex, startDateTime, endDateTime)) {
            timeSlot.showOverlapAlert = true;
            isSaveEvent = false;
            break;
          }
        }
        timeSlotIndex++;
      }

      if (this.eventForm.meetingDuration == null) {
        this.showMissingMeetingDuration = true;
        document.getElementById('event-timeslots').scrollIntoView(true);
        isSaveEvent = false;
      } else {
        this.showMissingMeetingDuration = false;
      }

      // Generate timeslots from allocation time
      if (!this.timeSlotDurationValidation(null, true)) {
        isSaveEvent = false;
      }

      if (this.isInviteeCoOrganizer) {
        isSaveEvent = false;
      }

      if (
        this.eventForm.responseDeadline != null &&
        this.eventForm.responseDeadline != '' &&
        this.eventForm.timeSlots.length > 0
      ) {
        let date = this.eventForm.timeSlots[0].date;
        for (const timeSlot of this.eventForm.timeSlots) {
          if (moment(timeSlot.date).isAfter(moment(date), 'day')) {
            date = timeSlot.date;
          }
        }
        if (
          this.eventForm.responseDeadline != null &&
          this.eventForm.responseDeadline != '' &&
          moment(this.eventForm.responseDeadline).isAfter(date, 'day')
        ) {
          this.showInvalidDeadlineAlert = true;
          document.getElementById('deadline-date').scrollIntoView(true);
          isSaveEvent = false;
        }
      }

      this.isMissingTimeslots = this.numberOfSlotsAdded === 0;
      if (this.isMissingTimeslots) {
        isSaveEvent = false;
      }

      return isSaveEvent;
    },
    saveEvent() {
      this.prepareEventForm();
      this.checkForAttachments();
    },
    checkForAttachments() {
      this.isLoading = true;
      const preparedAttachments = Vue.filter('prepareAttachments')(this.eventForm.attachments);
      // Update existing attachments
      if (preparedAttachments.existingMedia.length > 0) {
        const updatedMedia = preparedAttachments.existingMedia.filter(att => att.media != null && att.media.updated);
        if (updatedMedia.length > 0) {
          this.updateAttachments({
            media: Vue.filter('prepareMedia')(updatedMedia),
          });
        }
      }
      if (preparedAttachments.media != null || preparedAttachments.files != null || preparedAttachments.links != null) {
        preparedAttachments.ownerInstitutionProfileId = this.institutions.find(
          inst => inst.institutionCode == this.institutionCode
        ).institutionProfileId;
        preparedAttachments.institutionCode = this.institutions.find(
          inst => inst.institutionCode == this.institutionCode
        ).institutionCode;
        this.createAttachments(preparedAttachments)
          .then(rsp => {
            const attachments = rsp.files.concat(rsp.links).concat(rsp.media).concat(preparedAttachments.existingMedia);
            this.eventForm.attachmentIds = attachments.map(att => att.id);
            if (this.isEditMode) {
              this.updateTimeSlotEvent(this.eventForm)
                .then(() => {
                  this.resetForm();
                  this.$parent.updatedEvent();
                })
                .catch(() => {
                  this.isLoading = false;
                });
            } else {
              this.createTimeSlotEvent(this.eventForm)
                .then(() => {
                  this.resetForm();
                  this.$parent.updatedEvent();
                })
                .catch(() => {
                  this.isLoading = false;
                });
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.eventForm.attachmentIds = preparedAttachments.existingMedia.map(att => att.id);
        if (this.isEditMode) {
          this.updateTimeSlotEvent(this.eventForm)
            .then(() => {
              this.resetForm();
              this.$parent.updatedEvent();
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          this.createTimeSlotEvent(this.eventForm)
            .then(() => {
              this.resetForm();
              this.$parent.updatedEvent();
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      }
    },
    prepareEventForm() {
      if (!this.isAllowedMultipleParticipants) {
        this.eventForm.numberOfParticipantsPerTimeSlot = null;
      }
      if (!this.isEditMode) {
        this.eventForm.creatorInstProfileId = this.delegatedContext.institutionProfileId
          ? this.delegatedContext.institutionProfileId
          : this.institutions.find(inst => inst.institutionCode == this.eventForm.institutionCode).institutionProfileId;
      }
      this.eventForm.inviteeIds = this.eventForm.invitees.map(res => res.id);
      // If user invited all three portal roles of a group, we show the event on the group dashboard
      if (this.eventForm.invitedGroups != null) {
        // Groups with portal roles
        const groupDict = {};

        for (const group of this.eventForm.invitedGroups) {
          const groupDictItem = groupDict[group.id] || {};
          const portalRoles = groupDictItem.portalRoles || new Set();
          const portalRole = group.portalRole;
          const groupId = +group.id;

          if (portalRole != null) {
            portalRoles.add(portalRole);
            groupDict[groupId] = {
              groupId,
              portalRoles: [...portalRoles],
            };
          }
        }
        this.eventForm.inviteeGroups = Object.values(groupDict);
      }
      this.eventForm.eventId = this.eventForm.id;
      for (const timeSlot of this.eventForm.timeSlots) {
        timeSlot.startDateTime = timeSlot.startDate;
        timeSlot.endDateTime = timeSlot.endDate;
        if (
          timeSlot.primaryResource != null &&
          timeSlot.primaryResource.id != null &&
          timeSlot.primaryResource.id != ''
        ) {
          timeSlot.primaryResourceId = timeSlot.primaryResource.id;
        }
      }

      this.eventForm.addToInstitutionCalendar =
        this.addOnlyToInstitutionCalendar || this.addToMineAndInstitutionCalendar;
      this.eventForm.hideInOwnCalendar = this.addOnlyToInstitutionCalendar;
    },
    inviteMembersFromGroup() {
      const existingAttendees = [];
      if (this.eventForm.type != '') {
        this.loadGroupMemberships({ groupId: this.group.id }).then(() => {
          const isPerformanceMeeting = this.eventForm.type === eventTypeEnum.PERFORMANCE_MEETING;
          const isGuardianAndChildMeeting =
            this.eventForm.type === eventTypeEnum.SCHOOL_HOME_MEETING ||
            this.eventForm.type === eventTypeEnum.PARENTAL_MEETING;
          for (const groupMember of this.groupMemberships) {
            for (const institutionProfile of groupMember.institutionProfiles) {
              const isProfileMe = this.institutions.some(
                inst => inst.institutionProfileId === institutionProfile.institutionProfileId
              );
              if (isProfileMe) {
                continue;
              }

              let addAttendee = false;
              if (isPerformanceMeeting && groupMember.portalRole === portalRoles.EMPLOYEE) {
                addAttendee = true;
              } else if (isGuardianAndChildMeeting && groupMember.portalRole === portalRoles.CHILD) {
                addAttendee = true;
              }

              if (addAttendee) {
                existingAttendees.push({
                  label: Vue.filter('displayProfileNameWithMetadata')(institutionProfile),
                  name: Vue.filter('displayProfileNameWithMetadata')(institutionProfile),
                  value: 'profile' + institutionProfile.institutionProfileId,
                  id: institutionProfile.institutionProfileId,
                  portalRole: groupMember.portalRole,
                  type: 'profile',
                });
              }
            }
          }
        });
        this.existingInvitees = existingAttendees;
        this.eventForm.invitees = existingAttendees;
        this.eventForm.invitedGroups = [this.group];
        this.eventForm.invitedGroupIds = [this.group.id];
      }
    },
    loadEditedEvent() {
      if (this.$route.params.eventId == this.activeEvent.id) {
        this.eventForm = cloneDeep(this.activeEvent);
        this.eventForm.invitees = [];
        this.eventForm.inviteeGroups = [];
        this.hiddenOrganizers = [this.eventForm.creator];
        // Map timeSlot DTO to frontend
        this.$set(this.eventForm, 'timeSlots', this.eventForm.timeSlot.timeSlots);
        this.eventForm.breakLength = this.eventForm.timeSlot.breakLength;
        this.eventForm.childRequired = this.eventForm.timeSlot.childRequired;
        this.eventForm.meetingDuration = this.eventForm.timeSlot.meetingDuration;
        this.eventForm.meetingsBetweenBreaks = this.eventForm.timeSlot.meetingsBetweenBreaks;
        this.eventForm.addToInstitutionCalendar = this.activeEvent.addedToInstitutionCalendar;
        this.eventForm.numberOfParticipantsPerTimeSlot = this.activeEvent.timeSlot.numberOfParticipantsPerTimeSlot;
        this.eventForm.description = this.eventForm.description.html;
        this.isAllowedMultipleParticipants = this.activeEvent.timeSlot.numberOfParticipantsPerTimeSlot != null;
        this.existingOrganizers = this.activeEvent.coOrganizers.map(coOrganizer => coOrganizer.instProfile);
        this.selectedOrganizers = [...this.existingOrganizers];
        this.eventForm.coOrganizerIds = this.existingOrganizers.map(coOrganizer => coOrganizer.id);
        if (this.eventForm.description == null) {
          this.eventForm.description = '';
        }
        this.existingAttachments = this.activeEvent.attachments;
        this.eventForm.institutionCode =
          this.emptyEventForm.institutionCode =
          this.institutionCode =
            this.eventForm.creator.institutionCode;
        this.updateMeetingEventType();
        if (this.meetingEventTypes.length > 0 && this.eventForm.type == '') {
          this.changeEventType(this.meetingEventTypes[0].type, true);
        }
        let i = 0;
        for (const allocationTime of this.eventForm.timeSlots) {
          allocationTime.date = moment(allocationTime.startDate).format(
            this.$parent.defaultDateValueFormat.toUpperCase()
          );
          allocationTime.startTime = moment(allocationTime.startDate).format('HH:mm');
          allocationTime.endTime = moment(allocationTime.endDate).format('HH:mm');
          allocationTime.isEdit = true;
          allocationTime.showDateAlert = false;
          allocationTime.showStartTimeAlert = false;
          allocationTime.showEndTimeAlert = false;
          allocationTime.showOverlapAlert = false;
          if (this.eventForm.timeSlots[i].primaryResource != null) {
            this.existingPrimaryResources[i] = [
              {
                label: `${allocationTime.primaryResource.name} (${allocationTime.primaryResource.institutionName})`,
                value: allocationTime.primaryResource.id,
                name: allocationTime.primaryResource.name,
              },
            ];
          } else if (allocationTime.primaryResourceText != null) {
            this.existingPrimaryResources[i] = [
              {
                label: allocationTime.primaryResourceText,
                value: allocationTime.primaryResourceText,
                name: allocationTime.primaryResourceText,
              },
            ];
          } else {
            this.existingPrimaryResources[i] = [];
          }
          this.$emit('savePrimaryLocation', { primaryLocation: this.eventForm.timeSlots[i].primaryResource, index: i });
          i++;
        }
        this.existingInvitees = this.activeEvent.invitees.map(invitee => ({
          label: this.$options.filters.displayProfileNameWithMetadata(invitee.instProfile),
          name: this.$options.filters.displayProfileNameWithMetadata(invitee.instProfile),
          value: 'profile' + invitee.instProfile.id,
          institutionCode: invitee.instProfile.institutionCode,
          id: invitee.instProfile.id,
          portalRole: invitee.instProfile.role,
          type: 'profile',
        }));
        this.addToMineAndInstitutionCalendar =
          this.activeEvent.addedToInstitutionCalendar && !this.activeEvent.hideInOwnCalendar;
        this.addOnlyToInstitutionCalendar =
          this.activeEvent.addedToInstitutionCalendar && this.activeEvent.hideInOwnCalendar;
      }
    },
    setInstitutionCodeAndEventType() {
      const defaultInstitution = getDefaultInstitution(
        this.institutionsThatCanBeSeen,
        this.activeInstitutionCodes,
        this.institutionsThatCanBeSeen[0]
      );

      for (const institution of this.institutions) {
        const hasPermission =
          this.hasPermissionOnInstitution(
            this.permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL,
            institution.institutionCode
          ) ||
          this.hasPermissionOnInstitution(
            this.permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE,
            institution.institutionCode
          ) ||
          this.hasPermissionOnInstitution(this.permissionEnum.HANDLE_PERFORMANCE_MEETING, institution.institutionCode);

        institution.hasCreatingMeetingPermission = hasPermission;
        if (
          institution.isPrimary &&
          hasPermission &&
          institution.institutionCode === defaultInstitution.institutionCode
        ) {
          this.updateMeetingEventType();
          if (this.meetingEventTypes.length > 0 && this.eventForm.type == '') {
            this.changeEventType(this.meetingEventTypes[0].type, true);
          }
        }
      }

      const institutionsWithPermission = this.institutions.filter(i => i.hasCreatingMeetingPermission);
      if (institutionsWithPermission.length > 0) {
        if (!this.isEditMode) {
          const defaultInstitutionIndex = institutionsWithPermission.indexOf(defaultInstitution);
          const index = defaultInstitutionIndex === -1 ? 0 : defaultInstitutionIndex;

          this.institutionCode = institutionsWithPermission[index].institutionCode;
          this.eventForm.institutionCode = institutionsWithPermission[index].institutionCode;
          this.emptyEventForm.institutionCode = institutionsWithPermission[index].institutionCode;
        }
        this.updateMeetingEventType();
        if (this.meetingEventTypes.length > 0 && this.eventForm.type == '') {
          this.changeEventType(this.meetingEventTypes[0].type, true);
        }
      }

      if (this.parent == parentTypes.GROUP) {
        this.inviteMembersFromGroup();
      }
    },
    removeUnavailableResources() {
      for (const resourceId of this.unavailableResourceIds) {
        this.instanceToReset = this.eventForm.timeSlots.findIndex(
          t => t.primaryResource && t.primaryResource.id === resourceId
        );
        this.resetResources = !this.resetResources;
      }
      this.updateUnavailableResources([]);
    },
    showUnavailableResources() {
      this.unavailableResources = [];
      for (const resourceId of this.unavailableResourceIds) {
        const timeSlot = this.eventForm.timeSlots.find(t => t.primaryResource && t.primaryResource.id === resourceId);
        if (timeSlot) {
          this.unavailableResources.push(timeSlot.primaryResource);
        }
      }
      this.$refs.unavailableResourcesModal.show();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.mobile-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.custom-label {
  display: flex;
  padding-right: 5rem;
  align-items: center;

  @include breakpoint-xs-up {
    padding-right: 6rem;
  }

  @include breakpoint-lg {
    padding-right: 0;
  }
}

.event-settings {
  label {
    @include breakpoint-lg-down() {
      font-weight: normal !important;
    }
  }
  & > div:not(:first-child) {
    margin-top: 12px;
  }
}

.input-group-search-addon {
  font-size: 1.5em;
}

.remove_timeslot {
  margin-top: 15px;
  font-weight: bold;
  i {
    cursor: pointer;
  }
  @include breakpoint-sm-down() {
    margin-left: -15px;
    margin-top: 75px;
  }
}

.has-help-box {
  display: flex;
}

.icon-Aula_search {
  font-size: 18px;
}

.search-module {
  .input-group-prepend {
    --padding-top: 17px;
  }
  /deep/ .aula-search {
    --select-input-tags-padding-right: 28px;
  }
}
</style>
