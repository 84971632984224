<template>
  <VacationIntervalsFragmentDayRow class="row">
    <VacationIntervalsFragmentDayCol class="column mobile-day-display col-sm-2 col-3">
      <p class="font-weight-bold">
        {{ 'CALENDAR_TIMES_MOBILE_FOR_ALL_WEEK_TITLE' | fromTextKey }}
      </p>
    </VacationIntervalsFragmentDayCol>
    <VacationIntervalsFragmentDayCol class="column d-flex flex-column">
      <b-form-checkbox
        :checked="isComing"
        :class="{ 'checkbox-text-disabled': disabled }"
        :disabled="disabled || isReadonly"
        :value="true"
        @change="onIsComing(true)"
      >
        {{ 'RESPONSE_VACATION_COMING' | fromTextKey }}
      </b-form-checkbox>
      <b-row v-if="isComing && useTimes" class="ml-1 pt-1">
        <b-col cols="4">
          {{ 'RESPONSE_VACATION_COMMING_HOUR' | fromTextKey }}
        </b-col>
        <b-col cols="8">
          <VacationIntervalsFragmentTimepicker
            id="allWeekTo"
            :value="entryTime"
            :start="startTime"
            :end="endTime"
            :disabled="disabled || isReadonly"
            @change="onUpdateEntryTime"
          />
        </b-col>
        <b-col cols="12" class="separator" />
        <b-col cols="4">
          {{ 'RESPONSE_VACATION_NOT_COMMING_HOUR' | fromTextKey }}
        </b-col>
        <b-col cols="8">
          <VacationIntervalsFragmentTimepicker
            id="allWeekFrom"
            :value="exitTime"
            :start="startTime"
            :end="endTime"
            :disabled="disabled || isReadonly"
            @change="onUpdateExitTime"
          />
        </b-col>
      </b-row>
      <b-form-checkbox
        :checked="isComing"
        :class="{ 'checkbox-text-disabled': disabled }"
        :disabled="disabled || isReadonly"
        :value="false"
        @change="onIsComing(false)"
      >
        {{ 'RESPONSE_VACATION_NOT_COMING' | fromTextKey }}
      </b-form-checkbox>
    </VacationIntervalsFragmentDayCol>
    <aula-modal
      ref="entryTimesExistsWarning"
      @cancelClicked="onCancelEntryTimesExistsWarning()"
      @okClicked="onOkEntryTimesExistsWarning()"
    >
      {{ 'PRESENCE_WARNING_FILL_OUT_TIMES_FOR_ENTIRE_WEEK' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="exitTimesExistsWarning"
      @cancelClicked="onCancelExitTimesExistsWarning()"
      @okClicked="onOkExitTimesExistsWarning()"
    >
      {{ 'PRESENCE_WARNING_FILL_OUT_TIMES_FOR_ENTIRE_WEEK' | fromTextKey }}
    </aula-modal>
  </VacationIntervalsFragmentDayRow>
</template>
<script>
import VacationIntervalsFragmentDayRow from './VacationIntervalsFragmentDayRow';
import VacationIntervalsFragmentDayCol from './VacationIntervalsFragmentDayCol';
import VacationIntervalsFragmentTimepicker from './VacationIntervalsFragmentTimepicker';
import { comeGoUtil } from '../../utils/comeGoUtil';

export default {
  components: { VacationIntervalsFragmentTimepicker, VacationIntervalsFragmentDayCol, VacationIntervalsFragmentDayRow },
  props: {
    vacationRequestDays: {
      required: true,
      type: Array,
    },
    isReadonly: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isComing: null,
      disabled: false,
      entryTime: '',
      exitTime: '',
      startTime: '',
      endTime: '',
      useTimes: true,
      oldEntryTime: '',
      oldExitTime: '',
      entryDisplayTime: '',
      exitDisplayTime: '',
    };
  },
  watch: {
    vacationRequestDays: {
      deep: true,
      immediate: true,
      handler() {
        this.disabled = comeGoUtil.getAreAllVacationRequestDaysDisabled(this.vacationRequestDays);
        this.isComing = comeGoUtil.getIsComingValueForAllVacationRequestDays(this.vacationRequestDays);
        this.useTimes = comeGoUtil.canUseTimesForVacationRequestDays(this.vacationRequestDays);
        this.entryTime = comeGoUtil.getAlignedEntryTimeFromVacationRequestDays(this.vacationRequestDays);
        this.exitTime = comeGoUtil.getAlignedExitTimeFromVacationRequestDays(this.vacationRequestDays);
        this.startTime = comeGoUtil.getLowestStartTimeFromVacationRequestDays(this.vacationRequestDays);
        this.endTime = comeGoUtil.getHighestEndTimeFromVacationRequestDays(this.vacationRequestDays);
      },
    },
  },
  methods: {
    onIsComing(coming) {
      let isComingValue = coming;
      if (coming === this.isComing) {
        isComingValue = null;
      }
      comeGoUtil.updateIsComingValueForVacationRequestDays(this.vacationRequestDays, isComingValue);
    },
    onUpdateExitTime(value) {
      const oldExitTime = this.exitTime;
      const displayTime = value.displayTime;
      this.exitTime = displayTime;
      if (displayTime === '' || oldExitTime === displayTime) {
        this.exitTime = oldExitTime;
        return;
      }
      this.exitDisplayTime = displayTime;
      if (comeGoUtil.hasSpecifiedExitTimeForVacationDay(this.vacationRequestDays)) {
        this.oldExitTime = oldExitTime;
        this.$refs.exitTimesExistsWarning.show();
      } else {
        this.updateExitTimes();
      }
    },
    onUpdateEntryTime(value) {
      const oldEntryTime = this.entryTime;
      const displayTime = value.displayTime;
      this.entryTime = displayTime;
      if (displayTime === '' || oldEntryTime === displayTime) {
        this.entryTime = oldEntryTime;
        return;
      }
      this.entryDisplayTime = displayTime;
      if (comeGoUtil.hasSpecifiedEntryTimeForVacationDay(this.vacationRequestDays)) {
        this.oldEntryTime = oldEntryTime;
        this.$refs.entryTimesExistsWarning.show();
      } else {
        this.updateEntryTimes();
      }
    },
    onOkEntryTimesExistsWarning() {
      if (this.entryDisplayTime) {
        this.updateEntryTimes();
      }

      this.entryDisplayTime = '';
      this.$refs.entryTimesExistsWarning.hide();
    },
    onCancelEntryTimesExistsWarning() {
      this.entryTime = this.oldEntryTime;
      this.oldEntryTime = '';
      this.$refs.entryTimesExistsWarning.hide();
    },
    onOkExitTimesExistsWarning() {
      if (this.exitDisplayTime) {
        this.updateExitTimes();
      }

      this.exitDisplayTime = '';
      this.oldExitTime = '';
      this.$refs.exitTimesExistsWarning.hide();
    },
    onCancelExitTimesExistsWarning() {
      this.exitTime = this.oldExitTime;
      this.oldExitTime = '';
      this.exitDisplayTime = '';
      this.$refs.exitTimesExistsWarning.hide();
    },
    updateEntryTimes() {
      comeGoUtil.updateEntryTimeForVacationRequestDays(this.vacationRequestDays, this.entryDisplayTime);
    },
    updateExitTimes() {
      comeGoUtil.updateExitTimeForVacationRequestDays(this.vacationRequestDays, this.exitDisplayTime);
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  --row-padding-top: 0;
  --row-padding-bottom: 0;
  --row-padding-start: 0;
  --row-padding-end: 0;
}

.mobile-day-display {
  min-width: 100px;
}

.column {
  background-color: var(--color-grey-base);

  &:first-child {
    margin-right: 1px;
  }

  &:last-child {
    margin-left: 1px;
  }
}

.checkbox-text-disabled {
  color: var(--color-dark-grey);
  font-weight: bold;
}

.separator {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
