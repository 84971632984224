<template>
  <div v-if="showNoData && filteredAlerts.length === 0" class="no-data">
    <slot name="no-data" />
  </div>
  <div v-else-if="filteredAlerts.length > 0" class="aula-alert-container no-print">
    <template v-for="(alert, index) in shownNotifications">
      <Notification
        v-if="getShouldUseNotification(alert)"
        :key="alert.notificationId"
        :notification="alert"
        @open="onOpenNotification(alert)"
        @openInfoModal="openInfoModal(alert)"
        @delete="deleteSelectedNotifications(alert)"
      />
      <div v-else :key="index" class="aula-alert" @click="handleClick(alert)">
        <span class="sr-only">
          {{ 'ARIA_LABEL_NOTIFICATION' | fromTextKey }}
        </span>
        <div
          v-if="dismissOnClick.includes(alert.notificationEventType) || isVacationResponseWithNoCustody(alert)"
          class="icon-box-exclamation"
          aria-hidden="true"
        >
          <div class="circle">
            <i class="icon-Aula_exclamation_sign" />
          </div>
        </div>
        <div v-else class="icon-box" aria-hidden="true" @click="handleClick(alert)">
          <i class="icon-Aula_bell" />
        </div>
        <template
          v-if="requiresReply.indexOf(alert.notificationEventType) !== -1 || isVacationResponseWithCustody(alert)"
        >
          <div class="alert-text" role="button" @click="openInfoModal(alert)" @keydown.enter="openInfoModal(alert)">
            <span
              v-if="
                alert.notificationEventType === notificationEventTypes.INVITED_TO_SCHOOL_HOME_MEETING ||
                alert.notificationEventType === notificationEventTypes.INVITED_TO_PARENTAL_MEETING ||
                alert.notificationEventType === notificationEventTypes.INVITED_TO_PERFORMANCE_MEETING
              "
              class="d-inline"
            >
              <span class="alert-title">{{ alert.title | truncated }}</span> - {{ 'ALERT_FROM' | fromTextKey }}
              {{ 'ALERT_DATE' | fromTextKey }}
              {{ alert.startTime | shortDate(true, true) }}
              {{ 'ALERT_TO' | fromTextKey }} {{ 'ALERT_DATE' | fromTextKey }}
              {{ alert.endTime | shortDate(true, true) }}
            </span>
            <span
              v-if="
                alert.notificationEventType === notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED ||
                alert.notificationEventType === notificationEventTypes.INVITED_TO_EXCEPTION_EVENT ||
                alert.notificationEventType === notificationEventTypes.INVITED_TO_SINGLE_OCCURRENCE_OF_EVENT
              "
              class="d-inline"
            >
              <span class="alert-title">{{ alert.title | truncated }}</span> - {{ 'ALERT_FROM' | fromTextKey }}
              {{ 'ALERT_DATE' | fromTextKey }}
              {{ alert.startTime | shortDate(true, true, alert.isAllDayEvent) }}
              <template v-if="alert.endTime != null"
                >{{ 'ALERT_TO' | fromTextKey }}
                {{ 'ALERT_DATE' | fromTextKey }}
                {{ getEventEndTime(alert) }}
              </template>
            </span>
            <span v-if="alert.notificationEventType === notificationEventTypes.VACATION_RESPONSE_REQUIRED">
              {{ alert.vacationRequestName }} - {{ alert.relatedChildName }}:
              {{ 'ALERT_VACATION_RESPONSE_REQUIRED' | fromTextKey }}
              {{ moment(alert.startDate).utc() | shortDate(true) }} -
              {{ moment(alert.endDate).utc() | shortDate(true) }}
            </span>
          </div>
          <div v-button class="text-right alert-link" role="button" @click="openInfoModal(alert)">
            <span :class="{ 'sr-only': isMobile }">{{ 'ALERT_REPLY' | fromTextKey }}</span>
            <i class="icon-Aula_export" />
          </div>
        </template>
        <template
          v-if="dismissOnClick.indexOf(alert.notificationEventType) !== -1 || isVacationResponseWithNoCustody(alert)"
        >
          <div
            v-if="alert.notificationEventType === notificationEventTypes.EVENT_SUBSTITUTE_ADDED"
            class="alert-text alert-overflow"
            @click="goToLesson(alert)"
          >
            {{ alert.notificationMessage }}
            <span class="alert-text--highlight">
              {{ 'ALERT_GO_TO_LESSON_TEXT' | fromTextKey }}
            </span>
          </div>
          <div
            v-if="alert.notificationEventType === notificationEventTypes.EVENT_LESSON_NOTE_CHANGED"
            class="alert-text alert-overflow"
            @click="goToLesson(alert)"
          >
            {{ alert.notificationMessage }}
            <span class="alert-text--highlight">
              {{ 'ALERT_GO_TO_LESSON_TEXT' | fromTextKey }}
            </span>
          </div>
          <div
            v-if="alert.notificationEventType === notificationEventTypes.LOST_ROOM_BECAUSE_OF_EXTERNAL_SCHEDULING"
            class="alert-text alert-overflow"
            @click="
              alert.notificationEventType === notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT
                ? openInfoModal(alert)
                : ''
            "
          >
            {{ 'ALERT_RESOURCE_HAS_BOOKED_TEXT1' | fromTextKey }}
            {{ alert.roomName }}
            {{ 'ALERT_RESOURCE_HAS_BOOKED_TEXT2' | fromTextKey }}
            {{ alert.title | truncated }}
            {{ alertTimeDuration(alert) }}
            {{ 'ALERT_RESOURCE_HAS_BOOKED_TEXT3' | fromTextKey }}
            <a @click="rebookEventResource(alert)">{{ 'ALERT_RESOURCE_HAS_BOOKED_TEXT4' | fromTextKey }}</a>
          </div>
          <div
            v-else-if="alert.notificationEventType === notificationEventTypes.EVENT_CHANGED_NO_RESPONSE_REQUIRED"
            class="alert-text alert-overflow"
            role="button"
            @click="openInfoModal(alert)"
            @keydown.enter="openInfoModal(alert)"
          >
            <span class="alert-title">{{ alert.title | truncated }}</span> - {{ 'ALERT_FROM' | fromTextKey }}
            {{ 'ALERT_DATE' | fromTextKey }}
            {{ alert.startTime | shortDate(true, true) }}
            {{ 'ALERT_TO' | fromTextKey }} {{ 'ALERT_DATE' | fromTextKey }}
            {{ alert.endTime | shortDate(true, true) }}
          </div>
          <div
            v-else-if="alert.notificationEventType === notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT"
            class="alert-text alert-overflow"
            role="button"
            @click="openInfoModal(alert)"
            @keydown.enter="openInfoModal(alert)"
          >
            {{ alert.responderName }}
            {{ 'ALERT_RESPOND_ON_BEHALF' | fromTextKey }} {{ alert.title | truncated }}
          </div>
          <div
            v-else-if="
              alert.notificationEventType === notificationEventTypes.SOMEONE_ELSE_REMOVED_YOUR_RESPONSE_TO_EVENT
            "
            class="alert-text alert-overflow"
            role="button"
            @click="openInfoModal(alert)"
            @keydown.enter="openInfoModal(alert)"
          >
            {{ alert.responderName }}
            {{ 'ALERT_REMOVED_TIMESLOT_RESPONSE' | fromTextKey }}
            {{ alert.title | truncated }}
          </div>
          <div class="text-right alert-link">
            <span
              v-button
              :aria-label="'ARIA_LABEL_CLOSE_NOTIFICATION' | fromTextKey"
              @click="deleteEventNotification(alert.notificationId)"
            >
              <i class="icon-Aula_close" />
            </span>
          </div>
        </template>
      </div>
    </template>
    <b-btn
      v-if="parent !== 'group' && filteredAlerts.length > 2"
      variant="link"
      class="clearfix float-right"
      @click="alertAmount == 2 ? (alertAmount = filteredAlerts.length) : (alertAmount = 2)"
    >
      {{ alertAmount == 2 ? 'ALERT_SEE_ALL_BUTTON' : 'ALERT_SEE_LESS_BUTTON' | fromTextKey }}
    </b-btn>
    <div class="clearfix" />
    <transition :name="isMobile ? 'slide-only' : ''">
      <calendar-popover-single-event
        ref="alertPopoverSingleEvent"
        :popover-event="popoverEvent"
        :related-notification="relatedNotification"
        :is-mobile="isMobile"
        :time-slot-id="currentEventTimeSlotId"
        @changeTimeSlotResponse="changeTimeSlotResponse"
        @respondToEvent="respondToEvent"
        @openDocumentDrawer="openDocumentDrawer"
      />
    </transition>
    <response-meeting-form
      ref="responseNotificationModal"
      :parent="parentTypes.ALERT"
      :related-notification="relatedNotification"
      required-response
      @afterResponseEvent="deleteEventNotification"
    />
    <register-vacation-request-drawer ref="registerVacationRequestDrawer" />
    <aula-modal
      ref="vacationRequestAnsweredWarning"
      :show-cancel="false"
      @okClicked="$refs.vacationRequestAnsweredWarning.hide()"
    >
      {{ 'WANRING_VACATION_REQUEST_ANSWERED' | fromTextKey }}
    </aula-modal>
    <DocumentDrawer
      v-if="isDocumentFormShown"
      :id="selectedDocumentId"
      :type="selectedDocumentType"
      :is-edit-mode="editDrawer"
      @toggleEdit="editDrawer = true"
      @hidden="closeDocumentDrawer"
    />
    <aula-modal
      ref="calendarParticipationWarning"
      :show-cancel="false"
      @okClicked="$refs.calendarParticipationWarning.hide()"
    >
      {{ 'CALENDAR_PARTICIPATION_NO_LONGER_POSSIBLE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="surveyModal"
      css-class="survey-modal"
      :header-icon="iconClassEnum.LOGO"
      header-text=""
      cancel-text="ALERT_SURVEY_DECLINE"
      ok-text="ALERT_SURVEY_ACCEPT"
      @cancelClicked="onSurveyDeclined"
      @okClicked="onSurveyAccepted"
    >
      <template v-if="selectedNotification">
        <h4>{{ selectedNotification.title }}</h4>
        <div class="survey-content" v-html="selectedNotification.content.html" />
      </template>
    </aula-modal>
    <aula-modal
      ref="generalInformationModal"
      css-class="general-information-modal"
      :show-cancel="isMobile"
      :show-ok="false"
      :header-icon="iconClassEnum.LOGO"
      header-text=""
      hide-footer
      @cancelClicked="onGeneralInformationClosed"
    >
      <template v-if="selectedNotification">
        <h4>{{ selectedNotification.title }}</h4>
        <div class="general-information-content" v-html="selectedNotification.content.html" />
      </template>
    </aula-modal>
  </div>
</template>

<script>
import { types } from '../../src/store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import moment from 'moment-timezone';
import { notificationEventTypes } from '../enums/notificationEventTypes';
import { notificationTypes } from '../enums/notificationTypes';
import { notificationAreas } from '../enums/notificationAreas';
import CalendarPopoverSingleEvent from '../../src/components/calendar/CalendarPopoverSingleEvent';
import ResponseMeetingForm from '../../src/components/calendar/ResponseMeetingForm';
import RegisterVacationRequestDrawer from '../../src/components/presence/RegisterVacationRequestDrawer.vue';
import { parentTypes } from '../enums/parentTypes';
import { eventRepeatPatterns } from '../enums/eventRepeatPatterns';
import { eventResponseStatus } from '../enums/eventResponseStatus';
import { convertWeekdayMask } from '../../src/components/calendar/convertWeekdayMask';
import { documentTypes } from '../enums/documentTypes';
import { VacationRegistration } from '../../src/business/vacationRegistration';
import Button from '../directives/button';
import { textUtil } from '../utils/textUtil';
import { portalRoles } from '../enums/portalRoles';
import Notification from './Notification/Notification.vue';
import { iconClassEnum } from '../enums/iconClassEnum';
import * as dateUtil from '../utils/dateUtil';
import { dateFormatEnum } from '../enums/dateFormatEnum';
import DocumentDrawer from '../../src/components/documents/DocumentDrawer.vue';
import { errorSubCodeTypes } from '../enums/errorSubCodeTypes';

export default {
  directives: {
    Button,
  },
  components: {
    DocumentDrawer,
    Notification,
    ResponseMeetingForm,
    CalendarPopoverSingleEvent,
    RegisterVacationRequestDrawer,
  },
  props: {
    parent: { type: String, default: 'post' },
    showNoData: { type: Boolean, default: false },
    filterByActiveGroup: { type: Boolean, default: false },
    filteredAreas: {
      type: Array,
      default: () => [],
    },
    relatedEventId: { type: Number, default: null },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      convertWeekdayMask: convertWeekdayMask,
      requiresReply: [
        notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_REPEATING_EVENT,
        notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_PERFORMANCE_MEETING,
        notificationEventTypes.INVITED_TO_PARENTAL_MEETING,
        notificationEventTypes.INVITED_TO_SCHOOL_HOME_MEETING,
        notificationEventTypes.VACATION_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_EXCEPTION_EVENT,
        notificationEventTypes.INVITED_TO_SINGLE_OCCURRENCE_OF_EVENT,
        notificationEventTypes.INVITED_TO_SURVEY,
      ],
      dismissOnClick: [
        notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT,
        notificationEventTypes.SOMEONE_ELSE_REMOVED_YOUR_RESPONSE_TO_EVENT,
        notificationEventTypes.INVITED_TO_EVENT_NO_RESPONSE_REQUIRED,
        notificationEventTypes.EVENT_CHANGED_NO_RESPONSE_REQUIRED,
        notificationEventTypes.EVENT_CANCELLED_NO_REASON,
        notificationEventTypes.VACATION_REQUEST_CANCELLED_NO_REASON,
        notificationEventTypes.GRANTED_RIGHTS_FOR_OTHER_CALENDAR,
        notificationEventTypes.REMOVED_RIGHTS_FOR_OTHERS_CALENDAR,
        notificationEventTypes.POST_WAS_DELETED,
        notificationEventTypes.POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN,
        notificationEventTypes.LOST_ROOM_BECAUSE_OF_EXTERNAL_SCHEDULING,
        notificationEventTypes.ADDED_TO_COMMON_INBOX,
        notificationEventTypes.REMOVED_FROM_COMMON_INBOX,
        notificationEventTypes.EVENT_SUBSTITUTE_ADDED,
        notificationEventTypes.EVENT_LESSON_NOTE_CHANGED,
        notificationEventTypes.EVENT_CANCELLED_BY_SOMEONE_ELSE,
        notificationEventTypes.SINGLE_OCCURRENCE_EVENT_CANCELLED_NO_REASON,
        notificationEventTypes.DELETION_RULES_INFO,
      ],
      popoverEvent: {},
      eventRepeatPatterns: eventRepeatPatterns,
      notificationEventTypes: notificationEventTypes,
      notificationTypes: notificationTypes,
      notificationAreas: notificationAreas,
      eventResponseStatus: eventResponseStatus,
      parentTypes: parentTypes,
      relatedNotification: null,
      alertAmount: 2,
      selectedDocumentId: null,
      selectedDocumentType: null,
      editDrawer: false,
      isDocumentFormShown: false,
      documentTypes,
      currentEventTimeSlotId: null,
      selectedNotification: null,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      notifications: types.GET_NOTIFICATIONS,
      currentEvent: types.GET_CURRENT_EVENT,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      events: types.GET_EVENTS,
      document: types.GET_DOCUMENTS_LIVE,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
      activeGroupHome: types.GET_ACTIVE_GROUP_HOME,
      isMobile: types.GET_IS_MOBILE,
      guardianVacationResponse: types.GET_VACATION_REQUEST_RESPONSE,
    }),
    shownNotifications() {
      if (this.parent === parentTypes.GROUP) {
        return [];
      }
      return this.filteredAlerts.slice(0, this.alertAmount);
    },
    alerts() {
      return this.notifications.filter(notification => notification.notificationType === notificationTypes.ALERT);
    },
    filteredAreaAlerts() {
      const isCalendar = this.$route.path.includes('kalender');
      const filteredAreas = isCalendar ? [notificationAreas.CALENDAR] : this.filteredAreas;
      return this.alerts.filter(alert => filteredAreas.length === 0 || filteredAreas.includes(alert.notificationArea));
    },
    filteredAlerts() {
      return this.filteredAreaAlerts.filter(alert => {
        if (this.filterByActiveGroup) {
          return this.events.some(event => event.id === alert.eventId);
        }
        if (this.relatedEventId) {
          return alert.eventId === this.relatedEventId;
        }
        if (this.profile.role === this.portalRoles.OTP && this.activeGroupHome?.children) {
          const groupHomeChildIds = this.activeGroupHome.children.map(child => child.id);
          const isActiveGroupHome = groupHomeChildIds.includes(alert.relatedChildInstitutionProfileId);
          const isUniversalAlert = alert.relatedChildInstitutionProfileId == null;
          return isUniversalAlert || isActiveGroupHome;
        }
        const alertInstitution = this.institutions.find(
          institution => institution.institutionCode === alert.institutionCode
        );
        const isActiveInstitution = this.activeInstitutions.includes(alertInstitution?.institutionCode);
        const isUniversalAlert = alert.institutionCode == null;
        return isUniversalAlert || isActiveInstitution;
      });
    },
  },
  watch: {
    notifications(to) {
      if (this.$route.query.openvacationrequestmodal && to.length > 0) {
        const vacationNotification = to.find(
          alert => alert.notificationId.indexOf(this.$route.query.openvacationrequestmodal) !== -1
        );
        if (vacationNotification) {
          this.$nextTick(() => {
            this.openInfoModal(vacationNotification);
          });
        }
        this.$route.query.openvacationrequestmodal = undefined;
      }
    },
  },
  methods: {
    ...mapActions({
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      getEventById: types.GET_EVENT_BY_ID,
      respondEvent: types.RESPOND_TO_SIMPLE_EVENT,
      setEventSelectedFromSearch: types.SET_EVENT_SELECTED_FROM_SEARCH,
      getVacationRegistrationResponse: types.LOAD_VACATION_REQUEST_RESPONSE,
      loadPresenceConfiguration: types.LOAD_PRESENCE_CONFIGURATION,
    }),
    getEventEndTime(alert) {
      const time = dateUtil.format(alert.originalEventEndTime || alert.endTime, dateFormatEnum.SHORT_TIME);
      const date = this.$options.filters.shortDate(alert.endTime, true, false, alert.isAllDayEvent);
      const timePrefix = this.$options.filters.fromTextKey('AT');
      return `${date} ${timePrefix} ${time}`;
    },
    onSurveyDeclined() {
      this.$refs.surveyModal.hide();
      if (this.isMobile) {
        return;
      }
      this.deleteSelectedNotifications(this.selectedNotification);
    },
    onSurveyAccepted() {
      this.$refs.surveyModal.hide();
      window.open(this.selectedNotification.url, '_blank');
    },
    onGeneralInformationClosed() {
      this.$refs.generalInformationModal.hide();
    },
    deleteSelectedNotifications(selectedNotification) {
      const [type, id] = selectedNotification.notificationId.split(':');
      const notificationId = [type, id].join(':');
      const notificationsToDelete = this.notifications.filter(notification =>
        notification.notificationId.includes(notificationId)
      );
      this.deleteNotifications({
        notifications: notificationsToDelete,
      });
    },
    onOpenNotification(notification) {
      switch (notification.notificationEventType) {
        case notificationEventTypes.INVITED_TO_SURVEY:
          this.selectedNotification = notification;
          this.$refs.surveyModal.show();
          break;
        case notificationEventTypes.GENERAL_INFORMATION_SENT:
          this.selectedNotification = notification;
          this.$refs.generalInformationModal.show();
          break;
        case notificationEventTypes.DELETION_RULES_INFO:
          this.$router.push({ name: 'privacypolicy' });
          break;
        case notificationEventTypes.RESOURCE_RESERVATION_DECLINED:
          this.$router.push({
            name: 'editEvent',
            params: { eventId: notification.eventId },
            query: { parent: parentTypes.PROFILE, occurrence: notification.occurrenceDate },
          });
          break;
        default:
          break;
      }
    },
    getShouldUseNotification(alert) {
      return [
        notificationEventTypes.INVITED_TO_SURVEY,
        notificationEventTypes.GENERAL_INFORMATION_SENT,
        notificationEventTypes.EVENT_CANCELLED_NO_REASON,
        notificationEventTypes.SINGLE_OCCURRENCE_EVENT_CANCELLED_NO_REASON,
        notificationEventTypes.EVENT_CANCELLED_BY_SOMEONE_ELSE,
        notificationEventTypes.DELETION_RULES_INFO,
        notificationEventTypes.ADDED_TO_COMMON_INBOX,
        notificationEventTypes.REMOVED_FROM_COMMON_INBOX,
        notificationEventTypes.GRANTED_RIGHTS_FOR_OTHER_CALENDAR,
        notificationEventTypes.REMOVED_RIGHTS_FOR_OTHERS_CALENDAR,
        notificationEventTypes.VACATION_REQUEST_CANCELLED_NO_REASON,
        notificationEventTypes.POST_WAS_DELETED,
        notificationEventTypes.POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN,
        notificationEventTypes.EVENT_CHANGED_BY_SOMEONE_ELSE,
        notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_REPEATING_EVENT,
        notificationEventTypes.RESOURCE_RESERVATION_DECLINED,
        notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED,
      ].includes(alert.notificationEventType);
    },
    getEventCancelledAlertInformation(alert) {
      const startTime = dateUtil.format(alert.startTime, dateFormatEnum.LONG_DATE_TIME_WITH_DATE_PREFIX);
      const triggeredTime = dateUtil.format(alert.triggered, dateFormatEnum.LONG_DATE_TIME);

      return alert.cancelledBy === null
        ? `${startTime} ${this.$options.filters.fromTextKey('ALERT_IS_CANCELLED')} (${triggeredTime})`
        : `${startTime} ${this.$options.filters.fromTextKey('ALERT_CANCELLED_BY')} ${
            alert.cancelledBy
          } (${triggeredTime})`;
    },
    getShortDate(date, ...args) {
      return this.$options.filters.shortDate(date, ...args);
    },
    isVacationResponseWithCustody(alert) {
      return (
        alert.notificationEventType === notificationEventTypes.VACATION_RESPONSE_REQUIRED &&
        this.hasCustodyOrExtendedAccess(alert.relatedChildInstitutionProfileId)
      );
    },
    isVacationResponseWithNoCustody(alert) {
      return (
        alert.notificationEventType === notificationEventTypes.VACATION_RESPONSE_REQUIRED &&
        !this.hasCustodyOrExtendedAccess(alert.relatedChildInstitutionProfileId)
      );
    },
    openDocumentDrawer(data) {
      this.selectedDocumentId = data.documentId;
      this.selectedDocumentType = data.documentType;
      this.editDrawer = false;
      this.$refs.alertPopoverSingleEvent.hide();
      this.isDocumentFormShown = true;
    },
    closeDocumentDrawer() {
      this.isDocumentFormShown = false;
      this.$refs.alertPopoverSingleEvent.show();
    },
    changeTimeSlotResponse(relatedNotification) {
      this.relatedNotification = relatedNotification;
      this.$refs.alertPopoverSingleEvent.hide();
      this.$refs.responseNotificationModal.show();
    },
    handleClick(alert) {
      if (this.requiresReply.indexOf(alert.notificationEventType) !== -1) {
        this.openInfoModal(alert);
      }
    },
    deleteEventNotification(notificationId = null) {
      const notifications = [];
      if (!notificationId) {
        for (const notification of this.notifications) {
          if (
            notification.eventId &&
            notification.eventId == this.popoverEvent.id &&
            notification.relatedChildInstitutionProfileId == this.popoverEvent.relatedChildInstitutionProfileId
          ) {
            notifications.push({
              notificationId: notification.notificationId,
              institutionProfileId: notification.institutionProfileId,
            });
          }
        }
      } else {
        const selectedNotification = this.notifications.find(
          notification => notification.notificationId === notificationId
        );
        const badgeId = this.getBadgeId(selectedNotification);
        const badgeNotification = this.notifications.find(notification => notification.notificationId === badgeId);
        notifications.push({
          notificationId: selectedNotification.notificationId,
          institutionProfileId: selectedNotification.institutionProfileId,
        });
        if (badgeNotification) {
          notifications.push({
            notificationId: badgeNotification.notificationId,
            institutionProfileId: badgeNotification.institutionProfileId,
          });
        }
      }
      this.deleteNotifications({ notifications: notifications });
      if (this.parent === this.parentTypes.CALENDAR) {
        this.$emit('afterResponseNotification');
      }
    },
    getBadgeId(notification) {
      const { notificationId } = notification;
      const splitId = notificationId.split(':').map(c => {
        if (textUtil.isEqual(c, notificationTypes.ALERT, { caseInsensitive: true })) {
          return notificationTypes.BADGE;
        }
        return c;
      });
      return splitId.join(':');
    },
    openInfoModal(alert) {
      if (
        alert.notificationEventType === notificationEventTypes.INVITED_TO_SCHOOL_HOME_MEETING ||
        alert.notificationEventType === notificationEventTypes.INVITED_TO_PARENTAL_MEETING ||
        alert.notificationEventType === notificationEventTypes.INVITED_TO_PERFORMANCE_MEETING ||
        alert.notificationEventType === notificationEventTypes.SOMEONE_ELSE_REMOVED_YOUR_RESPONSE_TO_EVENT
      ) {
        if (alert.eventId != null && alert.eventId != '') {
          this.getEventById({
            eventId: alert.eventId,
          }).then(() => {
            let showMeetingModal = true;
            if (this.currentEvent.timeSlot != null) {
              for (const timeSlot of this.currentEvent.timeSlot.timeSlots) {
                for (const answer of timeSlot.answers) {
                  if (
                    answer.concerningProfile != null &&
                    alert.notificationEventType != notificationEventTypes.INVITED_TO_PERFORMANCE_MEETING &&
                    alert.concerningChildProfileId == answer.concerningProfile.profileId
                  ) {
                    showMeetingModal = false;
                  }
                }
              }
            }
            if (!showMeetingModal) {
              this.popoverEvent = this.currentEvent;
              this.popoverEvent.relatedChildInstitutionProfileId = alert.relatedChildInstitutionProfileId;
              this.$refs.alertPopoverSingleEvent.show();
            } else {
              this.relatedNotification = alert;
              this.$refs.responseNotificationModal.show();
            }
          });
        }
      } else if (alert.notificationEventType === notificationEventTypes.VACATION_RESPONSE_REQUIRED) {
        this.getVacationRegistrationResponse({
          vacationRegistrationResponseId: alert.vacationRegistrationResponseId,
        }).then(() => {
          const isAnswered = !this.guardianVacationResponse.vacationRegistration.isMissingAnswer;
          const child = this.children.find(child => child.id === alert.relatedChildInstitutionProfileId);
          if (!child) {
            return;
          }
          if (isAnswered === false) {
            const vacationRegistrationRequest = new VacationRegistration({
              endDate: alert.endDate,
              isEditable: true,
              isMissingAnswer: true,
              isPresenceTimesRequired: alert.isPresenceTimesRequired,
              noteToGuardian: alert.noteToGuardians,
              responseDeadline: alert.responseDeadline,
              responseId: alert.vacationRegistrationResponseId,
              startDate: alert.startDate,
              title: alert.vacationRequestName,
              vacationRegistrationId: alert.vacationRegistrationResponseId,
            });
            this.$refs.registerVacationRequestDrawer.show(vacationRegistrationRequest, child, false);
          } else {
            this.$refs.vacationRequestAnsweredWarning.show();
            const notifications = this.notifications.filter(
              not =>
                not.notificationEventType == alert.notificationEventType &&
                not.relatedChildInstitutionProfileId == alert.relatedChildInstitutionProfileId &&
                not.vacationRegistrationResponseId == alert.vacationRegistrationResponseId
            );
            this.deleteNotifications({ notifications: notifications });
          }
        });
      } else {
        this.relatedNotification = alert;
        if (alert.eventId != null && alert.eventId != '') {
          this.getEventById({ eventId: alert.eventId, occurrenceDateTime: alert.occurrenceDateTime }).then(() => {
            this.popoverEvent = this.currentEvent;

            if (alert.originalEventEndTime) {
              this.popoverEvent.end = alert.originalEventEndTime;
            }

            this.popoverEvent.relatedChildInstitutionProfileId = alert.relatedChildInstitutionProfileId;
            if (
              alert.notificationEventType == notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT &&
              this.currentEvent.timeSlot != null
            ) {
              const answeredTimeSlot = this.currentEvent.timeSlot.timeSlots.find(timeSlot =>
                timeSlot.answers.some(answer => this.children.some(child => child.id === answer.concerningProfile?.id))
              );
              this.currentEventTimeSlotId = answeredTimeSlot?.id;
              for (const timeSlot of this.currentEvent.timeSlot.timeSlots) {
                for (const answer of this.getRelevantAnswers(timeSlot)) {
                  this.popoverEvent.start = timeSlot.timeSlotIndexes[answer.selectedTimeSlotIndex].startTime;
                  this.popoverEvent.end = timeSlot.timeSlotIndexes[answer.selectedTimeSlotIndex].endTime;
                }
              }
            }
            this.$refs.alertPopoverSingleEvent.show();
          });
        }
      }
    },
    respondToEvent(eventId, responseType, occurrenceDateTime, numberOfAdultParticipants, numberOfChildParticipants) {
      this.respondEvent({
        eventId: eventId,
        responseType: responseType,
        occurrenceDateTime,
        numberOfAdultParticipants,
        numberOfChildParticipants,
      }).then(response => {
        if (response.response?.data.status.subCode === errorSubCodeTypes.exceedingMaximumParticipants) {
          this.$refs.calendarParticipationWarning.show();
        }

        if (this.parent == this.parentTypes.CALENDAR && responseType == this.eventResponseStatus.DECLINED) {
          this.$emit('removeEventFromCalendar', eventId);
        } else {
          this.$emit('afterResponseNotification');
        }
      });
    },
    getRelevantAnswers(timeSlot) {
      // Find all answers on the timeSlot that are relevant to current profile
      return timeSlot.answers.filter(
        answer =>
          answer.concerningProfile != null &&
          answer.concerningProfile.id == this.relatedNotification.relatedChildInstitutionProfileId
      );
    },
    alertTimeDuration(alert) {
      const startTime = moment(alert.startTime);
      const endTime = moment(alert.endTime);
      let result = '';
      if (startTime.format('DD/MM/YYYY') == endTime.format('DD/MM/YYYY')) {
        result = startTime.format('dddd, DD/MM/YYYY HH:mmz') + ' - ' + endTime.format('HH:mm');
      } else {
        result = startTime.format('dddd, DD/MM/YYYY HH:mm') + ' - ' + endTime.format('dddd, DD/MM/YYYY HH:mm');
      }
      return result;
    },
    rebookEventResource(alert) {
      if (this.$route.path.includes('kalender')) {
        // if current parent is CalendarPage
        this.$parent.editEvent(alert.eventId);
      } else {
        // if current page is not Calendar
        this.setEventSelectedFromSearch(alert);
        this.$router.push({ name: 'calendar' });
      }
      this.deleteNotifications({
        notifications: [
          {
            notificationId: alert.notificationId,
            institutionProfileId: alert.institutionProfileId,
          },
        ],
      });
    },
    goToLesson(alert) {
      this.$router.push({ name: 'viewEvent', params: { eventId: alert.eventId } });
      this.deleteEventNotification(alert.notificationId);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/_variables.scss';
@import '../assets/scss/core/_breakpoints.scss';
@import '../assets/scss/components/alerts/_alerts.scss';

.no-data {
  background-color: var(--color-grey-base);
}

.mw-80 {
  max-width: 80%;
  margin: 20px;
  @include breakpoint-lg-down() {
    max-width: 100%;
  }
}

.slide-only-enter-active,
.slide-only-leave-active {
  transition: transform 1s ease;
}

.slide-only-enter,
.slide-only-leave-to {
  transform: translateX(103%);
}
</style>
