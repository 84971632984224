import i18n from '../../../shared/locales/i18n';
import { repeatingEventPatternEnum } from '../../../shared/enums/repeatingEventPatternEnum';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';
export class RepeatingPattern {
  /**
   * Get the repeating pattern depending on repeating type.
   * @param repeatingPattern
   * @param weekdays
   * @param endDate
   * @param dayInMonth
   * @param interval
   * @param occurrenceLimit
   * @returns string
   */
  public static getPattern(
    repeatingPattern: string,
    endDate: string,
    weekdays?: boolean[],
    dayInMonth?: string,
    interval?: number,
    occurrenceLimit?: number
  ): string {
    switch (repeatingPattern) {
      case repeatingEventPatternEnum.DAILY:
        return this.getDailyRepeatingPattern(endDate, interval, occurrenceLimit);
      case repeatingEventPatternEnum.WEEKLY:
        return this.getWeeklyRepeatingPattern(endDate, weekdays, occurrenceLimit);
      case repeatingEventPatternEnum.MONTHLY:
        return this.getMonthlyRepeatingPattern(endDate, dayInMonth, occurrenceLimit);
    }
  }

  /**
   * Get the daily repeating pattern
   * @param interval
   * @param endDate
   * @param occurrenceLimit
   * @returns string
   */
  private static getDailyRepeatingPattern(endDate: string, interval?: number, occurrenceLimit?: number): string {
    if (interval == null) {
      return '';
    }

    if (interval > 1) {
      return this.getIntervalPattern(endDate, interval, occurrenceLimit);
    }

    return this.getDailyPattern(endDate, occurrenceLimit);
  }

  /**
   * i.e. Forekommer dagligt | Forekommer dagligt og slutter efter [X] gentagelser
   * @param endDate
   * @param occurrenceLimit
   * @private
   */
  private static getDailyPattern(endDate: string, occurrenceLimit?: number): string {
    if (occurrenceLimit !== null && occurrenceLimit > 0) {
      return `${i18n.t('label.occur')} ${i18n.t('label.daily')} ${i18n.t('label.end_after', {
        total: occurrenceLimit,
      })}`.trim();
    }

    return `${i18n.t('label.occur')} ${i18n.t('label.daily')} ${endDate}`.trim();
  }

  /**
   * i.e. Forekommer hver [X]. dag | Forekommer hver X. dag og slutter efter [X] gentagelser
   * @param endDate
   * @param interval
   * @param occurrenceLimit
   * @private
   */
  private static getIntervalPattern(endDate: string, interval: number, occurrenceLimit?: number): string {
    if (occurrenceLimit !== null && occurrenceLimit > 0) {
      return `${i18n.t('label.occur')} ${i18n.t('label.every')} ${interval}. ${i18n.t('label.day')} ${i18n.t(
        'label.end_after',
        {
          total: occurrenceLimit,
        }
      )}`.trim();
    }

    return `${i18n.t('label.occur')} ${i18n.t('label.every')} ${interval}. ${i18n.t('label.day')} ${endDate}`.trim();
  }

  /**
   * Get the weekly repeating pattern
   * i.e. Forekommer hver [week day 1], [week day 2] og [week day 3] indtil d. DD. MMM.
   * Forekommer hver [week day 1], [week day 2] og [week day 3] og slutter efter [X] gentagelser
   * @param weekdays
   * @param endDate
   * @param occurrenceLimit
   * @returns string
   */
  private static getWeeklyRepeatingPattern(endDate: string, weekdays?: boolean[], occurrenceLimit?: number): string {
    if (weekdays == null) {
      return '';
    }

    if (occurrenceLimit != null && occurrenceLimit > 0) {
      return `${i18n.t('label.occur')} ${i18n.t('label.every')} ${this.convertToWeekDays(weekdays)} ${i18n.t(
        'label.and_end'
      )} ${i18n.tc('label.number_of_weeks', occurrenceLimit)}`.trim();
    }

    return `${i18n.t('label.occur')} ${i18n.t('label.every')} ${this.convertToWeekDays(weekdays)} ${endDate}`.trim();
  }

  /**
   * Get the weekly repeating pattern
   * i.e.Forekommer hver DD. i måneden | Forekommer hver DD. i måneden og slutter efter [X] gentagelser
   * @param endDate
   * @param dayInMonth
   * @param occurrenceLimit
   * @returns string
   */
  private static getMonthlyRepeatingPattern(endDate: string, dayInMonth?: string, occurrenceLimit?: number): string {
    if (dayInMonth == null) {
      return '';
    }

    if (occurrenceLimit != null && occurrenceLimit > 0) {
      return `${i18n.t('label.occur')} ${i18n.t('label.every')} ${dayInMonth}. ${i18n.t('label.month')} ${i18n.t(
        'label.end_after',
        {
          total: occurrenceLimit,
        }
      )}`.trim();
    }

    return `${i18n.t('label.occur')} ${i18n.t('label.every')} ${dayInMonth}. ${i18n.t(
      'label.month'
    )} ${endDate}`.trim();
  }

  /**
   * Covert weekdayMask to week days.
   * @param weekdayMask The weekdayMask
   * returns string
   */
  private static convertToWeekDays(weekdayMask: boolean[]): string {
    const weekdays = this.getWeekdaysFromWeekdayMask(weekdayMask);
    return this.formatWeekdays(weekdays);
  }

  /**
   * Get weekdays from weekdayMask
   * @param weekdayMask
   * @returns string[]
   */
  private static getWeekdaysFromWeekdayMask(weekdayMask: boolean[]): string[] {
    return weekdayMask
      .map((isActive, index) => (isActive ? DateTimeUtil.getWeekday(index) : null))
      .filter(weekdayName => weekdayName !== null);
  }

  /**
   * Format weekdays to have the correct wording
   * @param weekdays
   * @returns string
   */
  private static formatWeekdays(weekdays): string {
    if (weekdays.length > 1) {
      const lastDay = weekdays.pop();
      return `${weekdays.join(', ')} ${i18n.t('label.and')} ${lastDay}`;
    } else {
      return weekdays.join('');
    }
  }
}
