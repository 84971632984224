<template>
<div class='widget-W0025V0002'>
  <div>
    <form ref="ssoform" type="POST" target="_blank" action="https://mit.hjernenhjertet.dk/aula/announcements/login">
      <input name="aulaToken" v-model="aulaToken" type="hidden">
      <input name="sessionUUID" v-model="sessionUUID" type="hidden">
      <div v-on:click="ssoSubmit" class="widget-title">
        <svg class="hh-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 203.4 193.2" style="enable-background:new 0 0 203.4 193.2;" xml:space="preserve">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-200.0678" y1="519.1269" x2="-199.3607" y2="518.4198">
          <stop offset="0" style="stop-color:#20AC4B"/>
          <stop offset="0.9831" style="stop-color:#19361A"/>
        </linearGradient>
          <g>
          <g>
            <path class="st0" d="M172.8,33.1c-4.1-2.8-10.5-5.5-16.9-8c2.6,3.9,5.9,10.8,5.7,26.6c4,1,7.4,2.5,9,4.4c2.3,2.3,2.9,5.2,1.8,9.2
              c-1.2,5-4.7,10.6-8.8,15.7c-1.4,2-3,4.1-4.8,6.4c-18.2,23.3-44.1,17.9-69.6,7.8c-7.9,10.2-15.8,23.8-9.5,42.1
              c6,6.2,13.4,12.3,21.1,17.4c9.2,5.6,21.5,11.7,32.1,11.7c0.3,0,0.5,0,0.8,0c20.8-3,35.9-28.6,43.8-58.3c3.1-10,6.1-22.4,8-36.2
              C188.7,48.2,182.6,39.2,172.8,33.1z"/>
            <path class="st1" d="M103.8,160.8c-13.8,14.5-20.3,0.2-29.3-24.4c-9.9-27.1,16-49.5,27.4-59.4c0-0.2-0.1-0.5-0.2-0.7
              c-3.9-13.6-21.6-28.6-27.4-29.7c-6.7,1.9-19,5.8-31.3,10.6c-12.3,4.9-20.8,17.6-23.7,23c-12.4,29.2,21,62.6,52.2,86.2
              c31.7,23.9,41.9,15.7,53,6.7c1.4-1.1,2.6-2.2,3.7-3.3C120,169.1,109.8,164,103.8,160.8z"/>
          </g>
            <path class="st2" d="M151.4,23.3c-1.6-2-3.4-3.8-5.5-5.4c-0.1-0.1-0.3-0.2-0.5-0.3c-13.1-9.4-29.8,6.4-39.6,12
          c-26.1-15.7-46.9-9.2-65.6,0.1c-25.9,14-25,30.6-21.6,44.6c3.8-6.5,10.2-13.6,18.9-18.3c-6.7-13.4,14.9-23.6,39.7-12.6
          C103.6,55,109.8,83.5,111.5,98c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c8.9,2,26.2,2.2,35.6-6
          c2.8-2.2,4.1-4.5,4.8-6.5c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0,0,0,0c1.5-4.9,3.6-15.5,4.9-24.4
          C158.8,46,158.1,32.7,151.4,23.3z"/>
        </g>
      </svg>
        <h1 v-if="placement !== 'narrow'">
          Hjernen&Hjertet
        </h1>

        <div v-else>
          HJERNEN&HJERTET
        </div>
      </div>
    </form>

    <!-- Render accordion if placement is narrow -->
    <b-card v-if="announcements && placement === 'narrow'" v-for="(announcement, index) in announcements"
            :title="announcement.title"
            :sub-title="announcement.date" v-b-toggle="'accordion' + index" class="clickable">
      <b-collapse :id="'accordion' + index" accordion="news-accordion"
                  role="tabpanel" class="news-collapse">
        <b-card-text>
          <widget-html :html="announcement.description"></widget-html>
        </b-card-text>
      </b-collapse>
    </b-card>

    <!-- Render announcements so they're all visible at once if we have more space available -->
    <b-card v-if="announcements && placement !== 'narrow'" v-for="(announcement, index) in announcements"
            :title="announcement.title"
            :sub-title="announcement.date" v-b-toggle="'accordion' + index">
      <b-card-text>
        <widget-html :html="announcement.description"></widget-html>
      </b-card-text>
    </b-card>

    <!-- If there are errors, let's show them -->
    <b-alert v-if="error" show variant="danger">
      <h2 class="error-title">Der opstod en fejl</h2>
      <p>
        <span v-if="error.code > 0">Kode {{ error.code }}:</span> {{ error.text }}
        <!-- E.g. Kode 401: Bruger ikke fundet -->
      </p>
      <b-button v-on:click='fetchData' variant='secondary' class="float-right">
        Prøv igen <i class="far fa-sync"></i></b-button>
    </b-alert>

    <!-- ... or if we're still waiting, render a spinner -->
    <div v-if="!announcements && !error" style="position: relative; height: 200px">
      <i class="far fa-spin fa-spinner"></i>
    </div>
  </div>
</div>
</template>

<script>
export default {
  // Initial properties
  data: () => ({
    aulaToken    : undefined,
    error        : undefined,
    announcements: undefined
  }),

  created () {
    this.aulaToken = this.getAulaToken();
  },

  watch: {
    aulaToken: function (val) {
      if (val != undefined) {
        // Fetch data once the token has been generated
        this.aulaToken = val;
        this.fetchData();
      }
    }
  },

  // The input parameters that this Widget expects to receive from the dashboard
  props: {
    axios       : Function,    // An axios instance, specifically made for this Widget. We can do whatever we want with it.
    sessionUUID : String,
    placement   : String,
    getAulaToken: Function,  // This is only available for Secure Widgets
  },

  // Methods available for the component to use
  methods: {
    fetchData () {
      this.error = undefined; // Reset the error, in case one was encountered the last time data was fetched

      // Reset data, in case we're fetching data multiple times
      this.axios.post(
        "https://mit.hjernenhjertet.dk/aula/announcements",
        {
          "aulaToken"  : this.aulaToken,    // Use the token that has been generated asynchronously
          "sessionUUID": this.sessionUUID  // Send the session UUID so user activity can be traced across systems
        })
        .then(response => {
          this.announcements = response.data;
        })
        .catch(e => {
          if (e.response) {
            // We received an error code from the API
            this.error = {
              code: e.response.status,
              text: e.response.statusText
            };
          } else {
            console.log(e);
            // The API was not reachable
            this.error = {
              code: 0,
              text: "Hjernen & Hjertet kunne ikke kontaktes. Prøv igen senere."
            };
          }
        });
    },
    ssoSubmit () {
      this.$refs.ssoform.submit();
    }
  },
}
</script>

<style lang="scss">
.widget-W0025V0002 {
/* Spinner is centered horizontally and vertically within the parent */
.fa-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

/* No scrolling in alerts */
.alert {
  overflow: hidden;
}

.card {
  margin-top: 10px;
}

p {
  font-size: 12px;
}

pre {
  white-space: pre-line; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */

  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #222350;
  margin-bottom: 0;
}

/* The date of the announcement is the subtitle of the card */
.card-subtitle {
  text-transform: capitalize;
  font-size: 14px;
}

/* The title of the announcement */
.card-title, .error-title {
  font-size: 16px;
}

.clickable {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.fa-sync {
  margin-left: 6px;
}

/* SVG icon */
.Arched_x0020_Green {
  fill: url(#SVGID_1_);
  stroke: #FFFFFF;
  stroke-width: 0.25;
  stroke-miterlimit: 1;
}

.st0 {
  fill: #49A2C2;
}

.st1 {
  fill: #5EAFD4;
}

.st2 {
  fill: #96C5DB;
}

.hh-icon {
  height: 32px;
  margin-right: 8px;
}

.widget-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
}
</style>
