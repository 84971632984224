<template>
  <div class="calendar-drawer">
    <div v-if="hasPermission(permissionEnum.HANDLE_EVENTS)" class="hide-tablet-up">
      <multi-button-mobile
        v-if="isMultiButtonVisible"
        :items="multiButtonItems"
        @newEvent="
          $router.push({
            name: parent == parentTypes.PROFILE ? 'createEvent' : 'group-calendar-create-event',
            query: { parent: parent },
          })
        "
        @newMeetingEvent="
          $router.push({
            name: parent == parentTypes.PROFILE ? 'createMeetingEvent' : 'group-calendar-create-meeting-event',
            query: { parent: parent },
          })
        "
        @newVacationEvent="openVacationRequestFromToolbar()"
      />
    </div>
    <div class="calendar-container">
      <transition name="slide-only" appear>
        <b-navbar v-if="parent == parentTypes.PROFILE" class="mobile-page-navbar" :toggleable="false">
          <b-navbar-nav>
            <b-nav-item :class="mobilePageComponent == 'calendar' ? 'active' : ''" @click="activeCalendarView">
              {{ 'CALENDAR_NAVBAR_TAB_CALENDAR' | fromTextKey }}
            </b-nav-item>
            <b-nav-item
              :class="mobilePageComponent == 'notifications' ? 'active' : ''"
              @click="mobilePageComponent = 'notifications'"
            >
              {{ 'CALENDAR_NAVBAR_TAB_NOTIFICATIONS' | fromTextKey }}
              <span v-if="numberOfCalendarNotifications > 0" class="alert-badge">{{
                numberOfCalendarNotifications
              }}</span>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar>
      </transition>
      <template v-if="mobilePageComponent == 'calendar' || mobilePageComponent == 'notifications'">
        <calendar-toolbar
          v-if="showToolbar && !bootstrappingData"
          ref="calendarToolbar"
          :parent="parent"
          :group="group"
          :calendar="calendar"
          :is-mobile="isMobile"
          @changeCalendars="setExistingCalendars(existingCalendars)"
          @openVacationRequestDetail="openVacationRequestDetail"
        />
        <div
          v-if="delegatedContext.id != null"
          class="delegated-access-text"
          @click="onUpdateDelegatedContext({}, true)"
        >
          <div class="circle">
            <i class="icon-Aula_exclamation_sign" />
          </div>
          {{ 'CALENDAR_ACTIVE_DELEGATED_ACCESS_START' | fromTextKey }}
          {{ fullNameOfDelegatedContext }}.
          {{ 'CALENDAR_ACTIVE_DELEGATED_ACCESS_END' | fromTextKey }}
        </div>
        <alerts
          v-if="
            (!isMobile || (isMobile && mobilePageComponent == 'notifications')) &&
            parent !== parentTypes.NOTICE_BOARDS &&
            parent !== parentTypes.GROUP
          "
          :parent="parentTypes.CALENDAR"
          :filter-by-active-group="parent == parentTypes.GROUP"
          @afterResponseNotification="reloadCalendar"
          @removeEventFromCalendar="reloadCalendar"
        />
        <div
          v-if="
            isMobile &&
            mobilePageComponent == 'notifications' &&
            parent !== parentTypes.NOTICE_BOARDS &&
            numberOfCalendarNotifications === 0
          "
          class="no-notification"
        >
          {{ 'CALENDAR_NOTIFY_MESSAGE_NO_NOTIFICATIONS' | fromTextKey }}
        </div>
        <div
          v-if="mobilePageComponent == 'calendar' || !isMobile"
          class="aula-calendar"
          :class="{ 'disable-mouse-clicks': isLoading }"
        >
          <el-date-picker
            ref="calendarDatepicker"
            v-model="datepickerDate"
            class="datetime-input"
            :popper-class="popperClass"
            :append-to-body="false"
            :clearable="false"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            aria-hidden="true"
            prefix-icon="icon-Aula_down-arrow"
            :picker-options="{ firstDayOfWeek: 1 }"
            :editable="false"
            @blur="showCalendarPickerForMobileView"
            @focus="datePickerModifier()"
            @change="changeCalendarDate"
          />
          <h1 v-if="parent != parentTypes.NOTICE_BOARDS" class="mx-2 mx-lg-0 my-2 mt-lg-0">
            {{ 'CALENDAR_PAGE_TITLE' | fromTextKey }}
          </h1>
          <h3 v-if="showWeekNumber && !isMobile && parent !== parentTypes.NOTICE_BOARDS" class="custom-calendar-title">
            {{ 'Uge ' + currentWeek }}
          </h3>
          <h3 v-if="showMonthNumber" class="month custom-calendar-title">
            {{ selectedMonth }}
          </h3>
          <Overlay class="calendar-overlay" :show="isShownLoadingOverlay">
            <template #overlay>
              <AulaSpinner class="calendar-spinner" />
            </template>
            <FullCalendar
              v-if="!bootstrappingData"
              :id="calendarId"
              ref="fullCalendar"
              :default-view="defaultView"
              :plugins="calendarPlugins"
              :views="views"
              locale="da"
              lang="da"
              :title-format="titleFormat"
              :week-numbers="true"
              :selectable="true"
              :first-day="1"
              scroll-time="08:00:00"
              week-number-title="U"
              :content-height="isMobile || parent === parentTypes.NOTICE_BOARDS ? 'auto' : 700"
              :time-grid-event-min-height="25"
              :default-date="defaultDate"
              :header="defaultHeader"
              :all-day-default="false"
              all-day-text=" "
              :slot-label-format="slotLabelFormat"
              :slot-event-overlap="true"
              :column-header-format="columnHeaderFormat"
              scheduler-license-key="0851031242-fcs-1572871596"
              :button-icons="buttonIcons"
              :button-text="buttonText"
              :custom-buttons="customButtons"
              :weekends="true"
              :lazy-fetching="false"
              :dates-above-resources="defaultView !== 'listEvents'"
              :resources="getResources"
              :resource-render="resourceRender"
              :event-sources="eventSources"
              :event-render="eventRender"
              :view-skeleton-render="viewRender"
              :dates-render="datesRender"
              :event-limit-text="'LOAD_MORE_LOWERCASE' | fromTextKey"
              :event-limit-click="onEventLimitClick"
              day-popover-format="dddd D. MMMM"
              @eventPositioned="eventPositioned"
              @loading="isCalLoading"
              @eventClick="eventItemClick"
              @dateClick="dateClick"
              @select="dateClick"
              @eventDrop="eventDrop"
            />
          </Overlay>
        </div>
        <div class="widgets-below">
          <widget-placeholder v-if="isMobile" :list-of-widgets="getListWidgetsNarrow" placement="narrow" />
        </div>
        <widget-placeholder
          v-if="parent !== parentTypes.NOTICE_BOARDS"
          class="p-2 p-lg-4"
          :list-of-widgets="getListWidgets"
          placement="medium"
        />
      </template>
    </div>
    <response-meeting-form
      ref="responseMeetingModal"
      :parent="parent"
      :related-notification="relatedNotification"
      :required-response="selectedTimeSlotId != null"
      @afterResponseEvent="reloadCalendar()"
      @removeEventFromCalendar="removeEventFromCalendar"
    />
    <aula-modal
      ref="cannotMoveRepeatingEventToExistingRepetitionWarningModal"
      :show-cancel="false"
      @okClicked="$refs.cannotMoveRepeatingEventToExistingRepetitionWarningModal.hide()"
    >
      {{ 'CALENDAR_ALERT_REPEATING_EVENT_CANNOT_MOVE_TO_EXISTING_REPETITION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="modalConfirmEditEvent"
      ok-text="CALENDAR_BUTTON_CONFIRM"
      cancel-text="CALENDAR_BUTTON_UNDO"
      @closeClicked="confirmEditEventCancel"
      @cancelClicked="confirmEditEventCancel"
      @okClicked="confirmEditEventOK"
    >
      <p>
        {{ 'CALENDAR_NOTIFY_MESSAGE_1' | fromTextKey }}
        {{ popoverEvent.title }}
        {{ 'CALENDAR_NOTIFY_MESSAGE_2' | fromTextKey }}
        {{ moment(popoverEvent.start).format('dddd') }}
        {{ 'CALENDAR_NOTIFY_MESSAGE_3' | fromTextKey }}
        {{ moment(popoverEvent.start).format('HH:mm') }}?
      </p>
    </aula-modal>
    <aula-modal
      ref="occurrenceDateBeforeFirstOccurrence"
      :show-cancel="false"
      @okClicked="$refs.occurrenceDateBeforeFirstOccurrence.hide()"
    >
      <p>{{ 'CALENDAR_ALERT_OCCURENCE_DATE_BEFORE_FIRST_OCCURRENCE' | fromTextKey }}</p>
    </aula-modal>
    <aula-modal
      ref="occurrenceDateAfterLastOccurrence"
      :show-cancel="false"
      @okClicked="$refs.occurrenceDateAfterLastOccurrence.hide()"
    >
      <p>{{ 'CALENDAR_ALERT_OCCURENCE_DATE_AFTER_LAST_OCCURRENCE' | fromTextKey }}</p>
    </aula-modal>
    <aula-modal ref="modalWarningEditEvent" :show-cancel="false" @okClicked="$refs.modalWarningEditEvent.hide()">
      <p v-if="popoverEvent.timeSlot != null">
        {{ 'CALENDAR_WARNING_EDIT_TIMESLOT' | fromTextKey }}
      </p>
      <p v-else-if="popoverEvent.type == eventTypes.HOLIDAY || popoverEvent.type == eventTypes.PRESENCE_HOLIDAY">
        {{ 'CALENDAR_WARNING_EDIT_HOLIDAY' | fromTextKey }}
      </p>
      <p v-else-if="popoverEvent.type == eventTypes.VACATION_REGISTRATION">
        {{ 'CALENDAR_WARNING_EDIT_PRESENCE_REGISTRATION' | fromTextKey }}
      </p>
      <p
        v-else-if="
          popoverEvent.responseDeadline != null &&
          moment(popoverEvent.responseDeadline).isAfter(moment(popoverEvent.start))
        "
      >
        {{ 'CALENDAR_WARNING_DRAG_DROP_DEADLINE' | fromTextKey }}
      </p>
    </aula-modal>
    <aula-modal
      ref="conflictingEventsModal"
      ok-text="BUTTON_YES"
      @closeClicked="confirmEditEventCancel"
      @cancelClicked="confirmEditEventCancel"
      @okClicked="confirmEditEventOK"
    >
      {{ 'CALENDAR_ALERT_CONFLICTINT_INVITEES' | fromTextKey }}<br /><br />
      <template v-for="(profile, i) in conflictingAttendees">
        {{ profile | displayProfileNameWithMetadata }}<br :key="i" />
      </template>
      {{ 'CALENDAR_ALERT_CONFLICTINT_CONFIRM' | fromTextKey }}<br />
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationModal"
      header-text="CALENDAR_BLOCK_COMMUNICATION_TITLE"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationModal.hide()"
    >
      {{ 'CALENDAR_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      ok-text="YES"
      :is-loading="isLoadingDeletedEvent"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deletedEvent(eventToDelete)"
    >
      <template v-if="isHandlingOthersEvent">
        {{ 'CALENDAR_DELETE_OTHERS_EVENT_WARNING_1' | fromTextKey }}<br />
        {{ 'CALENDAR_DELETE_OTHERS_EVENT_WARNING_2' | fromTextKey }}
      </template>
      <template v-else-if="popoverEvent.type == eventTypes.HOLIDAY || popoverEvent.type == eventTypes.PRESENCE_HOLIDAY">
        {{ 'CALENDAR_DELETE_HOLIDAY_TEXT' | fromTextKey }}<br />{{ 'CALENDAR_DELETE_HOLIDAY_TEXT_2' | fromTextKey }}
      </template>
      <template
        v-else-if="
          (eventToDelete != null && eventToDelete.repeating != null) ||
          (popoverEvent != null && popoverEvent.repeating != null)
        "
      >
        <template v-if="isDeletingOccurrence">
          {{ 'CALENDAR_DELETE_OCCURRENCE_EVENT_TEXT' | fromTextKey }}<br />{{
            'CALENDAR_DELETE_OCCURRENCE_EVENT_TEXT_2' | fromTextKey
          }}
        </template>
        <template v-else>
          {{ 'CALENDAR_DELETE_SERIES_EVENT_TEXT' | fromTextKey }}<br />{{
            'CALENDAR_DELETE_SERIES_EVENT_TEXT_2' | fromTextKey
          }}
        </template>
      </template>
      <template v-else>
        {{ 'CALENDAR_DELETE_EVENT_TEXT' | fromTextKey }}
      </template>
    </aula-modal>
    <aula-modal
      ref="removeResourcesWhenChangingDateTimeModal"
      ok-text="BUTTON_YES"
      @closeClicked="confirmEditEventCancel"
      @cancelClicked="confirmEditEventCancel"
      @okClicked="confirmEditEventOK"
    >
      {{ 'CALENDAR_REMOVE_RESOURCES_WHEN_CHANGING_DATE_TIME' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="calendarPopoverMultipleEvent"
      :is-scroll-top="false"
      :hide-footer="true"
      :hide-back-drop="true"
      :disable-portal="true"
      :css-class="'calendar-popover multiple-events'"
      @closeClicked="$refs.calendarPopoverMultipleEvent.hide()"
    >
      <div v-if="popoverEvents.length > 0" slot="header" class="popover-header">
        <div class="popover-header-text truncate">
          {{ ('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_' + popoverEvents[0].type) | fromTextKey }}
          -
          {{ moment(clickedEventDate).format('DD. MMMM') }}
        </div>
        <i
          class="icon-Aula_close popover-header_close_icon"
          tabindex="0"
          role="button"
          :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
          @click="$refs.calendarPopoverMultipleEvent.hide()"
          @keydown.enter="$refs.calendarPopoverMultipleEvent.hide()"
        />
      </div>
      <div>
        <div class="popover-content scrollbar">
          <div
            v-for="(event, index) in sortedPopoverEvents"
            :key="index"
            class="popover-row"
            :class="event.type == eventTypes.LESSON && index % 2 == 0 ? '' : 'divider'"
          >
            <div class="popover-left-column">
              <template v-if="moment(event.end).diff(moment(event.start), 'days') >= 1 && event.allDay">
                {{ event.start | shortDate }} -
                {{ moment(event.end).subtract(1, 'minutes') | shortDate }}
              </template>
              <template v-else-if="moment(event.end).diff(moment(event.start), 'days') >= 1 && !event.allDay">
                {{ event.start | shortDate }} - {{ event.end | shortDate }}
              </template>
              <template v-else-if="!event.allDay"> {{ event.start | time }} - {{ event.end | time }} </template>
              <template v-else>
                {{ 'CALENDAR_LABEL_EVENT_ALL_DAY' | fromTextKey }}
              </template>
            </div>
            <i
              v-if="canDeleteEvent(event)"
              class="icon-Aula_bin pull-right popover-icon-column"
              :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
              @click="
                $refs.calendarPopoverMultipleEvent.hide();
                eventToDelete = event;
                $refs.calendarPopoverMultipleEvent.hide();
                $refs.deleteModal.show();
              "
            />
            <div class="popover-right-column" :class="!canDeleteEvent(event) ? 'without-icon' : ''">
              <a href="#" @click.prevent="eventItemClick(event)">{{ event.title }}</a>
            </div>
            <div class="clearfix" />
            <div
              class="divider-popover"
              :class="index % 2 !== 0 && event.type == eventTypes.LESSON ? 'divider--state' : ''"
            >
              <div class="divider-popover-left-column" />
              <div class="divider-popover-right-column" />
            </div>
          </div>
        </div>
        <div class="clearfix" />
        <div
          class="popover-footer"
          :class="popoverEvent.type !== eventTypes.LESSON ? 'popover-footer--for-non-schedule' : ''"
        >
          <b-btn variant="link" @click="goToDay">
            {{ 'CALENDAR_GO_TO_DAY' | fromTextKey }}
            <i class="icon-Aula_forward-arrow" />
          </b-btn>
        </div>
      </div>
    </aula-modal>
    <calendar-popover-single-event
      ref="calendarPopoverSingleEvent"
      :popover-event="popoverEvent"
      :is-mobile="isMobile"
      :parent="parent"
      :is-show-more-details="isShowMoreDetails"
      :time-slot-id="selectedTimeSlotId"
      @closeClicked="closePopover"
      @respondToEvent="respondToEvent"
      @changeTimeSlotResponse="changeTimeSlotResponse"
      @openDocumentDrawer="openDocumentDrawer"
    />
    <register-vacation-request-drawer ref="registerVacationRequestDrawer" @onClosed="openDrawer" />
    <drawer v-if="parent !== parentTypes.NOTICE_BOARDS" :show="drawerShow" @change-show="changeDrawerShow">
      <template slot="drawer-title">
        <div class="title">
          <i class="icon-Aula_export hide-tablet-down mr-3" aria-hidden="true" style="font-size: 1.3em" />
          {{ getDrawerTitle }}
          <b-btn
            v-if="canEditCurrentVacationRequest"
            variant="link"
            class="drawer-edit-button read-only-mode mt-0"
            :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
            @click="toggleEditVacationRequest"
          >
            <i class="icon icon-Aula_edit_pen" />
            <span v-if="!isMobile"> {{ 'BUTTON_EDIT' | fromTextKey }}</span>
          </b-btn>
        </div>
      </template>
      <template slot="drawer-content">
        <calendar-event-form
          v-if="drawerShow"
          ref="eventForm"
          :parent="parent"
          @openChildVacationRequest="onOpenChildVacationRequest"
          @removeEventFromCalendar="removeEventFromCalendar"
          @afterUpdated="updatedEvent"
          @reloadCalendar="reloadCalendar"
          @reloadVacationRequest="reloadVacationRequest"
        />
      </template>
    </drawer>
    <DocumentDrawer
      v-if="isDocumentFormShown"
      :id="selectedDocumentId"
      :type="selectedDocumentType"
      :is-edit-mode="editDrawer"
      @toggleEdit="editDrawer = true"
      @hidden="closeDocumentDrawer"
    />
    <aula-modal
      ref="calendarParticipationWarning"
      :show-cancel="false"
      @okClicked="$refs.calendarParticipationWarning.hide()"
    >
      {{ 'CALENDAR_PARTICIPATION_NO_LONGER_POSSIBLE' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import CalendarToolbar from './CalendarToolbar.vue';
import CalendarEventForm from './BaseEvent.vue';
import ResponseMeetingForm from './ResponseMeetingForm.vue';
import Alerts from '../../../shared/components/Alerts.vue';
import moment from 'moment-timezone';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { docTypes } from '../../../shared/enums/docTypes';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import { eventResponseStatus } from '../../../shared/enums/eventResponseStatus';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { documentTypes } from '../../../shared/enums/documentTypes';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import CalendarPopoverSingleEvent from './CalendarPopoverSingleEvent';
import MultiButtonMobile from '../shared/MultiButtonMobile';
import WidgetPlaceholder from '../widgets/WidgetPlaceholder';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import FullCalendar from '@fullcalendar/vue';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import { calendarUtil } from '../../../shared/utils/calendarUtil.ts';
import $ from 'jquery';
import { storageKeyTypes } from '../../../shared/enums/storageKeyTypes';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import { MatchMediaService } from '../../../shared/services/MatchMediaService';
import RegisterVacationRequestDrawer from '../presence/RegisterVacationRequestDrawer';
import { VacationRegistration } from '../../business/vacationRegistration';
import { ChildVacationRegistration } from '../../business/childVacationRegistration';
import { errorSubCodeTypes } from '../../../shared/enums/errorSubCodeTypes';
import { MobileEventRenderer } from '../../business/calendarRenderers/mobileEventRenderer';
import { MonthEventRenderer } from '../../business/calendarRenderers/monthEventRenderer';
import { FiveDaysEventRenderer } from '../../business/calendarRenderers/fiveDaysEventRenderer';
import { WeekEventRenderer } from '../../business/calendarRenderers/weekEventRenderer';
import { DefaultEventRenderer } from '../../business/calendarRenderers/defaultEventRenderer';

import { CalendarViewType } from '../../../shared/enums/calendarViewType';
import { NoticeBoardEventRenderer } from '../../business/calendarRenderers/noticeBoardEventRenderer';
import { format as dateFormat, isSame, startOf, endOf, add as addTime } from '../../../shared/utils/dateUtil';
import DocumentDrawer from '../documents/DocumentDrawer.vue';
import Overlay from '../../../shared/components/Overlay.vue';
import { userTypes } from '../../../shared/enums/userTypes.enum';
import ContactAvatar from '../contacts/ContactAvatar.vue';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';

export const NOTICE_BOARD_EVENT_LIMIT = 50;
export const NOTICE_BOARD_MAXIMUM_FETCHING_MONTH = 12;
export const MAXIMUM_FETCHING_MONTH = 1;

export default {
  provide() {
    return {
      [providerKeyTypes.onUpdateEventTypeFilters]: eventTypeFilters => {
        this.setFilterEvenTypes(eventTypeFilters);
        this.activateFilters();
      },
      [providerKeyTypes.eventTypeFilters]: () => this.filterEventTypes,
      [providerKeyTypes.onUpdateExistingSharedCalendars]: calendars => {
        this.setExistingCalendars(calendars);
        this.addResourceToCalendar();
      },
      [providerKeyTypes.existingSharedCalendars]: () => this.existingCalendars,
      [providerKeyTypes.onUpdateInstitutionCalendars]: calendars => {
        this.setInstitutionCalendars(calendars);
        this.addResourceToCalendar();
      },
      [providerKeyTypes.institutionCalendars]: () => this.institutionCalendars,
      [providerKeyTypes.onUpdateDelegatedContext]: this.onUpdateDelegatedContext,
      [providerKeyTypes.delegatedContext]: () => this.delegatedContext,
      [providerKeyTypes.canHandleOthersEvents]: () => this.canHandleOthersEvents,
      [providerKeyTypes.onDeleteEventClicked]: this.onDeleteEventClicked,
      [providerKeyTypes.isCurrentEventCreator]: () => this.isCurrentEventCreator,
      [providerKeyTypes.isCurrentEventCoOrganizer]: () => this.isCurrentEventCoOrganizer,
      [providerKeyTypes.isHandlingOthersEvent]: () => this.isHandlingOthersEvent,
      [providerKeyTypes.isCurrentEventInvitee]: () => this.isCurrentEventInvitee,
      [providerKeyTypes.isCurrentEventInviteeAsOtp]: () => this.isCurrentEventInviteeAsOtp,
      [providerKeyTypes.occurrenceDateTime]: () => this.occurrenceDateTime,
      [providerKeyTypes.defaultCalendars]: () => this.defaultCalendars,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.delegatedContext.id != null && !to.path.includes('kalender')) {
      await this.setDelegatedContext();
      this.setCalendarActiveInstProfileId(this.profile.id);
      this.loadNotificationsForOwnCalendar();
    }
    next();
  },
  props: {
    parent: { type: String, default: 'profile' },
    noticeBoardId: { type: Number, default: null },
    noticeBoardName: { type: String, default: null },
    calendarId: { type: String, default: 'calendar' },
    showToolbar: { type: Boolean, default: true },
  },
  data: function () {
    return {
      orientationMediaService: new MatchMediaService('(orientation: portrait)'),
      defaultView: null,
      bootstrappingData: false,
      delegatedContextId: null,
      filterEventTypes: [],
      institutionCalendars: [],
      calendarResourcesLimit: 4,
      calendarPlugins: [resourceTimeGridPlugin, listPlugin, dayGridPlugin, interactionPlugin, momentPlugin],
      titleFormat: { month: 'long', day: '2-digit', year: 'numeric' },
      slotLabelFormat: { hour: '2-digit', minute: '2-digit' },
      columnHeaderFormat: { weekday: 'long', day: 'numeric', month: 'long' },
      buttonIcons: {
        prev: ' icon-Aula_back-triangle',
        next: ' icon-Aula_forward-triangle',
      },
      buttonText: {
        today: 'I dag',
        month: 'Måned',
        resourceTimeGridWeek: 'Uge',
        resourceTimeGridDay: 'Dag',
      },
      views: {
        dayGridMonth: {
          columnHeaderFormat: { weekday: 'long' },
          titleFormat: { year: 'numeric' },
          allDaySlot: true,
          weekLabel: ' ',
          eventLimit: 0,
        },
        resourceTimeGridFiveDay: {
          type: 'resourceTimeGridWeek',
          dayCount: 5,
          buttonText: '5 dage',
          weekends: false,
          weekNumbers: false,
          eventLimit: 3,
        },
        listEvents: {
          type: 'listDay',
          noEventsMessage: 'Ingen begivenheder',
          eventLimit: 3,
          listDayFormat: {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          },
          listDayAltFormat: false,
          duration: { days: 30 },
        },
        resourceTimeGridDay: {
          titleFormat: {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            weekday: 'long',
          },
          weekNumbers: false,
          eventLimit: 3,
        },
        resourceTimeGridWeek: {
          weekNumbers: false,
          eventLimit: 3,
        },
      },
      isSwitchingViewPort: false,
      parentTypes: parentTypes,
      defaultDate: moment().toDate(),
      containerSelectorOutSide: '.calendar-container',
      permissionEnum: permissionEnum,
      drawerShow: false,
      datepickerDate: moment().toDate(),
      moment: moment,
      calendar: null,
      amountOfRenderedEvents: 0,
      relatedNotification: {},
      popoverEvent: {},
      popoverEvents: [],
      existingCalendars: [],
      addedEmployee: null,
      getEventsForResource: false,
      earliestEventStart: null,
      eventToDelete: null,
      isDeletingOccurrence: false,
      isLoading: false,
      isLoadingAttachment: false,
      isLoadingDeletedEvent: false,
      confirmEditEventOK: function () {},
      confirmEditEventCancel: function () {},
      currentWeek: moment().isoWeek(),
      selectedMonth: moment().format('MMMM'),
      selectedYear: moment().format('YYYY'),
      showMonthNumber: false,
      showWeekNumber: true,
      mobilePageComponent: 'calendar',
      responseEventType: '',
      eventTypes: eventTypeEnum,
      clickedEventDate: moment(),
      drawerRoutes: [
        'createEvent',
        'editEvent',
        'createMeetingEvent',
        'editMeetingEvent',
        'group-calendar-create-event',
        'group-calendar-create-meeting-event',
        'group-calendar-edit-event',
        'group-calendar-edit-meeting-event',
        'editVacationRequest',
        'viewVacationRequest',
      ],
      meetingEventRouters: [
        'createMeetingEvent',
        'editMeetingEvent',
        'group-calendar-create-meeting-event',
        'group-calendar-edit-meeting-event',
      ],
      originProfile: null,
      originalProfilePortalRole: null,
      isDocumentFormShown: false,
      selectedDocumentId: null,
      selectedDocumentType: null,
      editDrawer: false,
      documentTypes,
      portalRoles,
      selectedTimeSlotId: null,
      calendarSelectedDate: null,
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isMobile: types.GET_IS_MOBILE,
      menuItems: types.GET_MENU_ITEMS,
      events: types.GET_EVENTS,
      currentEvent: types.GET_CURRENT_EVENT,
      profile: types.GET_CURRENT_PROFILE,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeGroupHome: types.GET_ACTIVE_GROUP_HOME,
      institutions: types.GET_INSTITUTIONS,
      isDropdownShown: types.GET_SHOW_GROUPS_DROPDOWN,
      getEventSelectedFromSearch: types.GET_EVENT_SELECTED_FROM_SEARCH,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      notifications: types.GET_NOTIFICATIONS,
      conflictingAttendees: types.GET_WARNING_CONFLICT_EVENT_BY_ATTENDEES,
      group: types.GET_ACTIVE_GROUP,
      contentWrapperHeight: types.GET_CONTENT_WRAPPER_HEIGHT,
      myDelegatedAccesses: types.GET_MY_DELEGATED_ACCESSES,
      calendarActiveInstProfileId: types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID,
      eventTypesForFilter: types.CALENDAR_GET_EVENT_TYPES_FOR_FILTER,
      document: types.GET_DOCUMENTS_LIVE,
      selectedProfile: types.GET_SELECTED_PROFILE,
      activeEvent: types.GET_CURRENT_EVENT,
      hasNonBlockingProfiles: types.GET_HAS_NON_BLOCKING_PROFILES,
    }),
    defaultCalendars() {
      if (this.delegatedContext.id != null) {
        return [this.delegatedContext];
      }
      return [this.defaultProfile];
    },
    isShownLoadingOverlay() {
      if (this.parent === parentTypes.NOTICE_BOARDS) {
        return false;
      }
      return this.bootstrappingData || this.isLoading;
    },
    isAddedOnlyInSchoolCalendar() {
      return (
        this.activeEvent.hideInOwnCalendar &&
        this.hasPermission(permissionEnum.CREATE_EVENTS_ONLY_IN_INSTITUTION_CALENDAR)
      );
    },
    masterDataProfiles() {
      const masterDataProfiles = this.selectedProfile?.institutionProfiles || [];
      return masterDataProfiles.flatMap(profile => {
        if (profile.role === portalRoles.GUARDIAN) {
          return [profile, ...profile.relations];
        }
        return profile;
      });
    },
    isOnNoticeBoard() {
      return this.$route.name === 'notice-board';
    },
    occurrenceDateTime() {
      let occurrenceDateTime = this.$route.query.occurrence;
      if (this.calendarSelectedDate) {
        occurrenceDateTime = this.calendarSelectedDate;
      }
      return occurrenceDateTime;
    },
    isOpenDelegatedContext() {
      return this.delegatedContext?.id != null;
    },
    isHandlingOthersEvent() {
      return !this.isCurrentEventCreator && !this.isCurrentEventCoOrganizer;
    },
    isCurrentEventCreator() {
      if (this.isOpenDelegatedContext) {
        return this.calendarActiveInstProfileId == this.currentEvent?.creator?.id;
      }
      return this.institutions.filter(inst => inst.institutionProfileId == this.currentEvent?.creator?.id).length > 0;
    },
    isCurrentEventCoOrganizer() {
      if (!Array.isArray(this.currentEvent?.coOrganizers)) {
        return false;
      }
      if (this.isOpenDelegatedContext) {
        return this.currentEvent.coOrganizers.some(
          coOrganizer => this.calendarActiveInstProfileId == coOrganizer.instProfile.id
        );
      }
      const institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
      return this.currentEvent.coOrganizers.some(coOrganizer =>
        institutionProfileIds.includes(coOrganizer.instProfile.id)
      );
    },
    isCurrentEventInvitee() {
      if (!Array.isArray(this.currentEvent?.invitees)) {
        return false;
      }
      return this.currentEvent.invitees.some(inv =>
        this.institutions.some(inst => inst.institutionProfileId == inv.instProfile.id)
      );
    },
    isCurrentEventInviteeAsOtp() {
      if (!Array.isArray(this.currentEvent?.invitedGroupHomeChildren)) {
        return false;
      }
      return this.currentEvent.invitedGroupHomeChildren?.some(
        inv => this.activeGroupHome && this.activeGroupHome.id === inv.groupHomeId
      );
    },
    isCurrentEventParticipant() {
      return (
        this.isCurrentEventCreator ||
        this.isCurrentEventCoOrganizer ||
        this.isCurrentEventInvitee ||
        this.isCurrentEventInviteeAsOtp
      );
    },
    canHandleOthersEvents() {
      const eventData = this.popoverEvent.extendedProps || this.currentEvent;
      if (!eventData.institutionCode || eventData.type === eventTypeEnum.BIRTHDAY) {
        return false;
      }
      return this.hasPermissionOnInstitution(permissionEnum.HANDLE_OTHERS_EVENTS, this.currentEvent.institutionCode);
    },
    activeProfileResources() {
      let profile = this.delegatedContext;
      if (profile.id == null) {
        profile = this.profile;
      }
      return this.resources.filter(resource => resource.singleId == profile.id || resource.type === parentTypes.GROUP);
    },
    delegatedContext() {
      return this.myDelegatedAccesses.find(delegatedAccess => delegatedAccess.id === this.delegatedContextId) || {};
    },
    fullNameOfDelegatedContext() {
      return [this.delegatedContext.firstName, this.delegatedContext.lastName].join(' ');
    },
    canEditCurrentVacationRequest() {
      if (this.currentEvent) {
        const hasEditPermission = this.hasPermissionOnInstitution(
          permissionEnum.HANDLE_VACATION_REQUESTS,
          this.currentEvent.institutionCode
        );

        if (
          hasEditPermission &&
          moment().isBefore(this.currentEvent.startDateTime, 'days') &&
          this.$route.name === 'viewVacationRequest'
        ) {
          return true;
        }
      }
      return false;
    },
    numberOfCalendarNotifications() {
      return this.notifications.filter(
        notification =>
          notification.notificationType === notificationTypes.ALERT &&
          notification.notificationArea === notificationAreas.CALENDAR
      ).length;
    },
    isMultiButtonVisible() {
      let isVisible = this.multiButtonItems.length > 0;
      if (this.group && this.group.id && this.$route.name !== 'group-calendar') {
        isVisible = false;
      }
      return isVisible;
    },
    getResources() {
      return async (fetchInfo, successCallback) => {
        await this.getProfileMasterDataForSelectedProfiles();
        successCallback(this.resources);
      };
    },
    isProfileSwitched() {
      return this.originalProfilePortalRole != this.profile.role;
    },
    customButtons() {
      const self = this;
      return {
        openCalendar: {
          icon: ' icon-Aula_down-arrow',
          click() {
            self.openDatePicker();
          },
        },
        weekNumber: {
          text: 'Uge ' + moment().isoWeek(),
        },
      };
    },
    resources() {
      const resourcesArray = [];
      if (
        [portalRoles.GUARDIAN, portalRoles.OTP].indexOf(this.profile.role) > -1 &&
        this.parent === parentTypes.PROFILE
      ) {
        for (const child of this.children) {
          if (
            this.activeChildren.includes(child.id) &&
            resourcesArray.filter(c => c.profileId == child.profileId).length == 0
          ) {
            const resource = {
              id: docTypes.PROFILE.toLowerCase() + '-' + child.id,
              singleId: child.id,
              profileId: child.profileId,
              type: docTypes.PROFILE.toLowerCase(),
              name: child.name,
              eventClassName: child.profileId,
              shortName: child.shortName,
              profilePicture: child.profilePicture,
            };
            resourcesArray.push(resource);
          }
        }
      } else if (this.profile.role === portalRoles.EMPLOYEE && this.parent === parentTypes.PROFILE) {
        for (const existingCalendar of this.existingCalendars) {
          const type = existingCalendar.type || docTypes.PROFILE.toLowerCase();
          const id = existingCalendar.singleId || existingCalendar.id;

          let name = existingCalendar.name || [existingCalendar.firstName, existingCalendar.lastName].join(' ').trim();
          if (type === docTypes.RESOURCE.toLowerCase()) {
            name = existingCalendar.label;
          }

          const profileId = this.delegatedContext?.id || this.profile.profileId;
          let picture = null;
          if (type === docTypes.PROFILE.toLowerCase()) {
            picture = this.getInstitutionProfilePictureForCalendar(existingCalendar);
          }
          resourcesArray.push({
            id: type + '-' + id,
            singleId: id,
            name,
            type,
            eventClassName: type + '-' + id,
            shortName: existingCalendar.shortName,
            profilePicture: picture,
            profileId,
          });
        }
        for (const institutionCalendar of this.institutionCalendars) {
          resourcesArray.push({
            id: institutionCalendar.institutionCode,
            singleId: institutionCalendar.institutionCode,
            name: institutionCalendar.name,
            type: 'institution',
            shortName: institutionCalendar.shortName,
            eventClassName: institutionCalendar.institutionCode,
          });
        }
      } else if (this.profile.role === portalRoles.CHILD && this.parent === parentTypes.PROFILE) {
        const resource = {
          id: docTypes.PROFILE.toLowerCase() + '-' + this.profile.id,
          singleId: this.profile.id,
          type: docTypes.PROFILE.toLowerCase(),
          name: Vue.filter('displayProfileNameWithMetadata')(this.profile),
          eventClassName: this.profile.id,
          shortName: this.profile.shortName,
          profilePicture: this.profile.profilePicture,
          profileId: this.profile.profileId,
        };
        resourcesArray.push(resource);
      } else if (this.parent === parentTypes.NOTICE_BOARDS) {
        const resource = {
          id: parentTypes.NOTICE_BOARDS + '-' + this.noticeBoardId,
          singleId: this.noticeBoardId,
          type: parentTypes.NOTICE_BOARDS,
          name: this.noticeBoardName,
          eventClassName: this.noticeBoardId,
        };
        resourcesArray.push(resource);
      }
      if (this.parent === 'group' && this.group != null) {
        const resource = {
          id: docTypes.GROUP.toLowerCase() + '-' + this.group.id,
          singleId: this.group.id,
          type: docTypes.GROUP.toLowerCase(),
          name: this.group.name,
          eventClassName: this.group.id,
          profilePicture: '',
        };
        resourcesArray.push(resource);
      }
      return resourcesArray;
    },
    eventSources() {
      const self = this;
      const eventSources = [
        {
          events(fetchInfo, successCallback) {
            let start = startOf(fetchInfo.start, 'day');
            let end = endOf(fetchInfo.end, 'day');
            if (self.defaultView === CalendarViewType.EVENTS_LIST.toString()) {
              start = startOf(self.datepickerDate, 'day');
              const amount =
                self.parent === parentTypes.NOTICE_BOARDS
                  ? NOTICE_BOARD_MAXIMUM_FETCHING_MONTH
                  : MAXIMUM_FETCHING_MONTH;

              end = endOf(addTime(self.datepickerDate, amount, 'month'), 'day');
            }
            if (self.parent === parentTypes.PROFILE) {
              const params = self.calculateEventParams(start, end);
              self.resetEvent();
              if (!self.isProfileSwitched) {
                const groups = self.existingCalendars.filter(resource => resource.type == 'group');
                if (groups.length > 0) {
                  self.fetchBirthdayEvents(start, end).then(() => {
                    const groupPromises = groups.map(group => self.fetchGroupEvents(group.singleId, start, end));
                    const instCalendars = self.institutionCalendars.map(inst => inst.institutionCode);
                    const groupAndInstitutionPromises =
                      instCalendars.length > 0
                        ? [...groupPromises, self.fetchInstitutionEvents(instCalendars, start, end)]
                        : groupPromises;

                    Promise.all(groupAndInstitutionPromises).then(() => {
                      self.fetchProfileAndResourceEvents(params).then(() => {
                        successCallback(self.calculateResourcesAndEvents());
                      });
                    });
                  });
                } else {
                  self.fetchBirthdayEvents(start, end).then(() => {
                    self.fetchProfileAndResourceEvents(params).then(() => {
                      const instCalendars = self.institutionCalendars.map(inst => inst.institutionCode);
                      if (instCalendars.length > 0) {
                        self.fetchInstitutionEvents(instCalendars, start, end).then(() => {
                          successCallback(self.calculateResourcesAndEvents());
                        });
                      } else {
                        successCallback(self.calculateResourcesAndEvents());
                      }
                    });
                  });
                }
              }
            } else if (self.parent === parentTypes.NOTICE_BOARDS) {
              self.fetchNoticeBoardEvents(start, end).then(() => {
                successCallback(self.calculateResourcesAndEvents());
              });
            } else {
              const groupId = self.group.id || self.$route.params.groupId;
              if (groupId != null) {
                self.fetchBirthdayEvents(start, end).then(() => {
                  self.fetchGroupEvents(groupId, start, end).then(() => {
                    successCallback(self.calculateResourcesAndEvents());
                  });
                });
              }
            }
          },
        },
      ];
      return eventSources;
    },
    defaultHeader() {
      if (this.parent === parentTypes.NOTICE_BOARDS) {
        return '';
      }
      return this.isMobile
        ? {
            left: 'openCalendar prev,next',
            right: 'resourceTimeGridDay,resourceTimeGridWeek,resourceTimeGridFiveDay,dayGridMonth today',
          }
        : {
            left: 'openCalendar prev,next title',
            right: 'resourceTimeGridDay,resourceTimeGridWeek,resourceTimeGridFiveDay,dayGridMonth today',
          };
    },
    sortedPopoverEvents() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.popoverEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
    },
    getListWidgets() {
      return this.menuItems != undefined ? this.menuItems[moduleWidgetPlacements.BELOW_OF_CALENDAR] : [];
    },
    getListWidgetsNarrow() {
      return this.menuItems != undefined ? this.menuItems[moduleWidgetPlacements.RIGHT_OF_CALENDAR] : [];
    },
    canCreateEvent() {
      const isBlockCommunication = this.profile.communicationBlock && !this.hasNonBlockingProfiles;
      return (
        !isBlockCommunication && this.hasPermission(permissionEnum.HANDLE_EVENTS) && this.parent == parentTypes.PROFILE
      );
    },
    canCreateEventInGroupPage() {
      if (this.group) {
        const activeInstitutionProfile = this.institutions.find(
          institution => institution.institutionCode === this.group.institutionCode
        );
        return (
          !activeInstitutionProfile?.communicationBlock &&
          this.hasPermissionGroup(this.permissionEnum.INVITE_TO_EVENT, this.group.id) &&
          this.parent == parentTypes.GROUP
        );
      }
      return false;
    },
    canCreateMeetingEvent() {
      if (this.parent == parentTypes.PROFILE) {
        return (
          this.profile.role == portalRoles.EMPLOYEE &&
          (this.hasPermission(permissionEnum.HANDLE_PERFORMANCE_MEETING) ||
            this.hasPermission(permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL) ||
            this.hasPermission(permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE))
        );
      }
      return false;
    },
    canCreateMeetingEventInGroupPage() {
      if (this.group != null && this.parent == parentTypes.GROUP) {
        return (
          this.canCreateEventInGroupPage &&
          this.profile.role == this.portalRoles.EMPLOYEE &&
          (this.hasPermissionOnInstitution(
            this.permissionEnum.HANDLE_PERFORMANCE_MEETING,
            this.group.institutionCode
          ) ||
            this.hasPermissionOnInstitution(
              this.permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL,
              this.group.institutionCode
            ) ||
            this.hasPermissionOnInstitution(
              this.permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE,
              this.group.institutionCode
            ))
        );
      }
      return false;
    },
    multiButtonItems() {
      const items = [];
      if (this.canCreateEvent || this.canCreateEventInGroupPage) {
        items.push({
          icon: 'icon-Aula_calendar',
          text: 'CALENDAR_TOOLBAR_NEW_EVENT_MOBILE',
          action: 'newEvent',
        });
      }
      if (this.canCreateMeetingEvent || this.canCreateMeetingEventInGroupPage) {
        items.push({
          icon: 'icon-Aula_conversation',
          text: 'CALENDAR_TOOLBAR_NEW_MEETING_EVENT_MOBILE',
          action: 'newMeetingEvent',
        });
      }
      return items;
    },
    getDrawerTitle() {
      const titles = {
        createEvent: 'CALENDAR_TITLE_CREATE_EVENT',
        editEvent: 'CALENDAR_TITLE_EDIT_EVENT',
        createMeetingEvent: 'CALENDAR_TITLE_CREATE_MEETING_EVENT',
        editMeetingEvent: 'CALENDAR_TITLE_EDIT_MEETING_EVENT',
        'group-calendar-create-event': 'CALENDAR_TITLE_CREATE_EVENT',
        'group-calendar-edit-event': 'CALENDAR_TITLE_EDIT_EVENT',
        'group-calendar-create-meeting': 'CALENDAR_TITLE_CREATE_MEETING_EVENT',
        'group-calendar-edit-meeting': 'CALENDAR_TITLE_EDIT_MEETING_EVENT',
        editVacationRequest: 'CALENDAR_TOOLBAR_REQUEST_VACATION_HEADER',
        'group-calendar-create-meeting-event': 'CALENDAR_TITLE_CREATE_MEETING_EVENT',
      };
      let title = Vue.filter('fromTextKey')('CALENDAR_TITLE_CREATE_EVENT');
      if (this.popoverEvent.type === eventTypeEnum.LESSON && this.$route.name == 'editEvent') {
        title = Vue.filter('fromTextKey')('CALENDAR_TITLE_EDIT_LESSON');
      } else if (titles[this.$route.name] != null) {
        title = Vue.filter('fromTextKey')(titles[this.$route.name]);
      } else if (this.$route.name === 'viewVacationRequest') {
        title = this.currentEvent.title;
      }
      return title;
    },
    defaultProfile() {
      return {
        label: Vue.filter('displayProfileNameWithMetadata')(this.profile),
        value: docTypes.PROFILE.toLowerCase() + this.profile.id + this.profile.institutionCode,
        name: Vue.filter('displayProfileNameWithMetadata')(this.profile),
        role: this.profile.role,
        id: docTypes.PROFILE.toLowerCase() + '-' + this.profile.id,
        singleId: this.profile.id,
        shortName: this.profile.shortName,
        type: docTypes.PROFILE.toLowerCase(),
        institutionCode: this.profile.institutionCode,
        profilePicture: this.profile.profilePicture,
      };
    },
    popperClass() {
      let cssClass = '';
      if (this.parent === parentTypes.PROFILE) {
        cssClass = 'calendar-datepicker';
      } else {
        cssClass = 'group-calendar-datepicker';
      }
      return cssClass;
    },
    isShowMoreDetails() {
      if (
        (this.popoverEvent.type == eventTypeEnum.EVENT || this.popoverEvent.type == eventTypeEnum.EXCURSION) &&
        this.popoverEvent.creator != null &&
        this.popoverEvent.creator.id !== this.profile.id &&
        this.popoverEvent.responseRequired
      ) {
        for (const attendee of this.popoverEvent.invitees) {
          if (
            (this.institutions.find(inst => inst.institutionProfileId == attendee.instProfile.id) != null ||
              attendee.instProfile.id == this.calendarActiveInstProfileId) &&
            attendee.responseType !== eventResponseStatus.WAITING
          ) {
            return true;
          }
        }
        for (const attendee of this.popoverEvent.invitedGroupHomeChildren) {
          if (
            this.activeGroupHome &&
            this.activeGroupHome.id === attendee.groupHomeId &&
            attendee.responseType !== eventResponseStatus.WAITING
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      loadMyDelegatedAccesses: types.LOAD_MY_DELEGATED_ACCESSES,
      loadEventsByProfileIdsAndResourceIds: types.LOAD_EVENTS_BY_PROFILEID,
      loadEventByGroupHome: types.LOAD_EVENTS_BY_GROUPHOME,
      loadBirthdayEvents: types.LOAD_BIRTHDAY_EVENTS,
      loadEventsByInstitutions: types.LOAD_EVENTS_BY_INSTITUTIONS,
      getEventById: types.GET_EVENT_BY_ID,
      getNoticeBoardEvent: types.LOAD_NOTICE_BOARD_EVENT,
      loadEventsByGroupId: types.LOAD_EVENTS_BY_GROUPID,
      loadNoticeBoardEvents: types.LOAD_NOTICE_BOARD_EVENTS,
      editTimeByDraggingEvent: types.EDIT_TIME_BY_DRAGGING_EVENT,
      deleteEvent: types.DELETE_EVENT,
      setEventSelectedFromSearch: types.SET_EVENT_SELECTED_FROM_SEARCH,
      modifyDateTimeSelectedByClickedTimeslot: types.MODIFY_DATETIME_SELECTED_BY_CLICKED_TIMESLOT,
      clearEvents: types.CLEAR_EVENTS,
      respondEvent: types.RESPOND_TO_SIMPLE_EVENT,
      warningConflictEventByAttendees: types.WARNING_CONFLICT_EVENT_BY_ATTENDEES,
      getAvailableResources: types.LIST_AVAILABLE_RESOURCES,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      setDelegatedContext: types.SET_DELEGATED_CONTEXT,
      getMasterDataForProfiles: types.SELECT_PROFILE,
      getSameAuthorityInstitutions: types.ACTION_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS,
    }),
    ...mapMutations({
      resetEvent: types.MUTATE_CLEAR_EVENTS,
      setCalendarActiveInstProfileId: types.MUTATE_CALENDAR_ACTIVE_INST_PROFILE_ID,
      resetActiveEvent: types.MUTATE_RESET_ACTIVE_EVENT,
    }),
    getProfileMasterDataForSelectedProfiles() {
      const selectedProfileIds = this.existingCalendars
        .filter(profile => profile.type === parentTypes.PROFILE)
        .map(profile => profile.singleId || profile.id);
      if (this.delegatedContextId) {
        selectedProfileIds.push(this.delegatedContextId);
      }
      if (selectedProfileIds.length === 0) {
        return;
      }
      return this.getMasterDataForProfiles({
        instProfileIds: selectedProfileIds,
        fromAdministration: false,
      }).catch(() => {
        this.defaultCalendars;
      });
    },
    getInstitutionProfilePictureForCalendar(profileCalendar) {
      const profile = this.masterDataProfiles.find(
        profile => profile.id == profileCalendar.id || profile.id == profileCalendar.singleId
      );
      return profile?.profilePicture || null;
    },
    openDrawer() {
      this.drawerShow = true;
    },
    onOpenChildVacationRequest({ child, hasAnswered, registrationResponseId }) {
      const vacationRegistration = new VacationRegistration({
        endDate: this.activeEvent.end,
        isEditable: true,
        isMissingAnswer: !hasAnswered,
        isPresenceTimesRequired: this.activeEvent.vacationRegistration.isPresenceTimesRequired,
        noteToGuardian: this.activeEvent.vacationRegistration.noteToGuardians,
        responseDeadline: this.activeEvent.responseDeadline,
        responseId: registrationResponseId,
        startDate: this.activeEvent.start,
        title: this.activeEvent.title,
        vacationRegistrationId: registrationResponseId,
      });
      const childInstance = new ChildVacationRegistration({
        ...child,
        institutionCode: this.activeEvent.institutionCode,
      });

      this.$refs.registerVacationRequestDrawer.show(vacationRegistration, childInstance, false);
      this.drawerShow = false;
    },
    onDeleteEventClicked(eventToDelete, isDeletingOccurrence) {
      this.eventToDelete = eventToDelete;
      this.isDeletingOccurrence = isDeletingOccurrence;
      this.$refs.deleteModal.show();
    },
    async onUpdateDelegatedContext(delegatedContext, setToDefault) {
      await this.updateDelegatedContext({ institutionProfileId: delegatedContext.id });
      this.showCalendarPickerForMobileView();
      this.reloadCalendarWithNewActiveProfileId(this.delegatedContext, setToDefault);
    },
    async updateDelegatedContext({ institutionProfileId }) {
      this.delegatedContextId = institutionProfileId;
      try {
        await this.setDelegatedContext({
          delegatedInstProfileId: institutionProfileId,
        });
      } catch {
        this.delegatedContextId = null;
      }
      this.resetSearchFilters();
      this.updateFilterStorage();
    },
    setFilterEvenTypes(filterEventTypes) {
      this.filterEventTypes = filterEventTypes;
    },
    setExistingCalendars(calendars) {
      let calendarList = calendars; // 'calendars' from the localStorage may contain expired / null profilePicture
      if (calendarList.length === 0 && this.institutionCalendars.length === 0) {
        calendarList = this.defaultCalendars;
      }
      this.existingCalendars = [...calendarList];
    },
    setInstitutionCalendars(calendars) {
      this.institutionCalendars = calendars;
    },
    async setCalendarFiltersFromStorage() {
      if ([parentTypes.GROUP, parentTypes.NOTICE_BOARDS].includes(this.parent)) {
        return;
      }
      const profileId = this.profile.profileId;
      const profileFiltersOption = this.AulaStorage.getItem(
        profileId + storageKeyTypes.calendarFilters,
        { encryption: this.profile.encryptionKey },
        {
          institutionCalendars: this.institutionCalendars,
          calendars: this.existingCalendars,
          delegatedContextId: this.delegatedContextId,
        }
      );

      this.delegatedContextId = profileFiltersOption.delegatedContextId;

      this.setExistingCalendars(profileFiltersOption.calendars);
      this.setInstitutionCalendars(profileFiltersOption.institutionCalendars);

      if (profileFiltersOption.delegatedContextId != null) {
        await this.updateDelegatedContext({ institutionProfileId: profileFiltersOption.delegatedContextId });
      }
      if (this.delegatedContextId) {
        await this.reloadCalendarWithNewActiveProfileId(this.delegatedContext, false);
      }
    },
    updateFilterStorage() {
      if ([parentTypes.GROUP, parentTypes.NOTICE_BOARDS].includes(this.parent)) {
        return;
      }
      const profileId = this.profile.profileId;
      this.AulaStorage.setItem(
        profileId + storageKeyTypes.calendarFilters,
        { encryption: this.profile.encryptionKey },
        {
          calendars: this.existingCalendars,
          institutionCalendars: this.institutionCalendars,
          delegatedContextId: this.delegatedContextId,
        }
      );
    },
    resetSearchFilters() {
      if ([parentTypes.GROUP, parentTypes.NOTICE_BOARDS].includes(this.parent)) {
        return;
      }
      this.setExistingCalendars([]);
      this.setInstitutionCalendars([]);
    },
    onEventLimitClick(info) {
      this.setCalendarStylesForPopover(info.moreEl);
      return 'popover';
    },
    openVacationRequestDetail(eventId) {
      this.$router.push({
        name: 'viewVacationRequest',
        params: { id: eventId },
      });
      this.drawerShow = true;
    },
    openDocumentDrawer(data) {
      this.selectedDocumentId = data.documentId;
      this.selectedDocumentType = data.documentType;
      this.editDrawer = false;
      this.$refs.calendarPopoverSingleEvent.hide();
      this.isDocumentFormShown = true;
    },
    closeDocumentDrawer() {
      this.isDocumentFormShown = false;
      this.$refs.calendarPopoverSingleEvent.show();
      this.loadSingleEventAndShowPopover(this.$route.params.eventId);
    },
    closeDeletionWarning() {
      this.resetActiveEvent();
      this.$refs.deleteModal.hide();
    },
    closePopover() {
      this.$refs.calendarPopoverSingleEvent.hide();
    },
    calculateEventParams(start, end) {
      // get instProfileIds from sharedCalendar first
      let instProfileIds = this.existingCalendars
        .filter(profile => profile.type === parentTypes.PROFILE || this.delegatedContext.id == profile.id)
        .map(profile => Number(profile.singleId || profile.id));

      if ([portalRoles.OTP, portalRoles.GUARDIAN].indexOf(this.defaultProfile.role) > -1) {
        instProfileIds = instProfileIds.concat(this.activeChildren);
      }
      if (this.delegatedContext.id == null) {
        // then Add institution profile id for active institutions
        const activeInstitutions = this.institutions.filter(institution =>
          this.activeInstitutions.includes(institution.institutionCode)
        );

        instProfileIds = instProfileIds.filter(profileId => profileId != this.profile.id);

        for (const institution of activeInstitutions) {
          if (instProfileIds.filter(id => id == institution.institutionProfileId).length === 0) {
            instProfileIds.push(institution.institutionProfileId);
          }
        }
      }
      const resourceIds = this.existingCalendars
        .filter(resource => resource.type == 'resource')
        .map(resource => resource.singleId);
      return {
        instProfileIds: instProfileIds,
        resourceIds: resourceIds,
        start: start,
        end: end,
        calendarActiveInstProfileId: this.calendarActiveInstProfileId,
      };
    },
    deleteNotificationsOnEvent(eventId) {
      const notificationsToDelete = this.notifications.filter(notification => {
        const isEventBadgeNotification =
          notification.notificationType === notificationTypes.BADGE &&
          [
            notificationEventTypes.INVITED_TO_EVENT_NO_RESPONSE_REQUIRED,
            notificationEventTypes.EVENT_CHANGED_NO_RESPONSE_REQUIRED,
          ].includes(notification.notificationEventType);
        const isLessonNotification =
          notification.notificationEventType === notificationEventTypes.EVENT_LESSON_NOTE_CHANGED;

        return notification.eventId === parseInt(eventId) && (isEventBadgeNotification || isLessonNotification);
      });

      if (notificationsToDelete.length === 0) {
        return;
      }
      this.deleteNotifications({ notifications: notificationsToDelete }).then(() => {
        this.rerenderEvents();
      });
    },
    fetchInstitutionEvents(instCalendars, start, end) {
      return this.loadEventsByInstitutions({
        instCodes: instCalendars,
        start,
        end,
      });
    },
    fetchProfileAndResourceEvents(params) {
      if (this.profile.role === portalRoles.OTP) {
        params.groupHomeChildPairs = this.activeChildren.map(activeChildId => ({
          regardingChildId: activeChildId,
          groupHomeId: this.activeGroupHome.id,
        }));

        return this.loadEventByGroupHome(params);
      } else {
        return this.loadEventsByProfileIdsAndResourceIds(params).catch(error => {
          this.resetSearchFilters();
          this.updateFilterStorage();
          // check to make sure user does not reload calendar forever if user is denied
          if (error.response.status !== 403) {
            this.reloadCalendar();
          }
        });
      }
    },
    fetchGroupEvents(groupId, start, end) {
      return this.loadEventsByGroupId({
        groupId: groupId,
        start: start,
        end: end,
        calendarActiveInstProfileId: this.calendarActiveInstProfileId,
      });
    },
    fetchNoticeBoardEvents(start, end) {
      return this.loadNoticeBoardEvents({
        start: moment(),
        end: end,
        noticeBoardId: this.noticeBoardId,
        limit: NOTICE_BOARD_EVENT_LIMIT,
      });
    },
    fetchBirthdayEvents(start, end) {
      const groupId = this.group.id;
      let activeInstCodes = this.activeInstitutions;
      if (
        this.calendarActiveInstProfileId !== this.profile.id &&
        this.existingCalendars.length > 0 &&
        this.existingCalendars[0].institution != null
      ) {
        activeInstCodes = [this.existingCalendars[0].institution.institutionCode];
      }
      return this.loadBirthdayEvents({
        instCodes: activeInstCodes,
        calendarInstProfileId: this.calendarActiveInstProfileId,
        groupId: groupId,
        start: start,
        end: end,
        source: 'calendar',
      });
    },
    async addResourceToCalendar() {
      this.updateFilterStorage();
      this.reloadCalendar();
    },
    calculateResourcesAndEvents() {
      return this.prepareAllEvents();
    },
    prepareAllEvents() {
      const eventsArray = [];
      let viewType = '';
      if (this.calendar != null && this.calendar.view != null) {
        viewType = this.calendar.view.type;
      }
      for (const event of this.events) {
        // set event to be editable or not
        if (this.hasRightOfDisposal(event) && !event.type.includes('sum-') && !event.type.includes('birthday')) {
          // Allow events’ start times to be editable through dragging.
          event.startEditable = true;
        } else {
          event.startEditable = false;
        }

        const isDayGridMonth = viewType === CalendarViewType.DAY_GRID_MONTH.toString();
        const eventRelatedIds = getEventRelatedIds(event);
        const hasEventResource = this.resources.some(resource => eventRelatedIds.includes(resource.id));

        const isSumEvent = event.type.includes('sum-');
        if (isDayGridMonth === isSumEvent && hasEventResource) {
          if (this.isMobile) {
            const activeProfileResources = this.activeProfileResources;
            if (this.isEventDeclinedByUser(event, activeProfileResources[0]?.id)) {
              continue;
            }
            const activeResource = activeProfileResources.find(resource => eventRelatedIds.includes(resource.id));
            eventsArray.push({
              ...event,
              resourceIds: event.resourceIds,
              parentId: activeResource?.id || eventRelatedIds[0],
              eventResourceIds: eventRelatedIds,
            });
          } else {
            if (isSumEvent) {
              eventsArray.push({
                ...event,
                resourceIds: event.resourceIds,
                parentId: undefined,
                eventResourceIds: [],
              });
            } else {
              for (const resourceId of eventRelatedIds) {
                const hasResource = this.resources.some(resource => resource.id === resourceId);
                if (!hasResource || this.isEventDeclinedByUser(event, resourceId)) {
                  continue;
                }
                eventsArray.push({
                  ...event,
                  resourceIds: [resourceId],
                  parentId: resourceId,
                });
              }
            }
          }
        }
      }
      return eventsArray;

      function getEventRelatedIds(event) {
        // concatenates both event belongsTo array and resourceIds array
        //  returns array with  unique values from concatenation
        const eventBelongsTo = isArray(event.belongsTo) ? event.belongsTo : [];
        const eventResourceIds = isArray(event.resourceIds) ? event.resourceIds : [];
        return uniqWith([...eventBelongsTo, ...eventResourceIds], isEqual);
      }
    },
    resourceRender(renderInfo) {
      const resourceObj = renderInfo.resource;
      const extendedProps = { ...resourceObj.extendedProps, id: parseInt(resourceObj.extendedProps.singleId) };
      renderInfo.el.innerHTML = '';

      const AvatarClass = Vue.extend(ContactAvatar);
      const disabled = extendedProps.type !== userTypes.PROFILE;
      const avatarInstance = new AvatarClass({
        parent: this,
        propsData: {
          profile: extendedProps,
          disabled,
        },
      });
      avatarInstance.$mount();
      avatarInstance.$el.classList.add('calendar-resource');
      renderInfo.el.appendChild(avatarInstance.$el);
    },
    eventRender: function (info) {
      const eventRenderer = this.getEventRenderer(info);
      if (eventRenderer) {
        info.el.innerHTML = eventRenderer.render();
      }
    },
    isCalLoading(isLoading) {
      this.isLoading = isLoading;
      if (!isLoading) {
        this.showCalendarPickerForMobileView();
        this.setTabindexForMoreButtons();
        if (this.parent !== parentTypes.NOTICE_BOARDS) {
          this.$refs.calendarToolbar.emitFilters();
        }
        if (this.getEventSelectedFromSearch && this.getEventSelectedFromSearch.notificationType) {
          // Selected Event from Notification
          this.editEvent(this.getEventSelectedFromSearch.eventId);
          this.setEventSelectedFromSearch(null);
        } else if (this.getEventSelectedFromSearch && this.getEventSelectedFromSearch.id) {
          this.loadSingleEventAndShowPopover(
            this.getEventSelectedFromSearch.id,
            '',
            this.getEventSelectedFromSearch.startDateTime,
            this.getEventSelectedFromSearch.endDateTime,
            null,
            true
          );
        }
        if (!this.isSwitchingViewPort && !this.$route.path.includes('begivenhed')) {
          this.$refs.calendarPopoverSingleEvent.hide();
          this.$refs.calendarPopoverMultipleEvent.hide();
        } else {
          this.isSwitchingViewPort = false;
        }
        this.setEarliestEventStart();
        this.activateFilters();
      }
    },
    setTabindexForMoreButtons() {
      this.$nextTick(() => {
        const fcMoreButtons = this.$refs.fullCalendar.$el.querySelectorAll('.fc-more-cell a.fc-more');
        fcMoreButtons.forEach(el => {
          el.tabIndex = 0;
          el.addEventListener('keyup', event => {
            event.preventDefault();
            if (event.key === 'Enter') {
              el.click();
            }
          });
        });
      });
    },
    setEarliestEventStart() {
      if (this.earliestEventStart == null) {
        this.earliestEventStart = moment().hour(8).minute(0);
      }

      let earliestStartHour = this.earliestEventStart.hour();
      let earliestStartMinute = this.earliestEventStart.minute();
      for (const event of this.events) {
        const eventStartHour = moment(event.start).hour();
        const eventStartMinute = moment(event.start).minute();

        if (event.allDay) {
          continue;
        }

        const isLessMinutes = eventStartHour === earliestStartHour && eventStartMinute < earliestStartMinute;
        if (eventStartHour < earliestStartHour || isLessMinutes) {
          earliestStartHour = eventStartHour;
          earliestStartMinute = eventStartMinute;
          this.earliestEventStart = moment(event.start);
        }
      }
      this.calendar.scrollToTime(this.earliestEventStart.format('HH:mm'));
    },
    eventPositioned(info) {
      const event = info.event;
      const extendedProps = event.extendedProps;

      // Hide existing events right after view switch, if they do not belong to new view type
      if (
        this.calendar.view.type === CalendarViewType.DAY_GRID_MONTH.toString() &&
        !extendedProps.type.includes('sum-')
      ) {
        info.event.remove();
      } else if (
        this.calendar.view.type !== CalendarViewType.DAY_GRID_MONTH.toString() &&
        extendedProps.type.includes('sum-')
      ) {
        info.event.remove();
      }
      $('.fc-event').off('keypress');
      $('.fc-event').on('keypress', function (e) {
        if (e.which == 13) {
          $(this)[0].click();
        }
      });
    },
    datesRender(info) {
      this.earliestEventStart = null;
      const view = info.view;

      if (view.type === CalendarViewType.DAY_GRID_MONTH.toString()) {
        this.selectedMonth = moment(view.currentStart).format('MMMM');
        this.selectedYear = moment(view.currentStart).format('YYYY');
        this.showWeekNumber = false;
        this.showMonthNumber = true;
        const weeks = $('.fc-content-skeleton thead .fc-week-number');
        for (let i = 0; i < weeks.length; i++) {
          $(weeks[i])
            .find('span')
            .html('Uge ' + $(weeks[i]).find('span').html());
        }
      } else if (
        view.type === CalendarViewType.DAY_GRID.toString() ||
        view.type === CalendarViewType.WEEK_GRID.toString() ||
        view.type === CalendarViewType.FIVE_DAYS_GRID.toString() ||
        view.type === CalendarViewType.EVENTS_LIST.toString()
      ) {
        this.currentWeek = moment(view.currentStart).isoWeek();
        this.showWeekNumber = true;
        this.showMonthNumber = false;
      }
      this.datepickerDate = moment(view.currentStart).format();
    },
    viewRender(info) {
      const view = info.view;
      if (this.calendar != null) {
        this.updateCalendarView();
      }

      // Create aria label for prev and next buttons
      let nextStart = moment(view.currentStart).add(5, 'days').format('D. MMMM YYYY');
      let prevStart = moment(view.currentStart).subtract(5, 'days').format('D. MMMM YYYY');
      let nextEnd = moment(view.currentEnd).add(5, 'days').format('D. MMMM YYYY');
      let prevEnd = moment(view.currentEnd).subtract(5, 'days').format('D. MMMM YYYY');
      if (view.type === CalendarViewType.DAY_GRID_MONTH.toString()) {
        nextStart = moment(view.currentStart).add(1, 'months').format('D. MMMM YYYY');
        prevStart = moment(view.currentStart).subtract(1, 'months').format('D. MMMM YYYY');
        nextEnd = moment(view.currentEnd).add(1, 'months').format('D. MMMM YYYY');
        prevEnd = moment(view.currentEnd).subtract(1, 'months').format('D. MMMM YYYY');
      } else if (view.type === CalendarViewType.DAY_GRID.toString()) {
        nextStart = moment(view.currentStart).add(1, 'days').format('D. MMMM YYYY');
        prevStart = moment(view.currentStart).subtract(1, 'days').format('D. MMMM YYYY');
        nextEnd = moment(view.currentEnd).add(1, 'days').format('D. MMMM YYYY');
        prevEnd = moment(view.currentEnd).subtract(1, 'days').format('D. MMMM YYYY');
      } else if (view.type === CalendarViewType.WEEK_GRID.toString()) {
        nextStart = moment(view.currentStart).add(1, 'weeks').format('D. MMMM YYYY');
        prevStart = moment(view.currentStart).subtract(1, 'weeks').format('D. MMMM YYYY');
        nextEnd = moment(view.currentEnd).add(1, 'weeks').format('D. MMMM YYYY');
        prevEnd = moment(view.currentEnd).subtract(1, 'weeks').format('D. MMMM YYYY');
      }
      $('.fc-next-button').attr(
        'aria-label',
        Vue.filter('fromTextKey')('ARIA_LABEL_CALENDAR_NEXT') +
          nextStart +
          Vue.filter('fromTextKey')('ARIA_LABEL_CALENDAR_TO') +
          nextEnd
      );
      $('.fc-prev-button').attr(
        'aria-label',
        Vue.filter('fromTextKey')('ARIA_LABEL_CALENDAR_PREV') +
          prevStart +
          Vue.filter('fromTextKey')('ARIA_LABEL_CALENDAR_TO') +
          prevEnd
      );
    },
    eventDrop(eventDropInfo) {
      const self = this;
      const event = eventDropInfo.event;
      const extendedProps = event.extendedProps;

      const isUnmovableEvent =
        extendedProps.timeSlot != null ||
        [eventTypeEnum.VACATION_REGISTRATION, eventTypeEnum.PRESENCE_HOLIDAY].includes(extendedProps.type);
      const isDeadlineAfterStartDate =
        extendedProps.responseDeadline != null &&
        moment(extendedProps.responseDeadline, 'day').isAfter(moment(event.start));

      if (extendedProps.repeating) {
        this.calendarSelectedDate = dateFormat(eventDropInfo.oldEvent.start, 'YYYY-MM-DD');
      }

      // set popoverEvent to event, using it's information on dialog
      this.popoverEvent = Object.assign(event, extendedProps);

      if (isUnmovableEvent || isDeadlineAfterStartDate) {
        this.$refs.modalWarningEditEvent.show();
        eventDropInfo.revert();
        return;
      }

      this.showConfirmEditEventModal(
        async function confirm() {
          self.hideConfirmEditEventModal();
          await self.getEventById({ eventId: event.id, occurrenceDateTime: self.occurrenceDateTime });
          const eventToSave = cloneDeep(self.currentEvent);
          eventToSave.eventId = eventToSave.id;
          eventToSave.description = eventToSave.description.html;
          eventToSave.addToInstitutionCalendar = eventToSave.addedToInstitutionCalendar;
          eventToSave.isPrivate = eventToSave.private;
          eventToSave.inviteeIds = eventToSave.invitees.map(invitee => invitee.instProfile.id);
          eventToSave.invitedGroupIds = eventToSave.invitedGroups.map(invitee => invitee.id);
          eventToSave.coOrganizerIds = eventToSave.coOrganizers.map(organizer => organizer.instProfile.id);
          eventToSave.resourceIds = eventToSave.resources != null ? eventToSave.resources.map(res => res.id) : [];
          eventToSave.additionalLocationIds =
            eventToSave.additionalLocations != null ? eventToSave.additionalLocations.map(loc => loc.id) : [];
          eventToSave.additionalResourceIds = eventToSave.resourceIds.concat(eventToSave.additionalLocationIds);
          if (eventToSave.attachments.length > 0) {
            eventToSave.attachmentIds = [];
            eventToSave.attachments.forEach(attachment => {
              eventToSave.attachmentIds.push(attachment.id);
            });
          }
          if (
            eventToSave.primaryResource != null &&
            eventToSave.primaryResource.id != null &&
            eventToSave.primaryResource.id != ''
          ) {
            eventToSave.primaryResourceId = eventToSave.primaryResource.id;
          }
          if (event.allDay) {
            eventToSave.allDay = event.allDay;
            eventToSave.start = DateTimeUtil.formatDate(event.start, 'YYYY-MM-DD');
            eventToSave.end = eventToSave.start;
          } else {
            eventToSave.allDay = event.allDay;
            eventToSave.start = moment(event.start).format();
            if (event.end != null) {
              eventToSave.end = moment(event.end).format();
            } else {
              eventToSave.end = moment(event.start).add(30, 'minutes').format();
            }
          }
          eventToSave.invitees = self.currentEvent.invitees.map(invitee => invitee.instProfile.id);
          const eventResourceIds = eventToSave.additionalLocationIds;
          if (eventToSave.primaryResourceId) {
            eventResourceIds.push(eventToSave.primaryResourceId);
          }

          if (eventResourceIds.length > 0 || eventToSave.additionalResources.length > 0) {
            self.showUpdateEventWithResourcesModal(
              function confirm() {
                self.onDropOffEvent(eventDropInfo, eventToSave);
                self.$refs.removeResourcesWhenChangingDateTimeModal.hide();
              },
              function undoChange() {
                eventDropInfo.revert();
                self.$refs.removeResourcesWhenChangingDateTimeModal.hide();
              }
            );
          } else {
            self.onDropOffEvent(eventDropInfo, eventToSave);
          }
        },
        function undoChange() {
          eventDropInfo.revert();
          self.hideConfirmEditEventModal();
        }
      );
    },
    onDropOffEvent(eventDropInfo, eventToSave) {
      let eventPayload = Object.assign({}, eventToSave);
      if (eventPayload.inviteeGroups) {
        eventPayload.inviteeGroups = eventPayload.inviteeGroups.map(inviteeGroup => ({
          groupId: inviteeGroup.group.id,
          portalRoles: inviteeGroup.invitedPortalRoles,
        }));
      }
      if (eventPayload.repeating) {
        eventPayload = {
          ...eventPayload,
          ...eventPayload.repeating,
          occurrenceDateTime: this.occurrenceDateTime,
        };
      }

      eventPayload.invitedOtpInboxIds = eventPayload.invitedGroupHomeChildren.map(
        groupHomeChild => groupHomeChild.otpInboxId
      );
      const onEditTimeByDraggingEvent = () =>
        this.editTimeByDraggingEvent(eventPayload)
          .then(() => {
            // Reload Calendar after drag-drop event
            this.reloadCalendar();
          })
          .catch(error => {
            const subCode = error.response?.data.status.subCode;
            if (subCode === errorSubCodeTypes.firstRepeatingEventExceptionOutOfRange) {
              this.$refs.occurrenceDateBeforeFirstOccurrence.show();
            }
            if (subCode === errorSubCodeTypes.lastRepeatingEventExceptionOutOfRange) {
              this.$refs.occurrenceDateAfterLastOccurrence.show();
            }
            if (subCode === errorSubCodeTypes.conflictingRepeatingEventInSameSeries) {
              this.$refs.cannotMoveRepeatingEventToExistingRepetitionWarningModal.show();
            }
            eventDropInfo.revert();
          });
      if (this.profile.role == portalRoles.EMPLOYEE) {
        const params = {
          start: eventToSave.start,
          end: eventToSave.end,
          institutionProfileIds: this.currentEvent.invitees.map(invitee => invitee.instProfile.id),
          excludeEventId: eventToSave.id,
          allDay: eventToSave.allDay,
        };
        this.warningConflictEventByAttendees(params).then(response => {
          if (response.length === 0) {
            onEditTimeByDraggingEvent();
          } else {
            this.showConflictEventsModal(
              () => {
                onEditTimeByDraggingEvent();
                this.$refs.conflictingEventsModal.hide();
              },
              () => {
                eventDropInfo.revert();
                this.$refs.conflictingEventsModal.hide();
              }
            );
          }
        });
      } else {
        onEditTimeByDraggingEvent();
      }
    },
    changeTimeSlotResponse(relatedNotification) {
      this.relatedNotification = relatedNotification;
      this.$refs.calendarPopoverSingleEvent.hide();
      this.$refs.responseMeetingModal.show();
    },
    canDeleteEvent(event) {
      return (
        (event.isCreator || this.isChildHoliday(event)) &&
        !(
          this.profile.role == portalRoles.CHILD &&
          (event.type == eventTypeEnum.HOLIDAY || event.type == eventTypeEnum.PRESENCE_HOLIDAY)
        )
      );
    },
    isChildHoliday(event) {
      return (
        this.children.find(child => child.id == event.creatorInstProfileId) != null &&
        (event.type == this.eventTypes.HOLIDAY || event.type == this.eventTypes.PRESENCE_HOLIDAY)
      );
    },
    mobileCreateEvent() {
      if (this.mobilePageComponent == 'calendar') {
        this.$router.push({
          name: 'createEvent',
          query: { parent: this.parent },
        });
      } else {
        this.$router.push({
          name: 'createMeetingEvent',
          query: { parent: this.parent },
        });
      }
    },
    openVacationRequestFromToolbar() {
      this.$refs.calendarToolbar.openRequestVacationForm();
    },
    activeCalendarView() {
      this.mobilePageComponent = 'calendar';
      this.$nextTick(() => {
        this.initFullCalendar();
      });
    },
    showCalendarPickerForMobileView() {
      if (this.isMobile && this.parent !== parentTypes.NOTICE_BOARDS) {
        if (this.$refs.calendarDatepicker != null) {
          this.$refs.calendarDatepicker.handleFocus();
        }
      }
    },
    openDatePicker() {
      if ($('.calendar-datepicker').is(':visible')) {
        this.$refs.calendarDatepicker.blur();
      } else {
        this.$refs.calendarDatepicker.handleFocus();
      }
    },
    handleOrientationChange() {
      setTimeout(() => {
        this.isSwitchingViewPort = true;
        if (this.isMobile) {
          this.showWeekNumber = false;
          this.portraitMode();
        } else {
          this.showWeekNumber = true;
          this.landscapeMode();
        }
      }, 600);
    },
    landscapeMode() {
      if (this.calendar != null) {
        this.showWeekNumber = true;
        this.calendar.changeView(CalendarViewType.FIVE_DAYS_GRID.toString());
        // Specify target window as the prototype method only emits if the target is window
        this.calendar.windowResize({ target: window });
        this.$nextTick(() => this.calendar.scrollToTime('08:00'));
      }
      this.$refs.calendarDatepicker.hidePicker();
    },
    portraitMode() {
      if (this.calendar != null) {
        this.calendar.changeView(CalendarViewType.EVENTS_LIST.toString());
      }
      this.showCalendarPickerForMobileView();
    },
    changeDrawerShow(state) {
      if (!state) {
        if (this.$route.path.includes('opretbegivenhed') || this.$route.path.includes('redigerbegivenhed')) {
          this.$refs.eventForm.$refs.simpleEvent.closeEventForm();
        } else if (this.$route.path.includes('opretsamtale') || this.$route.path.includes('redigersamtale')) {
          this.$refs.eventForm.$refs.timeSlotEvent.closeEventForm();
        } else if (this.$route.name == 'viewVacationRequest') {
          this.$refs.eventForm.$refs.vacationRequestDetails.closeVacationRequestDetail();
        } else {
          this.$refs.eventForm.$refs.vacationRequestForm.closeVacationRequestForm();
        }
      }
    },
    goToDay() {
      if (this.calendar != null) {
        this.calendar.changeView('resourceTimeGridDay');
        this.calendar.gotoDate(dateFormat(this.clickedEventDate));
      }
      this.$refs.calendarPopoverMultipleEvent.hide();
    },
    changeCalendarDate() {
      if (this.calendar != null) {
        this.calendar.gotoDate(moment(this.datepickerDate).toDate());
      }
    },
    checkConflictResources(eventResourceIds, availableResources) {
      for (const eventResourceId of eventResourceIds) {
        if (!availableResources.find(resource => resource.id == eventResourceId)) {
          return true;
        }
      }
      return false;
    },
    activateFilters() {
      if (this.calendar != null) {
        this.calendar.batchRendering(() => {
          const events = this.calendar.getEvents();
          const preparedEvents = this.prepareAllEvents();

          for (const event of events) {
            event.remove(event);
          }

          for (const event of preparedEvents) {
            const isDayGridMonthView = this.calendar.view.type === CalendarViewType.DAY_GRID_MONTH.toString();
            const eventTypeIncludesSum = event.type.includes('sum-');
            let canAddEvent = true;

            if (this.filterEventTypes.length === 0) {
              canAddEvent = isDayGridMonthView === true || eventTypeIncludesSum === false;
            } else if (isDayGridMonthView === true && eventTypeIncludesSum === true) {
              canAddEvent =
                this.filterEventTypes.includes(event.type.substr(4, event.type.length)) ||
                (this.filterEventTypes.includes('directlyRelated') && event.directlyRelated === true);
            } else if (isDayGridMonthView === false && eventTypeIncludesSum === false) {
              canAddEvent =
                this.filterEventTypes.includes(event.type) ||
                (this.filterEventTypes.includes('directlyRelated') && event.directlyRelated === true);
            }

            if (canAddEvent) {
              this.calendar.addEvent(event);
            }
          }
        });
      }
    },
    loadNotificationsForOwnCalendar() {
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    async reloadCalendarWithNewActiveProfileId(calendarProfile, setToDefault) {
      let calendar = this.defaultProfile;
      let profile = this.profile;
      // change activate Resource, which has right to manipulate events
      // clear all filters and Resources id
      if (setToDefault) {
        if (this.delegatedContext.id != null) {
          this.delegatedContextId = null;
          await this.setDelegatedContext();
        }
      } else {
        calendar = calendarProfile;
        profile = calendarProfile;
      }
      this.setCalendarActiveInstProfileId(profile.id);
      this.loadNotificationsForOwnCalendar();
      this.existingCalendars = [calendar];
      this.showCalendarPickerForMobileView();
      this.reloadCalendar();
    },
    hasRightOfDisposal(event) {
      // Check if current profile has the right to edit event or not
      const isActiveCreator =
        event.creatorInstProfileId === this.delegatedContext.id ||
        this.institutions.some(inst => inst.institutionProfileId == event.creatorInstProfileId);
      const isDelegatedPrivateEvent =
        event.private && this.myDelegatedAccesses.some(item => item.profileId === event.creatorProfileId);

      return isActiveCreator && !isDelegatedPrivateEvent;
    },
    deletedEvent(event) {
      let deletedEvents = this.events.filter(evt => evt.id == event.id);
      let occurrenceDateTime;
      if (this.isDeletingOccurrence) {
        occurrenceDateTime = event.occurrenceDate || this.occurrenceDateTime;
        deletedEvents = deletedEvents.filter(event => isSame(event.startDateTime, occurrenceDateTime, 'days'));
      }

      this.isLoadingDeletedEvent = true;
      if (deletedEvents.length > 0) {
        const params = { eventForm: deletedEvents[0], occurrenceDateTime };
        this.deleteEvent(params).then(() => {
          this.removeEventFromCalendar(event.id, deletedEvents);
          this.isLoadingDeletedEvent = false;
          this.loadNotificationsForOwnCalendar();
          this.$refs.deleteModal.hide();
        });
      }
      this.closeDeletionWarning();
    },
    removeEventFromCalendar(eventId, removedEvents = null) {
      if (removedEvents == null) {
        removedEvents = this.events.filter(event => event.id == eventId);
      }
      for (const event of removedEvents) {
        for (const calEvent of this.calendar.getEvents()) {
          if (calEvent.extendedProps.uniqueId == event.uniqueId) {
            calEvent.remove();
          }
        }
      }
      this.reloadCalendar();
      this.$refs.calendarPopoverMultipleEvent.hide();
      this.$refs.calendarPopoverSingleEvent.hide();
    },
    reloadVacationRequest() {
      this.$refs.calendarToolbar.reloadCalendar();
    },
    reloadCalendar() {
      if (this.calendar != null) {
        this.clearEvents();
        this.calendar.refetchEvents();
        this.calendar.refetchResources();
      }
    },
    rerenderEvents() {
      if (this.calendar != null) {
        this.calendar.rerenderEvents();
      }
    },
    reload() {
      this.$refs.calendarPopoverSingleEvent.hide();
      this.$refs.calendarPopoverMultipleEvent.hide();
      this.reloadCalendar();
    },
    updatedEvent() {
      this.showCalendarPickerForMobileView();
      this.reloadCalendar();
      if (this.parent === 'group') {
        this.$router.push({
          path: '/gruppe/' + this.$route.params.groupId + '/kalender',
          query: { parent: this.parent },
        });
      } else if (this.parent === parentTypes.PROFILE) {
        this.$router.push({ path: '/kalender' });
      }
    },
    editEvent(eventId) {
      const institution = this.institutions.find(
        institution => institution.institutionCode === this.activeEvent.institutionCode
      );

      if (institution && institution.communicationBlock) {
        this.$refs.blockedCommunicationModal.show();
      } else {
        const occurrenceDateTime = this.$route.query.occurrence;
        this.getEventById({ eventId: eventId, occurrenceDateTime });
      }
    },
    toggleEditVacationRequest() {
      this.$router.push({
        name: 'editVacationRequest',
        params: { id: this.currentEvent.id },
        query: { parent: parentTypes.PROFILE },
      });
    },
    showConfirmEditEventModal(okEvent, cancelEvent) {
      this.confirmEditEventOK = okEvent;
      this.confirmEditEventCancel = cancelEvent;
      this.$refs.modalConfirmEditEvent.show();
    },
    hideConfirmEditEventModal() {
      this.$refs.modalConfirmEditEvent.hide();
    },
    showConflictEventsModal(okEvent, cancelEvent) {
      this.confirmEditEventOK = okEvent;
      this.confirmEditEventCancel = cancelEvent;
      this.$refs.conflictingEventsModal.show();
    },
    showUpdateEventWithResourcesModal(okEvent, cancelEvent) {
      this.confirmEditEventOK = okEvent;
      this.confirmEditEventCancel = cancelEvent;
      this.$refs.removeResourcesWhenChangingDateTimeModal.show();
    },
    isInDelegatedCalendar(resourceId) {
      if (this.myDelegatedAccesses !== undefined) {
        for (const idx in this.myDelegatedAccesses) {
          if (this.myDelegatedAccesses[idx].id == resourceId) {
            return true;
          }
        }
      }
      return false;
    },
    dateClick(dateClickInfo) {
      if (this.parent === parentTypes.NOTICE_BOARDS) {
        return;
      }
      this.$refs.calendarPopoverSingleEvent.hide();
      this.$refs.calendarPopoverMultipleEvent.hide();
      if (!this.isPreviewMode) {
        if (
          dateClickInfo.resource == null ||
          (dateClickInfo.resource != null &&
            (dateClickInfo.resource.id == this.profile.id || this.isInDelegatedCalendar(dateClickInfo.resource.id))) ||
          this.canCreateEvent ||
          this.canCreateEventInGroupPage
        ) {
          if (dateClickInfo.start) {
            this.modifyDateTimeSelectedByClickedTimeslot({
              startDateTime: moment(dateClickInfo.start).format(),
              endDateTime: moment(dateClickInfo.end).format(),
              resourceType: dateClickInfo.resource != null ? dateClickInfo.resource.type : null,
            });
          } else {
            this.modifyDateTimeSelectedByClickedTimeslot({
              startDateTime: moment(dateClickInfo.date).format(),
              endDateTime: moment(dateClickInfo.date).add(30, 'minutes').format(),
              resourceType: dateClickInfo.resource != null ? dateClickInfo.resource.type : null,
            });
          }
          if (this.parent === parentTypes.PROFILE) {
            this.$router.push({
              name: 'createEvent',
              query: { parent: this.parent },
            });
          } else {
            this.$router.push({
              name: 'group-calendar-create-event',
              query: { parent: this.parent },
            });
          }
        }
      }
    },
    respondToEvent(
      eventId,
      responseType,
      occurrenceDateTime,
      numberOfAdultParticipants,
      numberOfChildParticipants,
      hasNumberOfParticipantsChanged = false
    ) {
      this.responseEventType = responseType;
      this.respondEvent({
        eventId: eventId,
        responseType: responseType,
        occurrenceDateTime,
        numberOfAdultParticipants,
        numberOfChildParticipants,
        hasNumberOfParticipantsChanged,
      }).then(response => {
        if (response.response?.data.status.subCode === errorSubCodeTypes.exceedingMaximumParticipants) {
          this.$refs.calendarParticipationWarning.show();
        }
        this.reloadCalendar();
      });
    },
    goToViewEventUrl(eventId, isMeetingEvent = false, childId = undefined) {
      if (!isMeetingEvent) {
        if (this.$route.path.includes('/gruppe')) {
          this.$router.push({
            name: 'group-calendar-view-event',
            params: { groupId: this.$route.params.groupId, eventId: eventId },
            query: { child: childId },
          });
        } else if (this.$route.name !== 'editEvent') {
          this.$router.push({
            name: 'viewEvent',
            params: { eventId: eventId },
            query: { occurrence: this.occurrenceDateTime, child: childId },
          });
        }
      } else {
        if (this.$route.path.includes('/gruppe')) {
          this.$router.push({
            name: 'group-calendar-view-meeting-event',
            params: { groupId: this.$route.params.groupId, eventId: eventId },
            query: { child: childId },
          });
        } else {
          this.$router.push({
            name: 'viewMeetingEvent',
            params: { eventId: eventId },
            query: { ...this.$route.query, child: childId },
          });
        }
      }
    },
    eventItemClick(eventClickInfo) {
      if (this.parent === parentTypes.NOTICE_BOARDS) {
        return;
      }
      let calEvent = eventClickInfo;
      let extendedProps = eventClickInfo;
      if (eventClickInfo.event != null) {
        calEvent = eventClickInfo.event;
        extendedProps = calEvent.extendedProps;
      }
      let eventId = calEvent.id;
      let childId;
      if (extendedProps.type === eventTypeEnum.PARENTAL_MEETING) {
        childId = extendedProps.belongsTo[0].replace('profile-', '');
      }

      this.calendarSelectedDate = null;
      if (calEvent.extendedProps?.repeating || calEvent.repeating) {
        this.calendarSelectedDate = dateFormat(calEvent.start, 'YYYY-MM-DD');
      }
      this.$refs.calendarPopoverMultipleEvent.hide();
      // Remove the dummy id for if the user is clicking an old event that has been updated
      if (eventId.toString().includes('-old')) {
        eventId = eventId.replace('-old', '');
      }
      this.selectedTimeSlotId = extendedProps.selectedTimeSlotId;
      if (this.selectedTimeSlotId) {
        eventId = extendedProps.eventId;
      }
      if (extendedProps.type === eventTypeEnum.VACATION_REGISTRATION) {
        this.openVacationRequestDetail(eventId);
      } else if (extendedProps.type === eventTypeEnum.BIRTHDAY) {
        this.popoverEvent = calEvent;
        this.$refs.calendarPopoverSingleEvent.show();
        this.$refs.calendarPopoverMultipleEvent.hide();
      } else {
        if (
          (this.institutions.find(inst => inst.institutionProfileId == extendedProps.creatorInstProfileId) != null ||
            this.calendarActiveInstProfileId == extendedProps.creatorInstProfileId) &&
          [
            eventTypeEnum.PARENTAL_MEETING,
            eventTypeEnum.SCHOOL_HOME_MEETING,
            eventTypeEnum.PERFORMANCE_MEETING,
          ].includes(extendedProps.type)
        ) {
          this.loadSingleEventAndShowPopover(
            eventId,
            calEvent.classNames,
            calEvent.start,
            calEvent.end,
            extendedProps.eventClass,
            false,
            eventClickInfo.disableEventRouting,
            childId
          );
        } else {
          if (!extendedProps.type.includes('sum-')) {
            this.loadSingleEventAndShowPopover(
              eventId,
              calEvent.classNames,
              calEvent.start,
              calEvent.end,
              extendedProps.eventClass,
              false,
              eventClickInfo.disableEventRouting,
              childId
            );
            this.deleteNotificationsOnEvent(eventId);
          } else if (extendedProps.type.includes('sum-')) {
            this.clickedEventDate = calEvent.start;
            this.popoverEvents = this.events.filter(event => {
              if (moment(event.end).diff(moment(event.start), 'days') >= 1 && event.parentId != null && !event.allDay) {
                const days = moment(event.end).diff(moment(event.start), 'days') + 1;
                for (let i = 0; i < days; i++) {
                  const date = addTime(event.start, i, 'days');
                  const isSameType = extendedProps.type.includes(event.type);
                  if (isSame(date, this.clickedEventDate) && isSameType) {
                    return true;
                  }
                }
              }
              return event.parentId === eventId;
            });
            this.popoverEvents = this.filterEventsByChosenResources(this.popoverEvents).map(event => {
              event.isCreator = this.institutions.some(inst => inst.institutionProfileId == event.creatorInstProfileId);
              return event;
            });
            this.$refs.calendarPopoverSingleEvent.hide();
            this.$refs.calendarPopoverMultipleEvent.show();
          }
        }
      }
    },
    getCalendarView() {
      let defaultView = CalendarViewType.FIVE_DAYS_GRID;
      if (this.institutions.find(inst => inst.institutionRole == institutionRole.EARLY_STUDENT) != null) {
        defaultView = CalendarViewType.DAY_GRID;
      }
      if (this.isMobile || this.parent === parentTypes.NOTICE_BOARDS) {
        this.views.listEvents.duration.days = this.parent === parentTypes.NOTICE_BOARDS ? 500 : 30;
        defaultView = CalendarViewType.EVENTS_LIST;
      }
      return defaultView.toString();
    },
    initFullCalendar() {
      this.calendar = this.$refs.fullCalendar.getApi();
      if (this.isProfileLoaded) {
        this.originProfile = cloneDeep(this.profile);
        this.clearEvents();
      }
      if (this.drawerRoutes.indexOf(this.$route.name) != -1) {
        this.drawerShow = true;
      }
      if (this.isMobile && this.parent !== parentTypes.NOTICE_BOARDS) {
        if (this.$refs.calendarDatepicker != null) {
          this.openDatePicker();
        }
      }
      this.updateCalendarView();
      this.setCalendarInitialStyle();
    },
    setCalendarInitialStyle() {
      const fcWidgetHeaderAxis = this.$refs.fullCalendar.$el.querySelector('.fc-axis.fc-widget-header');
      const fcWidgetHeaderRow = this.$refs.fullCalendar.$el.querySelector('.fc-row.fc-widget-header');
      if (fcWidgetHeaderAxis && fcWidgetHeaderRow) {
        document.documentElement.style.setProperty('--fcTimeWidth', fcWidgetHeaderAxis.style.width);
        document.documentElement.style.setProperty('--fcHeaderMarginRight', fcWidgetHeaderRow.style.marginRight);
        document.documentElement.style.setProperty('--fcHeaderBorderWidth', fcWidgetHeaderRow.style.borderRightWidth);
      }
    },
    setCalendarStylesForPopover(moreEl) {
      const fcDayHeader = this.$refs.fullCalendar.$el.querySelector('.fc-more-cell');
      const fcViewContainer = this.$refs.fullCalendar.$el.querySelector('.fc-view-container');
      const fcBody = this.$refs.fullCalendar.$el.querySelector('.fc-body');

      if (fcDayHeader && fcViewContainer && fcBody) {
        const popoverOffsetTop = fcBody.getBoundingClientRect().top - fcViewContainer.getBoundingClientRect().top;
        document.documentElement.style.setProperty('--fcDayColumnWidth', fcDayHeader.offsetWidth + 'px');
        document.documentElement.style.setProperty('--fcPopoverOffsetTop', popoverOffsetTop + 'px');
      }

      this.$nextTick(() => {
        const fcPopoverCloseIcon = this.$refs.fullCalendar.$el.querySelector('.fc-close.fc-icon.fc-icon-x');
        if (fcPopoverCloseIcon) {
          fcPopoverCloseIcon.tabIndex = 0;
          fcPopoverCloseIcon.ariaLabel = Vue.filter('fromTextKey')('ARIA_LABEL_CLOSE');
          fcPopoverCloseIcon.focus();
          fcPopoverCloseIcon.addEventListener('keyup', event => {
            event.preventDefault();
            if (event.key === 'Enter') {
              fcPopoverCloseIcon.click();
              moreEl.focus();
            }
          });
        }
      });
    },
    stripForHtmlTags(value) {
      return value.replace(/<\/?[^>]+(>|$)/g, '');
    },
    loadSingleEventAndShowPopover(
      eventId,
      classNames = [],
      startDate = null,
      endDate = null,
      eventClass = null,
      fromSearch = false,
      disableEventRouting = false,
      childId = undefined
    ) {
      if (
        startDate != null &&
        fromSearch &&
        !this.isMobile &&
        (moment(startDate).isBefore(this.calendar.view.currentStart) ||
          moment(startDate).isAfter(this.calendar.view.currentEnd))
      ) {
        this.calendar.changeView('resourceTimeGridWeek', startDate);
      } else {
        let getEventMethod = 'getEventById';
        if (this.parent === parentTypes.NOTICE_BOARDS) {
          getEventMethod = 'getNoticeBoardEvent';
        }
        this[getEventMethod]({ eventId: eventId, occurrenceDateTime: this.occurrenceDateTime }).then(() => {
          if (this.currentEvent.timeSlot != null && (eventClass == null || eventClass == 'timeslot')) {
            let concerningProfileId = this.profile.id;
            let timeSlotAnswerSelector = answer => answer.instProfile?.id == concerningProfileId;
            if (this.$route.query.child) {
              this.relatedNotification.relatedChildInstitutionProfileId = this.$route.query.child;
              concerningProfileId = this.$route.query.child;
              timeSlotAnswerSelector = answer => answer.concerningProfile?.id == concerningProfileId;
            }
            let answer = null;
            let timeSlotIndex = null;
            timeSlotsLabel: for (const timeSlot of this.currentEvent.timeSlot.timeSlots) {
              if (timeSlot.answers == null) {
                continue;
              }

              for (const timeSlotAnswer of timeSlot.answers) {
                if (timeSlotAnswerSelector(timeSlotAnswer)) {
                  answer = timeSlotAnswer;
                  timeSlotIndex = timeSlot.timeSlotIndexes[answer.selectedTimeSlotIndex];
                  break timeSlotsLabel;
                }
              }
            }
            if (
              answer == null ||
              this.isCurrentEventCreator ||
              this.isCurrentEventCoOrganizer ||
              this.isAddedOnlyInSchoolCalendar ||
              this.selectedTimeSlotId == null
            ) {
              this.$refs.responseMeetingModal.show();
              if (this.$route.params.mediaId == null && !disableEventRouting) {
                this.goToViewEventUrl(eventId, true);
              }
            } else {
              this.popoverEvent = this.currentEvent;
              this.popoverEvent.start = moment(timeSlotIndex.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
              this.popoverEvent.end = moment(timeSlotIndex.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
              if (this.$route.name !== 'editEvent') {
                this.$refs.calendarPopoverSingleEvent.show();
              }
              if (this.$route.params.mediaId == null && !disableEventRouting) {
                let childId;
                if (answer.concerningProfile.role === portalRoles.CHILD) {
                  childId = answer.concerningProfile.id;
                }
                this.goToViewEventUrl(eventId, false, childId);
              }
            }
          } else {
            if (
              this.currentEvent.private &&
              !this.canHandleOthersEvents &&
              (!this.isCurrentEventParticipant || this.isOpenDelegatedContext)
            ) {
              return;
            }
            this.popoverEvent = this.currentEvent;
            if (this.popoverEvent.repeating != null) {
              if (startDate != null && endDate != null && !this.popoverEvent.allDay) {
                this.popoverEvent.start = startDate;
                this.popoverEvent.end = endDate;
              } else {
                this.popoverEvent.start = moment(this.popoverEvent.repeating.originalStartDateTime).format(
                  'YYYY-MM-DDTHH:mm:ss.SSSSZ'
                );
                this.popoverEvent.end = moment(this.popoverEvent.repeating.originalEndDateTime).format(
                  'YYYY-MM-DDTHH:mm:ss.SSSSZ'
                );
              }
            } else {
              if (startDate != null && endDate != null && !this.popoverEvent.allDay) {
                this.popoverEvent.start = startDate;
                this.popoverEvent.end = endDate;
              } else {
                this.popoverEvent.start = moment(this.popoverEvent.startDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
                this.popoverEvent.end = moment(this.popoverEvent.endDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
              }
            }
            this.popoverEvent.classNames = classNames;
            if (this.$route.name !== 'editEvent') {
              this.$refs.calendarPopoverSingleEvent.show();
            }
            if (this.$route.params.mediaId == null && !disableEventRouting) {
              this.goToViewEventUrl(eventId, false, childId);
            }
          }
        });
        this.setEventSelectedFromSearch(null);
      }
    },
    loadEventFromRoute() {
      if (this.$route.params.eventId != null && this.$route.path.includes('redigersamtale')) {
        this.editEvent(this.$route.params.eventId);
      } else if (
        this.$route.path.includes('begivenhed') &&
        this.$route.params.eventId != null &&
        this.currentEvent != null &&
        this.currentEvent.id != this.$route.params.eventId
      ) {
        this.loadSingleEventAndShowPopover(this.$route.params.eventId);
      } else if (
        this.$route.path.includes('samtale') &&
        this.$route.params.eventId != null &&
        this.currentEvent != null &&
        this.currentEvent.id != this.$route.params.eventId
      ) {
        this.loadSingleEventAndShowPopover(this.$route.params.eventId);
      }
    },
    updateCalendarView() {
      if (!this.calendar) {
        return;
      }

      const calendars = [...this.existingCalendars, ...this.institutionCalendars];
      if (calendars.length > this.calendarResourcesLimit && !this.isMobile) {
        this.calendar.changeView('resourceTimeGridDay');
      }
    },
    filterEventsByChosenResources(events) {
      const chosenResourcesId = this.resources.map(resource => resource.id);
      if ([portalRoles.EMPLOYEE, portalRoles.CHILD].includes(this.profile.role)) {
        return events.filter(event => event.belongsTo.some(resourceId => chosenResourcesId.includes(resourceId)));
      }
      return events.filter(event => event.resourceIds.some(resourceId => chosenResourcesId.includes(resourceId)));
    },
    isEventDeclinedByUser(event, resourceId) {
      return calendarUtil.isEventDeclinedByUser(
        event,
        this.profile.id,
        this.delegatedContext?.id,
        this.resources,
        resourceId
      );
    },

    getEventRenderer(eventInfo) {
      const event = eventInfo.event;
      const renderParams = [
        eventInfo,
        this.resources,
        this.notifications,
        this.profile,
        this.delegatedContext,
        this.calendar.getResources().length,
      ];

      switch (this.calendar.view.type) {
        case CalendarViewType.DAY_GRID_MONTH: {
          const isSumEvent = event.extendedProps.type.includes('sum-');
          return isSumEvent ? new MonthEventRenderer(...renderParams) : null;
        }

        case CalendarViewType.FIVE_DAYS_GRID: {
          return new FiveDaysEventRenderer(...renderParams);
        }

        case CalendarViewType.WEEK_GRID: {
          return new WeekEventRenderer(...renderParams);
        }

        case CalendarViewType.EVENTS_LIST: {
          if (this.isMobile && !this.isOnNoticeBoard) {
            const activeResourcesCount =
              this.profile.role === portalRoles.EMPLOYEE ? this.activeInstitutions.length : this.activeChildren.length;

            return new MobileEventRenderer(
              ...renderParams,
              this.parent,
              this.institutions,
              this.resources.length + activeResourcesCount > 1
            );
          }

          return new NoticeBoardEventRenderer(...renderParams, eventInfo.isStart);
        }

        default:
          return new DefaultEventRenderer(...renderParams);
      }
    },
  },
  watch: {
    $route(to) {
      this.loadEventFromRoute();
      if (this.drawerRoutes.indexOf(to.name) != -1 && to.query.parent != undefined) {
        if (this.meetingEventRouters.indexOf(to.name) != -1) {
          if (
            (this.profile.role == this.portalRoles.EMPLOYEE &&
              this.hasPermission(this.permissionEnum.HANDLE_PERFORMANCE_MEETING)) ||
            this.hasPermission(this.permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL) ||
            this.hasPermission(this.permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE)
          ) {
            this.drawerShow = true;
          } else {
            this.$router.push({
              name: this.parent == 'group' ? 'group-calendar' : 'calendar',
            });
          }
        } else {
          if (to.query.parent == parentTypes.PROFILE) {
            if (this.hasPermission(this.permissionEnum.HANDLE_EVENTS)) {
              this.drawerShow = true;
            } else {
              this.$router.push({ name: 'calendar' });
            }
          } else if (to.query.parent == 'group') {
            if (
              this.hasPermissionGroup(this.permissionEnum.INVITE_TO_EVENT, this.group.id) ||
              this.hasPermissionOnInstitution(
                this.permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
                this.group.institutionCode
              )
            ) {
              this.drawerShow = true;
            } else {
              this.$router.push({ name: 'group-calendar' });
            }
          }
        }
      } else if (to.name !== 'viewVacationRequest') {
        this.drawerShow = false;
      }
    },
    existingCalendars() {
      this.updateCalendarView();
    },
    institutionCalendars() {
      this.updateCalendarView();
    },
    isProfileLoaded() {
      this.initFullCalendar();
    },
    activeChildren() {
      if (!this.isProfileSwitched) {
        this.calendar.refetchResources();
        this.calendar.refetchEvents();
      }
    },
    activeInstitutions() {
      if (isEqual(this.profile, this.originProfile) && !this.isProfileSwitched) {
        this.calendar.refetchResources();
        this.calendar.refetchEvents();
      }
    },
    activeGroupHome() {
      this.clearEvents();
    },
    isLoading() {
      if (this.calendar != null) {
        const dateViewElement = this.$el.querySelector('#' + this.calendarId + ' .fc-left');
        if (dateViewElement) {
          if (this.calendar.state.viewType === CalendarViewType.DAY_GRID_MONTH.toString()) {
            dateViewElement.classList.add('fc-left--on-month-calender');
          } else {
            dateViewElement.classList.remove('fc-left--on-month-calender');
          }
        }
      }
    },
    isMobile() {
      const view = this.getCalendarView();
      this.calendar.changeView(view);
    },
  },
  async mounted() {
    this.defaultView = this.getCalendarView();
    const promises = [this.getSameAuthorityInstitutions(this.institutions)];
    if (this.profile.role === portalRoles.EMPLOYEE) {
      promises.push(this.loadMyDelegatedAccesses());
    }
    await Promise.all(promises);
    await this.setCalendarFiltersFromStorage();
    this.bootstrappingData = false;
    this.$nextTick(() => {
      this.initFullCalendar();
      if (this.parent === parentTypes.NOTICE_BOARDS) {
        return;
      }
      this.$eventHub.$on('planningAssistantEventClicked', this.eventItemClick);
      this.loadEventFromRoute();
      if (Cookie.Read('calendarDate')) {
        this.defaultDate = moment(Cookie.Read('calendarDate')).toDate();
        Cookie.Erase('calendarDate');
      } else {
        this.defaultDate = moment().toDate();
      }
      this.orientationMediaService.addEventListener(this.handleOrientationChange);
    });
  },
  created() {
    this.bootstrappingData = true;
    this.originalProfilePortalRole = this.profile.role;
  },
  components: {
    Overlay,
    DocumentDrawer,
    RegisterVacationRequestDrawer,
    CalendarPopoverSingleEvent,
    CalendarToolbar,
    CalendarEventForm,
    ResponseMeetingForm,
    Alerts,
    WidgetPlaceholder,
    MultiButtonMobile,
    FullCalendar,
  },
  beforeDestroy() {
    this.orientationMediaService.removeEventListener(this.handleOrientationChange);
    document.documentElement.style.removeProperty('--fcTimeWidth');
    document.documentElement.style.removeProperty('--fcHeaderMarginRight');
    document.documentElement.style.removeProperty('--fcHeaderBorderWidth');
    document.documentElement.style.removeProperty('--fcDayColumnWidth');
    document.documentElement.style.removeProperty('--fcPopoverOffsetTop');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/daygrid/main.css';

.calendar-overlay {
  min-height: 500px;
  .calendar-spinner {
    margin-top: 150px;
  }
}
.calendar-event-form {
  padding-bottom: 50px;
}

.drawer-edit-button {
  width: auto;
  height: 30px;
  @include breakpoint-lg() {
    min-width: 110px;
  }
  &.read-only-mode {
    color: $color-white;
    height: 22px;
    margin-right: 65px;
    margin-top: -5px;
    @include breakpoint-sm-down() {
      margin-right: 0px;
      color: $color-darkblue;
    }
  }
}

.no-notification {
  position: relative;
  top: 15px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.loading {
  margin-top: 50px;
  margin-left: -15px;
}
.alert-badge {
  border-radius: 10px;
  background-color: $color-alert;
  color: $color-white;
  height: 20px;
  min-width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 75%;
  position: relative;
  top: -2px;
  padding: 0 5px;
  margin-left: 4px;
  display: inline-block;
}

.aula-calendar.disable-mouse-clicks {
  pointer-events: none;
}

.aula-calendar {
  /deep/ .week-view-event-group {
    padding: 2px 5px;
  }

  & /deep/ #calendar {
    @include breakpoint-lg() {
      .fc-axis {
        width: var(--fcTimeWidth);
      }

      .fc-time-grid-container {
        height: 580px;
      }

      .fc-row.fc-widget-header {
        margin-right: var(--fcHeaderMarginRight);
        border-right-width: var(--fcHeaderBorderWidth);
      }
    }

    .fc-view-container .fc-event-container .fc-event.lesson-event {
      background-color: $color-white !important;
      border-top: solid 1px #e1e9ee;
      border-bottom: solid 1px #e1e9ee;
      margin: 0 -1px;

      .week-view-title-event .title {
        font-weight: bold;
      }
    }
    /deep/ .fc-time-grid-container {
      @include breakpoint-lg() {
        height: 580px;
      }
    }
  }
}
.widgets-below {
  display: none;
}

@media only screen and (max-width: 991px) {
  .widgets-below {
    display: block;
  }
}
</style>
