<template>
  <div class='widget-W0004V0001'>
    <div :class="placement" class="studentplan-widget">
      <div v-if="loading" class="widget-loader">
        <i class="fa fa-spinner"></i>
        Loading...
      </div>
      <div v-if="hasErrors">
        <ul class="errors">
          <li v-for="error in errors" v-bind:key="error" class="errors__error">{{ error }}</li>
        </ul>
      </div>
      <h2 class="weekplan-title"> UGEPLANER: </h2>
      <div class="widget-nav">
<span class="widget-nav__week-link"
      title="Se ugeplan for forrige uge"
      tabindex="0"
      v-on:click="getPreviousWeekPlan()"
      v-on:keypress="(e) => triggerGetPreviousWeekPlan(e)">&#x25C0</span>
        <span class="widget-nav__week-info">Uge {{ getFormatedActiveWeekNumber('WW') }}</span>
        <span class="widget-nav__week-link"
              title="Se ugeplan for næste uge"
              tabindex="0"
              v-on:click="getNextWeekPlan()"
              v-on:keypress="(e) => triggerGetNextWeekPlan(e)">&#x25B6</span>
      </div>
      <div v-for="(student, studentIndex) in data" v-if="!loading && !hasErrors" class="weekplan-container">
        <h3 class="studentname"> {{ student.name }} </h3>
        <div v-if="studentIndex < 1" class="weekdays">
          <div :id="studentIndex +''+ planIndex"
               class="weekdays__day"
               v-on:click="activeItemId = planIndex"
               v-for="(plan, planIndex) in student.weekPlan"
               v-bind:key="plan.date"
               :class="((activeItemId === planIndex)) ? 'active' :''">
            {{ getDayOfWeek(plan.date) }}
            <br>
            {{ getDateOfMonth(plan.date) }}
          </div>
          <span v-if="studentIndex < 1" v-for="(plan, planIndex) in student.weekPlan" class="circle"
                :class="(checkDayForTasks(planIndex))? 'visible' : ''">&#9679;</span>
        </div>
        <div class="weekplan">
          <div class="weekplan__column" v-for="(plan, planIndex) in student.weekPlan" v-bind:key="plan.date"
               :class="(activeItemId === planIndex) ? 'active' : ''">
            <div class="weekplan__column__head">
              <span class="plandate">{{ plan.date }} </span>
              <span class="childname">{{ student.name }} </span>
            </div>
            <div class="weekplan__column__tasks">
              <span v-if="!dayHasNoTasks(studentIndex, planIndex)"
                    class="no-tasks">Der er intet indhold for denne dag</span>
              <div
                v-for="task in plan.tasks"
                v-bind:key="task.id"
                :class="`weekplan-task weekplan-task--${task.type}`"
              >
                <div class="weekplan-task__top">
                  <div class="pills-wrap">
                    <div v-for="pill in (task.pill ? task.pill.split(',') : [])" class="weekplan-task__pill">
                      {{ pill }}
                    </div>
                  </div>
                  <div class="event-icon" :style="getBackgroundStyle(task.type)"></div>
                </div>
                <hr>
                <div class="weekplan-task__title">
                  {{ task.title }}
                </div>
                <div class="weekplan-task__toggle-container" v-on:click="handleClick($event)" ref='containers'>
                  <div class="weekplan-task__toggle-container__content js-content">
                    {{ task.content }}
                  </div>
                  <div class="weekplan-task__toggle-container__button js-button" data-more="Læs mere"
                       data-less="Læs mindre">
                    Læs mere
                  </div>
                </div>
                <hr class="link-hr" v-if="task.linkText">
                <div class="weekplan-task--bottom" v-if="task.linkText">
                  <div class="link-icon" :style="getLinkIcon()"></div>
                  <div
                    :title="`Se ${task.title} i Meebook`"
                    v-on:click="submitLink(widgetIdentifier + 'task' + task.id + student.unilogin)"
                    v-on:keypress="(e) => activateItem(e, 'task' + task.id + student.unilogin)"
                    tabindex="0"
                    class="weekplan-task__link">
                    <span>{{ task.linkText }}</span>
                    <form
                      v-bind:action="getFormAction()"
                      method="post"
                      target="_blank"
                      v-bind:ref="widgetIdentifier + 'task' + task.id + student.unilogin"
                    >
                      <input type="hidden" name="id" v-bind:value="task.id"/>
                      <input type="hidden" name="childFilter[]" v-bind:value="[student.unilogin]"/>
                      <input type="hidden" name="sessionUUID" v-bind:value="sessionUUID"/>
                      <input type="hidden" :id="`aulaJWT${widgetIdentifier + 'task' + task.id + student.unilogin}`"
                             name="aulaJWT" value=""/>
                      <input type="hidden" name="userProfile" v-bind:value="userProfile"/>
                      <input type="hidden" name="currentWeekNumber" v-bind:value="formatAulaYearWeek()"/>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    axios: Function,
    getAulaToken: Function,
    devServer: String,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    assuranceLevel: Number,
    userProfile: String,
    childFilter: [String, Array],
    institutionFilter: [String, Array],
    group: String,
    meebookImgBaseUrl: {
      type: String,
      default: 'https://app.meebook.com/images/aula/',
    },
    widgetIdentifier: {
      type: String,
      default: 'relatedWeekPlan-',
    },
    meebookApiBaseUrl: String,
    meebookApiTestData: String
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length;
    },
  },
  methods: {
    async ensureAulaToken() {
      this.aulaToken = await this.getAulaToken();
      return this.aulaToken;
    },
    addReadmore() {

      const containers = this.$refs.containers;
      if (containers) {
        containers.forEach((container) => {
          const content = container.querySelector('.js-content');
          const scrollHeight = content.scrollHeight;

// every row have aprox 17px line height
          if (scrollHeight > 51) {
            const button = container.querySelector('.js-button');
            button.classList.add('show');
          }
        });
      }
    },
    handleClick(event) {
      const element = event.currentTarget;
      const content = element.querySelector('.js-content');
      const button = element.querySelector('.js-button');
      const contentHeight = content.getBoundingClientRect().height;

// every row have aprox 17px line height
      if (content.classList.contains('open') && contentHeight > 51) {
        content.style.height = 51 + 'px';
        content.classList.remove('open');
        button.innerHTML = button.getAttribute('data-more');
      } else {
        content.style.height = contentHeight + 'px';
        content.style.height = content.scrollHeight + 'px';
        content.classList.add('open');
        button.innerHTML = button.getAttribute('data-less');
      }
    },
    getBackgroundStyle(type) {
      const typeMapping = {
        task: 'assignment',
        note: 'comment'
      };

      const mappedType = typeMapping[type] || type;
      return `background-image: url(${this.meebookImgBaseUrl}task-${mappedType}-2x.png);`;
    },
    getLinkIcon() {
      return `background-image: url(${this.meebookImgBaseUrl}link-2x.png)`;
    },
    getDate(date) {
      return moment(date);
    },
// Used to get the first letter in the name of the weekday.
    getDayOfWeek(date) {
      return date.substr(0, 2);
    },
// Extracting and returning number from day string.
    getDateOfMonth(date) {
      return date.match(/\d+/)[0];
    },
    formatAulaYearWeek() {
// format the aula year week string to folow moment year week format
      moment.locale('da');
      return moment(this.getCurrentWeekNumberPatchFormated()).format('GGGG-[W]WW');
    },
// get the active week number formated as just the week number
    getFormatedActiveWeekNumber(format) {
      return moment(this.getActiveWeekNumber()).format(format);
    },
// get the active week number formated as year-week
    getActiveWeekNumber() {
      return this.activeWeekNumber === null ? this.getCurrentWeekNumberPatchFormated() : this.activeWeekNumber;
    },
    getCurrentWeekNumberPatchFormated() {
// MEEB-2184
// The moment.js library can not format the currentWeekNumber string given by the AULA app when we
// are in the start of a year (weeks 1 - 9). The format is send as YYYY-[W]W... but should probably
// be sent as YYYY-[W]WW.. dvs. with leading zero for the week
// moment(2020-W7).format(moment.HTML5_FMT.WEEK) => FAILS
// moment(2020-W7).format('GGGG-[W]WW') => FAILS
//
// moment(2020-W07).format(moment.HTML5_FMT.WEEK) => SUCCESS
// moment(2020-W07).format('GGGG-[W]WW') => SUCCESS
//
// Sice Aula will not likely change this, we need to patch the format ourselvs per widget

// if the formated date string is longer than 7 chars, its the "correct" format
      if (this.currentWeekNumber.length > 7) {
        return this.currentWeekNumber;
      }

// if the length is below 7 add a leading "0" before the week number
      return this.currentWeekNumber.replace('W', 'W0');
    },
    async getClient() {
      // always ensure valid aula token before calling any client calls
      await this.ensureAulaToken();

      return this.axios.create({
        baseURL: this.getApiBaseUrl(),
        timeout: 30000,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.aulaToken,
          'sessionUUID': this.sessionUUID,
          'X-Version': '1.0',
        }
      });
    },
    getApiBaseUrl: function () {
      // See .env / .env.production
      // let url = import.meta.env.VITE_MEEBOOK_API_URL;
      //let url = 'https://devapp.meebook.com/aulaapi/';
      let url = 'https://app.meebook.com/aulaapi/';
      // if dev server is set, use that
      if (this.meebookApiBaseUrl !== undefined) {
        // could be localhost (or other local vhost) or development server
        url = this.meebookApiBaseUrl;
      }
      return url;
    },
    async activateItem(e, index) {
      if (e.code === 'Enter' || e.code === 'Space') {
        await this.submitLink(index);
      }
    },
    getFormAction() {
      return this.getApiBaseUrl() + 'relatedweekplanassignmentlinkauth';
    },
    async submitLink(index) {
      // before redirecting ensure valid aula token
      await this.ensureAulaToken();

      let aulaJwtInputElement = document.getElementById('aulaJWT' + index);
      // insert jwt token value into form
      aulaJwtInputElement.value = this.aulaToken;
// send form
      this.$refs[index][0].submit();
// reset aula jwt token input value to null
      aulaJwtInputElement.value = null;
    },
    dayHasNoTasks: function (studentIndex, planIndex) {
      return this.data[studentIndex].weekPlan[planIndex].tasks.length >= 1;
    },
//Return true if any child has tasks on a weekday.
    checkDayForTasks: function (planIndex) {
      let dayHasTask = false;
      this.data.forEach((student) => {
        if (student.weekPlan[planIndex].tasks.length >= 1) {
          dayHasTask = true;
        }
      });
      return dayHasTask;
    },
//fetch the week plan for the next week (activeWeekNumber + 1)
    async getNextWeekPlan() {
      let weekNumber = this.getActiveWeekNumber();
      moment.locale('da');
      this.activeWeekNumber = moment(weekNumber).add(1, 'w');
      await this.getWeekPlan(moment(this.activeWeekNumber).format('GGGG-[W]WW'));
    },
    async getWeekday() {
      let weekNumber = this.getActiveWeekNumber();
      moment.locale('da');
      this.activeWeekNumber = moment(weekNumber).add(1, 'w');
      await this.getWeekPlan(moment(this.activeWeekNumber).format('GGGG-[W]WW'));
    },
//trigger the get next week plan method by keboard pess of enter or space
    async triggerGetNextWeekPlan(e) {
      if (e.code === 'Enter' || e.code === 'Space') {
        await this.getNextWeekPlan();
      }
    },
//fetch the week plan for the next week (activeWeekNumber - 1)
    async getPreviousWeekPlan() {
      let weekNumber = this.getActiveWeekNumber();
      moment.locale('da');
      this.activeWeekNumber = moment(weekNumber).subtract(1, 'w');
      await this.getWeekPlan(moment(this.activeWeekNumber).format('GGGG-[W]WW'));
    },
//trigger the get previous week plan method by keboard pess of enter or space
    async triggerGetPreviousWeekPlan(e) {
      if (e.code === 'Enter' || e.code === 'Space') {
        await this.getPreviousWeekPlan();
      }
    },
// add wrapper method for fetching week plan data for the given year-week
    async getWeekPlan(yearWeek) {
      this.errors = []; // reset errors to ensure they get cleared

      if (this.childFilter.length === 0) {
        this.errors.push('Vælg en eller flere elever i Aula top-menu for at se data');
        return;
      }

// call aula api for dynamic data
      let http = await this.getClient();
      http.get(
        'relatedweekplan/all',
        {
          params: {
            currentWeekNumber: yearWeek,
            userProfile: this.userProfile,
            childFilter: this.childFilter,
            institutionFilter: this.institutionFilter,
          }
        }
      ).then((response) => {
        this.loading = false;
        this.data = response.data || [];
      }).catch((error) => {
        this.loading = false;
        if (error.response && error.response.data.exceptionMessage) {
          this.errors.push(error.response.data.exceptionMessage);
        } else {
          this.errors.push('Ugens plan kunne ikke hentes.');
        }
      });
    },
    async loadContent() {
      await this.getWeekPlan(this.formatAulaYearWeek());
    },
  },
  data: () => ({
    loading: true,
    activeWeekNumber: null,
    dayIsActive: false,
    activeItemId: 0,
    data: {},
    errors: [],
    aulaToken: null,
  }),
  mounted() {
// Set today as active day.
    (moment().isoWeekday() <= 5) ? this.activeItemId = moment().isoWeekday() - 1 : this.activeItemId = 0;

    this.aulaToken = this.getAulaToken();
  },
  updated() {
    this.addReadmore();
  },
  watch: {
    aulaToken: async function () {
      if (this.aulaToken !== undefined && this.aulaToken !== null) {
        await this.loadContent();
      }
    },
    childFilter: async function () {
      if (this.aulaToken !== undefined && this.aulaToken !== null) {
        await this.loadContent();
      }
    }
  }
}
</script>

<style lang="scss">
.widget-W0004V0001 {
  .widget-loader {
    font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 20px 0
  }

  .widget-loader > .fa {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;
    margin-right: 10px
  }

  @keyframes spin {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .errors {
    margin-top: 20px
  }

  .errors__error {
    display: block;
    padding: 15px 20px;
    background-color: #fff;
    color: #b50050
  }

  .no-tasks {
    display: block;
    text-align: center;
    padding: 10px
  }

  .widget-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #19405a;
    text-align: left;
    padding: 21px 10px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px
  }

  .widget-title h2 {
    margin: 10px 20px 10px 0;
    font-size: 32px;
    font-weight: 900;
    line-height: 34px;
    text-transform: uppercase
  }

  .widget-title h3 {
    margin: 0;
    vertical-align: bottom;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
    margin-top: 15px;
    margin-bottom: 10px
  }

  .studentname {
    margin-bottom: 10px;
    font-size: 22px
  }

  .widget-nav {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    color: #19405a;
    text-align: left
  }

  .widget-nav span {
    margin: 7px 4px 7px 4px;
    vertical-align: bottom;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px
  }

  .widget-nav__week-info {
    margin: 7px 10px 7px 10px;
    text-transform: uppercase
  }

  .widget-nav__week-link {
    font-size: 30px;
    font-weight: 700;
    cursor: pointer
  }

  .weekplan {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -8px
  }

  .weekplan__column {
    flex: 1 0 220px;
    width: 20%;
    max-width: 20%;
    border-left: 8px solid rgba(0, 0, 0, 0);
    margin-bottom: 20px
  }

  .weekplan__column span {
    font-size: 15px;
    font-weight: 500
  }

  .weekplan__column__head {
    background-color: #19405a;
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 10px
  }

  .weekplan__column__head:first-child {
    border-left: 0
  }

  .weekplan-task {
    background-color: #b4cdcf;
    padding: 8px 15px 8px 15px;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 35px 35px
  }

  .weekplan-task__pill {
    display: inline-block;
    margin-right: 3px;
    text-align: center;
    background-color: #589ea5;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    padding: 2px 12px;
    overflow: hidden
  }

  .weekplan-task--task {
    background-color: #f4e1e2;
    background-size: 18px;
    background-position: 96% 8%
  }

  .weekplan-task--task .weekplan-task__pill {
    background-color: #e46256
  }

  .weekplan-task--task .js-button {
    color: #e46256
  }

  .weekplan-task--task .js-button:hover {
    color: #efbbb8 !important
  }

  .weekplan-task--task hr {
    border-top-color: #efbbb8 !important
  }

  .weekplan-task--assignment {
    background-color: #f4e1e2;
    background-size: 18px;
    background-position: 96% 8%
  }

  .weekplan-task--assignment .weekplan-task__pill {
    background-color: #e46256
  }

  .weekplan-task--assignment .js-button {
    color: #e46256 !important
  }

  .weekplan-task--assignment hr {
    border-top-color: #efbbb8 !important
  }

  .weekplan-task--bottom {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 4px
  }

  .weekplan-task--bottom .link-icon {
    height: 18px;
    width: 18px;
    background-size: contain
  }

  .weekplan-task .link-hr {
    margin-top: 5px
  }

  .weekplan-task__toggle-container {
    position: relative;
    padding-top: 5px;
    cursor: pointer
  }

  .weekplan-task__toggle-container:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
  }

  .weekplan-task__toggle-container__button {
    text-decoration: underline;
    display: none
  }

  .weekplan-task__toggle-container__button.show {
    display: block
  }

  .weekplan-task--assignment .weekplan-task__toggle-container:hover .weekplan-task__toggle-container__button {
    color: #e46256
  }

  .weekplan-task__toggle-container__content {
    display: block;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    width: 100%;
    line-height: 17px;
    padding: 0;
    transition: height .3s ease;
    cursor: pointer;
    word-break: break-word;
    white-space: pre-line
  }

  .weekplan-task__toggle-container__content.open {
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    text-overflow: unset
  }

  .weekplan-task__title {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 0px
  }

  .weekplan-task__link {
    color: #2c3e50;
    cursor: pointer;
    text-decoration: underline;
    background-size: 18px;
    background-position: 1px 2px;
    background-repeat: no-repeat
  }

  .weekplan-task__top {
    position: relative;
    min-height: 24px
  }

  .weekplan-task__top .pills-wrap {
    max-width: 94%
  }

  .weekplan-task__top .event-icon {
    background-size: 18px;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    right: 0
  }

  .weekplan-task hr {
    height: 1px;
    width: calc(100% + 30px);
    border: 0;
    border-top: 1px solid #589ea5;
    margin: 1px -15px 1px -15px
  }

  .weekplan-task--comment {
    background-size: 18px;
    top: 5px
  }

  @media(max-width: 920px) {
    .studentplan-widget {
      margin: auto;
      max-width: 440px
    }
    .weekplan-container:last-of-type {
      margin-bottom: 20px
    }
    .plandate, .studentname {
      display: none
    }
    .childname {
      display: block
    }
    .weekplan-title {
      width: 50%;
      margin-top: 5px;
      display: inline-block;
      font-weight: 500;
      font-size: 20px
    }
    .widget-nav {
      width: 50%;
      font-weight: 500;
      float: right;
      display: inline-block;
      margin-top: 4px;
      text-align: right
    }
    .widget-title h2 {
      margin: 10px 20px 10px 0
    }
    .widget-title h2, .widget-nav span {
      font-size: 18px
    }
    .widget-title h3 {
      display: block;
      width: 100%
    }
    .widget-nav__week-link, .widget-nav span {
      margin-top: 10px
    }
    .weekdays {
      padding-bottom: 10px;
      width: 100%;
      display: inline-block
    }
    .weekdays__day {
      border-right: 1px solid #bebebe;
      height: auto;
      width: 20%;
      text-align: center;
      padding: 10px;
      background-color: #eeeff1;
      color: #19405a;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block
    }
    .weekdays__day:last-of-type {
      border-right: none
    }
    .weekdays__day.active {
      background-color: #19405a;
      color: #fff
    }
    .weekdays .circle {
      display: inline-block;
      width: 20%;
      height: auto;
      text-align: center;
      visibility: hidden
    }
    .weekdays .circle.visible {
      visibility: visible
    }
    .weekplan-container:not(:first-child) .weekplan__column__head {
      margin-top: 10px !important
    }
    .weekplan__column {
      display: none;
      width: 100%;
      max-width: none;
      margin: auto
    }
    .weekplan__column.active {
      display: block;
      width: 100%;
      max-width: none
    }
    .weekplan-container {
      max-width: 440px;
      margin: auto
    }
    .weekplan__column__tasks span {
      display: block;
      font-size: 16px;
      font-weight: 500
    }
  }
  @media(min-width: 920px) {
    .weekdays, .childname {
      display: none
    }
  }

  .narrow .studentplan-widget {
    margin: auto;
    max-width: 440px
  }

  .narrow .weekplan-container:last-of-type {
    margin-bottom: 20px
  }

  .narrow .plandate, .narrow .studentname {
    display: none
  }

  .narrow .childname {
    display: block
  }

  .narrow .weekplan-title {
    width: 50%;
    margin-top: 5px;
    display: inline-block;
    font-weight: 500;
    font-size: 20px
  }

  .narrow .widget-nav {
    width: 50%;
    font-weight: 500;
    float: right;
    display: inline-block;
    margin-top: 4px;
    text-align: right
  }

  .narrow .widget-title h2 {
    margin: 10px 20px 10px 0
  }

  .narrow .widget-title h2, .narrow .widget-nav span {
    font-size: 18px
  }

  .narrow .widget-title h3 {
    display: block;
    width: 100%
  }

  .narrow .widget-nav__week-link, .narrow .widget-nav span {
    margin-top: 10px
  }

  .narrow .weekdays {
    padding-bottom: 10px;
    width: 100%;
    display: inline-block
  }

  .narrow .weekdays__day {
    border-right: 1px solid #bebebe;
    height: auto;
    width: 20%;
    text-align: center;
    padding: 10px;
    background-color: #eeeff1;
    color: #19405a;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block
  }

  .narrow .weekdays__day:last-of-type {
    border-right: none
  }

  .narrow .weekdays__day.active {
    background-color: #19405a;
    color: #fff
  }

  .narrow .weekdays .circle {
    display: inline-block;
    width: 20%;
    height: auto;
    text-align: center;
    visibility: hidden
  }

  .narrow .weekdays .circle.visible {
    visibility: visible
  }

  .narrow .weekplan-container:not(:first-child) .weekplan__column__head {
    margin-top: 10px !important
  }

  .narrow .weekplan__column {
    display: none;
    width: 100%;
    max-width: none;
    margin: auto
  }

  .narrow .weekplan__column.active {
    display: block;
    width: 100%;
    max-width: none
  }

  .narrow .weekplan-container {
    max-width: 440px;
    margin: auto
  }

  .narrow .weekplan__column__tasks span {
    display: block;
    font-size: 16px;
    font-weight: 500
  }

  @media only screen and (max-width: 1300px) and (min-width: 980px) {
    .medium .studentplan-widget {
      margin: auto;
      max-width: 440px
    }
    .medium .weekplan-container:last-of-type {
      margin-bottom: 20px
    }
    .medium .plandate, .medium .studentname {
      display: none
    }
    .medium .childname {
      display: block
    }
    .medium .weekplan-title {
      width: 50%;
      margin-top: 5px;
      display: inline-block;
      font-weight: 500;
      font-size: 20px
    }
    .medium .widget-nav {
      width: 50%;
      font-weight: 500;
      float: right;
      display: inline-block;
      margin-top: 4px;
      text-align: right
    }
    .medium .widget-title h2 {
      margin: 10px 20px 10px 0
    }
    .medium .widget-title h2, .medium .widget-nav span {
      font-size: 18px
    }
    .medium .widget-title h3 {
      display: block;
      width: 100%
    }
    .medium .widget-nav__week-link, .medium .widget-nav span {
      margin-top: 10px
    }
    .medium .weekdays {
      padding-bottom: 10px;
      width: 100%;
      display: inline-block
    }
    .medium .weekdays__day {
      border-right: 1px solid #bebebe;
      height: auto;
      width: 20%;
      text-align: center;
      padding: 10px;
      background-color: #eeeff1;
      color: #19405a;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block
    }
    .medium .weekdays__day:last-of-type {
      border-right: none
    }
    .medium .weekdays__day.active {
      background-color: #19405a;
      color: #fff
    }
    .medium .weekdays .circle {
      display: inline-block;
      width: 20%;
      height: auto;
      text-align: center;
      visibility: hidden
    }
    .medium .weekdays .circle.visible {
      visibility: visible
    }
    .medium .weekplan-container:not(:first-child) .weekplan__column__head {
      margin-top: 10px !important
    }
    .medium .weekplan__column {
      display: none;
      width: 100%;
      max-width: none;
      margin: auto
    }
    .medium .weekplan__column.active {
      display: block;
      width: 100%;
      max-width: none
    }
    .medium .weekplan-container {
      max-width: 440px;
      margin: auto
    }
    .medium .weekplan__column__tasks span {
      display: block;
      font-size: 16px;
      font-weight: 500
    }
  }
}
</style>
