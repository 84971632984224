import uniqBy from 'lodash/uniqBy';
import { EventRenderer } from './eventRenderer';
import { CalendarEventModel } from '../../../shared/models/calendarEvent.model';
import { ResourceModel } from '../../../shared/models/resource.model';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { NotificationModel } from '../../../shared/models/notification.model';
import { parentTypes } from '../../../shared/enums/parentTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { htmlUtil } from '../../../shared/utils/htmlUtil';

export class MobileEventRenderer extends EventRenderer {
  private readonly maxProfileIconShow = 4;
  private readonly parent: parentTypes;
  private readonly institutions: InstitutionProfileModel[];
  private readonly areAnyActiveResources: boolean;
  private readonly users: ResourceModel[];

  constructor(
    { event, el }: { event: CalendarEventModel; el: HTMLElement },
    resources: ResourceModel[],
    notifications: NotificationModel[],
    profile: InstitutionProfileModel,
    delegatedContext: InstitutionProfileModel,
    noOfResources: number,
    parent: parentTypes,
    institutions: InstitutionProfileModel[],
    areAnyActiveResources
  ) {
    super(
      {
        event,
        el,
      },
      resources,
      notifications,
      profile,
      delegatedContext,
      noOfResources
    );
    this.parent = parent;
    this.institutions = institutions;
    this.areAnyActiveResources = areAnyActiveResources;
    this.users = this.getResources();
  }

  override render(): string {
    const timeHtml = this.getTimeHtml();
    const titleHtml = this.getTitleHtml();
    const profilePicturesHtml = htmlUtil.createSimpleDiv(
      null,
      ['mobile-view-picture-container', this.pictureContainerClass],
      this.getProfilePicturesHtml()
    );

    const htmlChildren = [timeHtml, titleHtml, profilePicturesHtml];
    const tableData = document.createElement('td');
    tableData.setAttribute('colspan', '3');
    tableData.classList.add('event-list-item');
    tableData.innerHTML = htmlUtil.createDiv(null, 'day-view-event-group', htmlChildren);
    return tableData.outerHTML;
  }

  private get pictureContainerClass() {
    return `users${Math.min(this.users.length, this.maxProfileIconShow)}`;
  }

  private getProfilePicturesHtml() {
    if (this.users.length === 0 || !this.areAnyActiveResources) {
      return '';
    }

    let htmlProfilePictures = '';
    let countProfiles = 0;

    for (const relatedResource of this.users) {
      if (countProfiles === this.maxProfileIconShow) {
        return;
      }
      countProfiles++;
      const numberProfileIcon = this.users.length - countProfiles;
      const { profilePicture, css } = this.getProfilePicture(relatedResource, numberProfileIcon);
      const profilePictureHtml = htmlUtil.createSimpleDiv(null, ['mobile-view-picture-event', css], profilePicture);

      htmlProfilePictures += profilePictureHtml;

      if (countProfiles === this.users.length) {
        return htmlProfilePictures;
      }
    }
  }

  private getTimeHtml() {
    const classes = this.allDay ? ['day-view-time-event', 'mobile-whole-day'] : ['day-view-time-event'];
    return htmlUtil.createSimpleDiv(null, classes, this.leftColumnText);
  }

  private getTitleHtml() {
    return htmlUtil.createSimpleDiv(null, 'mobile-view-title-event', this.detailedBoldedTitle);
  }

  override get updatedEventText(): string {
    const updatedEventText = super.updatedEventText;
    return this.titleHasIcons ? updatedEventText : htmlUtil.createSpan('d-block', updatedEventText);
  }

  override get isOneLineTitle(): boolean {
    return false;
  }

  private getProfilePicture(relatedResource: ResourceModel, numberProfileIcon: number) {
    const css = numberProfileIcon > 0 ? 'multiple user' + numberProfileIcon : '';

    if (relatedResource.profilePicture != null) {
      return { profilePicture: this.getProfilePictureHtmlByPicture(relatedResource), css };
    }
    if (relatedResource.name != null) {
      return { profilePicture: this.getProfilePictureHtmlByName(relatedResource), css };
    }

    return { profilePicture: this.getProfilePictureHtmlDefault(), css: '' };
  }

  private getProfilePictureHtmlByPicture(relatedResource: ResourceModel) {
    return htmlUtil.createImg(relatedResource.profilePicture.url, relatedResource.name);
  }

  private getProfilePictureHtmlByName(relatedResource: ResourceModel) {
    const initials = relatedResource.shortName;
    const cssFontSize = initials.length > 3 ? 'length' + initials.length : '';
    const initialsHtmlSpan = htmlUtil.createSpan(cssFontSize, initials);

    return htmlUtil.createSimpleDiv(relatedResource.name, 'user-icon', initialsHtmlSpan);
  }

  private getProfilePictureHtmlDefault() {
    return '';
  }

  private getResources(): ResourceModel[] {
    if (this.parent === 'group') {
      return [];
    } else {
      let institutionResources = [];
      if (this.profile.role === portalRoles.EMPLOYEE) {
        institutionResources = this.institutions.filter(institution =>
          this.extendedProps.belongsToProfiles?.includes(institution.id)
        );
      }
      const relatedResourcesIds = institutionResources.map(res => res.institutionProfileId);
      const eventResources = this.resources.filter(
        res =>
          this.extendedProps.belongsTo?.includes(res.id.toString()) &&
          !relatedResourcesIds.includes(parseInt(res.singleId))
      );

      return uniqBy([...institutionResources, ...eventResources], res => res.id);
    }
  }
}
