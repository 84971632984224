<template>
  <drawer :show="isShown" :focus-target="focusTarget" @change-show="changeDrawerShow">
    <template #drawer-title>
      <div class="presence-drawer-header">
        <Icon :name="iconClassEnum.EXPORT" class="presence-drawer-icon hide-tablet-down mr-3" aria-hidden="true" />
        <span>{{ title }}</span>
      </div>
    </template>
    <template #header-right>
      <AulaButton
        v-if="isReadonly && isEditable"
        variant="link"
        class="drawer-edit-button mt-0 to-the-right"
        :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
        @click="onEditMode()"
      >
        <Icon :name="iconClassEnum.EDIT_PEN" class="icon" />
        <span v-if="!isMobile"> {{ 'BUTTON_EDIT' | fromTextKey }}</span>
      </AulaButton>
    </template>
    <template #drawer-content>
      <aula-spinner v-if="isLoading" />
      <div v-else-if="guardianVacationResponse" class="register-vacation-request-container">
        <div v-if="isReadonly && !isEditable" class="aula-alert mb-3">
          <div class="icon-box-exclamation" aria-hidden="true">
            <div class="circle">
              <i class="icon-Aula_exclamation_sign" />
            </div>
          </div>
          <div class="alert-text">
            {{ 'PRESENCE_WARNING_VACATION_REQUEST_UNEDITABLE' | fromTextKey }}
          </div>
        </div>
        <h2 v-if="activeChild" class="mb-3">
          <span v-if="!isMobile">{{ 'RESPONSE_VACATION_REQUEST_FOR' | fromTextKey }}</span>
          {{ activeChild | displayProfileNameWithMetadata }}
        </h2>
        <template v-if="isMobile">
          <div class="d-flex">
            <div class="field-label">{{ 'PRESENCE_VACATION_FR0M' | fromTextKey }}:</div>
            <div>
              {{ convertDateFormat(guardianVacationResponse.vacationRegistration.startDate) }}
            </div>
          </div>
          <div class="d-flex">
            <div class="field-label">{{ 'PRESENCE_VACATION_TO' | fromTextKey }}:</div>
            <div>
              {{ convertDateFormat(guardianVacationResponse.vacationRegistration.endDate) }}
            </div>
          </div>
        </template>
        <div v-else class="d-flex">
          <div class="field-label">
            {{ 'CALENDAR_VACATION_REQUEST_PERIOD_1' | fromTextKey }}
          </div>
          <div>
            {{ convertDateFormat(guardianVacationResponse.vacationRegistration.startDate) }}
            -
            {{ convertDateFormat(guardianVacationResponse.vacationRegistration.endDate) }}
          </div>
        </div>
        <div v-if="guardianVacationResponse.vacationRegistration.responseDeadline" class="d-flex">
          <div class="field-label">{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}:</div>
          <div>
            {{ convertDateFormat(guardianVacationResponse.vacationRegistration.responseDeadline) }}
          </div>
        </div>
        <div class="my-3 my-lg-4">
          {{ guardianVacationResponse.vacationRegistration.noteToGuardian }}
        </div>
        <div v-if="!isMobile" class="font-weight-bold">
          {{ 'RESPONSE_VACATION_REQUEST_INTRODUCTION' | fromTextKey }}.
        </div>
        <div class="vacation-intervals mt-3 mt-lg-4">
          <template v-for="(vacationRequestWeek, index) in vacationRequestWeeks">
            <template v-if="isMobile">
              <b-row :key="'week' + index" class="header-container mt-2">
                <b-col class="header-item font-weight-bold d-flex justify-content-between text-uppercase px-4">
                  <div>
                    {{ getMonthLabelForWeek(vacationRequestWeek.week) }}
                  </div>
                  <div>
                    {{ 'CALENDAR_VACATION_REQUEST_WEEK' | fromTextKey }}
                    {{ vacationRequestWeek.week }}
                  </div>
                </b-col>
              </b-row>
              <VacationIntervalsFragmentMobileComeAllWeek
                v-if="isVacationFullWeek(vacationRequestWeek)"
                :vacation-request-days="
                  vacationRequestDaysList.filter(
                    day => day.week === vacationRequestWeek.week && day.year === vacationRequestWeek.year
                  )
                "
                :is-readonly="isReadonly"
              />
              <template
                v-for="(vacationDay, vacationDayIndex) in vacationRequestDaysList.filter(
                  day => day.week === vacationRequestWeek.week && day.year === vacationRequestWeek.year
                )"
              >
                <b-row :key="'day' + index + '' + vacationDay.date + '' + vacationDayIndex" class="response-day">
                  <b-col cols="3" sm="2" class="day pl-4 pt-2">
                    <strong>{{ getShortDateLabel(vacationDay.date) | firstLetterUppercase }}</strong>
                  </b-col>
                  <b-col class="mt-2 mb-1">
                    <p v-if="vacationDay.getIsClosingDay()" class="checkbox-text-disabled">
                      {{ getComingString(vacationDay) }}
                    </p>
                    <template v-else>
                      <b-form-checkbox
                        v-model="vacationDay.isComing"
                        :unchecked-value="null"
                        :disabled="isReadonly || vacationDay.isDisabled"
                        @input="onChangeVacationRequest"
                      >
                        {{ getComingString(vacationDay) }}
                      </b-form-checkbox>
                      <template v-if="!vacationDay.getIsClosingDay() && vacationDay.isComing && vacationDay.useTimes">
                        <b-row class="checkin-time ml-1 pt-1">
                          <b-col cols="4">
                            {{ 'RESPONSE_VACATION_COMMING_HOUR' | fromTextKey }}
                          </b-col>
                          <b-col cols="8">
                            <VacationIntervalsFragmentTimepicker
                              v-model="vacationDay.entryTime"
                              class="time"
                              :class="{
                                'red-border':
                                  vacationDay.getShowIsWithinOpeningHourError() ||
                                  vacationDay.getShowHasTimesError() ||
                                  vacationDay.getShowIsValidTimesError(),
                              }"
                              :disabled="isReadonly || vacationDay.isDisabled"
                              :start="vacationDay.getStartTime()"
                              :end="vacationDay.getEndTime()"
                              @change="validateComingTime(vacationDay)"
                            />
                          </b-col>
                        </b-row>
                        <b-row class="checkin-time ml-1">
                          <b-col cols="4">
                            {{ 'RESPONSE_VACATION_NOT_COMMING_HOUR' | fromTextKey }}
                          </b-col>
                          <b-col cols="8">
                            <VacationIntervalsFragmentTimepicker
                              v-model="vacationDay.exitTime"
                              class="time"
                              :class="{
                                'red-border':
                                  vacationDay.getShowIsWithinOpeningHourError() ||
                                  vacationDay.getShowHasTimesError() ||
                                  vacationDay.getShowIsValidTimesError(),
                              }"
                              :disabled="isReadonly || vacationDay.isDisabled"
                              :start="vacationDay.getStartTime()"
                              :end="vacationDay.getEndTime()"
                              @change="validateComingTime(vacationDay)"
                            />
                          </b-col>
                          <b-col cols="12">
                            <b-alert variant="danger" :show="vacationDay.getShowIsWithinOpeningHourError()">
                              {{ 'RESPONSE_VACATION_TIMES_OUTSIDE_OPENING_HOURS' | fromTextKey }}
                            </b-alert>
                            <b-alert variant="danger" :show="vacationDay.getShowHasTimesError()">
                              {{ 'RESPONSE_VACATION_COMING_TIME_INVALID' | fromTextKey }}
                            </b-alert>
                            <b-alert variant="danger" :show="vacationDay.getShowIsValidTimesError()">
                              {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
                            </b-alert>
                          </b-col>
                        </b-row>
                      </template>
                      <b-form-checkbox
                        v-model="vacationDay.isComing"
                        :value="false"
                        :unchecked-value="null"
                        :disabled="isReadonly || vacationDay.isDisabled"
                        @input="onChangeVacationRequest"
                        @change="onNotComingDateChange(vacationDay.date)"
                      >
                        {{ getLeavingString(vacationDay) }}
                      </b-form-checkbox>
                    </template>
                    <b-row
                      v-if="vacationDay.isComing && vacationDay.getIsSpecificOpeningHours() && !vacationDay.isDisabled"
                      aria-hidden="true"
                    >
                      <b-col md="auto" class="circle-parent">
                        <i class="icon-Aula_exclamation_sign circle-specific-times icon-size" />
                      </b-col>
                      <b-col class="pl-0">
                        <span>
                          {{ getSpecificOpeningHoursString(vacationDay) }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </template>
            <template v-else>
              <b-row :key="'week' + index" class="header-container font-weight-bold mt-2">
                <b-col class="header-item mr-1">
                  {{ 'CALENDAR_VACATION_REQUEST_WEEK' | fromTextKey }}
                  {{ vacationRequestWeek.week }}
                </b-col>
                <b-col class="header-item mr-1 col-lg-4">
                  <b-form-checkbox
                    v-if="isVacationFullWeek(vacationRequestWeek)"
                    :checked="isComingAllWeek(vacationRequestWeek, true)"
                    class="m-0"
                    :disabled="vacationDaysDisabled(vacationRequestWeek.week)"
                    @input="onChangeVacationRequest"
                    @change="checkIsComingAllWeek(vacationRequestWeek, true)"
                  >
                    {{ 'RESPONSE_VACATION_COMMING_TITLE' | fromTextKey }}
                  </b-form-checkbox>
                </b-col>
                <b-col class="header-item col-lg-4">
                  <b-form-checkbox
                    v-if="isVacationFullWeek(vacationRequestWeek)"
                    :checked="isComingAllWeek(vacationRequestWeek, false)"
                    class="m-0"
                    :disabled="vacationDaysDisabled(vacationRequestWeek.week)"
                    @input="onChangeVacationRequest"
                    @change="checkIsComingAllWeek(vacationRequestWeek, false)"
                  >
                    {{ 'RESPONSE_VACATION_NOT_COMMING_TITLE' | fromTextKey }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <VacationIntervalsFragmentComeAllWeek
                v-if="
                  canSelectPresenceTimeForAllWeek(
                    vacationRequestWeek,
                    true,
                    guardianVacationResponse.vacationRegistration.isPresenceTimesRequired
                  )
                "
                class="come-all-week"
                :disabled="isReadonly"
                :vacation-request-days="
                  vacationRequestDaysList.filter(
                    day => day.week === vacationRequestWeek.week && day.year === vacationRequestWeek.year
                  )
                "
              />
              <template
                v-for="(vacationDay, vacationDayIndex) in vacationRequestDaysList.filter(
                  day => day.week === vacationRequestWeek.week && day.year === vacationRequestWeek.year
                )"
              >
                <b-row :key="'day-' + index + '-' + vacationDay.date + '-' + vacationDayIndex" class="response-day">
                  <b-col class="day pl-3 mr-1">
                    <strong>{{ vacationDay.date | longDate(true) | firstLetterUppercase }}</strong>
                  </b-col>
                  <b-col class="pl-3 col-lg-4">
                    <p v-if="vacationDay.getIsClosingDay()" class="checkbox-text-disabled">
                      {{ getComingString(vacationDay) }}
                    </p>
                    <b-form-checkbox
                      v-else
                      v-model="vacationDay.isComing"
                      :unchecked-value="null"
                      :disabled="isReadonly || vacationDay.isDisabled"
                      @input="onChangeVacationRequest"
                    >
                      {{ getComingString(vacationDay) }}
                    </b-form-checkbox>
                    <b-row
                      v-if="vacationDay.isComing && vacationDay.getIsSpecificOpeningHours() && !vacationDay.isDisabled"
                      aria-hidden="true"
                    >
                      <b-col md="auto" class="circle-parent">
                        <i class="icon-Aula_exclamation_sign circle-specific-times icon-size" />
                      </b-col>
                      <b-col class="pl-0">
                        <span>
                          {{ getSpecificOpeningHoursString(vacationDay) }}
                        </span>
                      </b-col>
                    </b-row>
                    <template v-if="!vacationDay.getIsClosingDay() && vacationDay.isComing && vacationDay.useTimes">
                      <div class="checkin-time d-flex justify-content-end flex-wrap">
                        <div class="mx-1">
                          {{ 'RESPONSE_VACATION_COMMING_HOUR' | fromTextKey }}
                          <VacationIntervalsFragmentTimepicker
                            v-model="vacationDay.entryTime"
                            class="time"
                            :class="{
                              'red-border':
                                vacationDay.getShowIsWithinOpeningHourError() ||
                                vacationDay.getShowHasTimesError() ||
                                vacationDay.getShowIsValidTimesError(),
                            }"
                            :disabled="isReadonly || vacationDay.isDisabled"
                            :start="vacationDay.getStartTime()"
                            :end="vacationDay.getEndTime()"
                            @change="validateComingTime(vacationDay)"
                          />
                        </div>
                        <div class="ml-1">
                          {{ 'RESPONSE_VACATION_NOT_COMMING_HOUR' | fromTextKey }}
                          <VacationIntervalsFragmentTimepicker
                            v-model="vacationDay.exitTime"
                            class="time"
                            :class="{
                              'red-border':
                                vacationDay.getShowIsWithinOpeningHourError() ||
                                vacationDay.getShowHasTimesError() ||
                                vacationDay.getShowIsValidTimesError(),
                            }"
                            :disabled="isReadonly || vacationDay.isDisabled"
                            :start="vacationDay.getStartTime()"
                            :end="vacationDay.getEndTime()"
                            @change="validateComingTime(vacationDay)"
                          />
                        </div>
                        <div class="w-100">
                          <b-alert variant="danger" :show="vacationDay.getShowIsWithinOpeningHourError()">
                            {{ 'RESPONSE_VACATION_TIMES_OUTSIDE_OPENING_HOURS' | fromTextKey }}
                          </b-alert>
                          <b-alert variant="danger" :show="vacationDay.getShowHasTimesError()">
                            {{ 'RESPONSE_VACATION_COMING_TIME_INVALID' | fromTextKey }}
                          </b-alert>
                          <b-alert variant="danger" :show="vacationDay.getShowIsValidTimesError()">
                            {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
                          </b-alert>
                        </div>
                      </div>
                    </template>
                  </b-col>
                  <b-col class="pl-3 col-lg-4">
                    <p v-if="vacationDay.getIsClosingDay()" class="checkbox-text-disabled">
                      {{ getLeavingString(vacationDay) }}
                    </p>
                    <b-form-checkbox
                      v-else
                      v-model="vacationDay.isComing"
                      :value="false"
                      :unchecked-value="null"
                      :disabled="isReadonly || vacationDay.isDisabled"
                      @input="onChangeVacationRequest"
                      @change="onNotComingDateChange(vacationDay.date)"
                    >
                      {{ getLeavingString(vacationDay) }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </template>
            </template>
          </template>
        </div>
        <b-alert class="my-2" variant="danger" :show="isMissingData">
          {{ 'CALENDAR_CANT_SAVE_VACATION_WARNING' | fromTextKey }}
        </b-alert>
        <label class="mt-3"> {{ 'RESPONSE_VACATION_NOTE_LABEL' | fromTextKey }} </label>
        <b-form-textarea
          v-model="comment"
          :placeholder="'RESPONSE_VACATION_NOTE_PLACEHOLDER' | fromTextKey"
          :disabled="isReadonly || isComingEntireVacationPeriod"
          maxlength="250"
          rows="3"
          @change="hasDataChanged = true"
        />
        <aula-form-footer
          :is-loading="isSavingResponse"
          :submit-text="isReadonly ? 'BUTTON_CLOSE' : 'BUTTON_SAVE'"
          :show-cancel="!isReadonly"
          @submitClicked="isReadonly ? handleDiscard() : onSaveClicked()"
          @cancelClicked="handleDiscard"
        />
      </div>
      <aula-modal
        ref="discardResponseWarning"
        @cancelClicked="$refs.discardResponseWarning.hide()"
        @okClicked="discardResponse()"
      >
        {{ 'CALENDAR_CANCEL_INFO' | fromTextKey }}<br />{{ 'CALENDAR_CANCEL_INFO_2' | fromTextKey }}
      </aula-modal>
    </template>
  </drawer>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import { isoWeekdayEnum } from '../../../shared/enums/isoWeekdayEnum';
import { presenceOpenHours } from '../../../shared/enums/presenceOpenHours';
import Vue from 'vue';
import { comeGoUtil } from '../../../shared/utils/comeGoUtil';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import { NewVacationInterval } from '../../business/newVacationInterval';
import VacationIntervalsFragmentMobileComeAllWeek from '../../../shared/PageFragments/VacationIntervalsFragment/VacationIntervalsFragmentMobileComeAllWeek';
import VacationIntervalsFragmentComeAllWeek from '../../../shared/PageFragments/VacationIntervalsFragment/VacationIntervalsFragmentComeAllWeek';
import VacationIntervalsFragmentTimepicker from '../../../shared/PageFragments/VacationIntervalsFragment/VacationIntervalsFragmentTimepicker';
import { isTheSameDate, now } from '../../../shared/utils/dateUtil';
import { exitTypes } from '../../../shared/enums/exitTypes';
import { isTodayOrAfter } from '../../../shared/utils/dateUtil';
import { isGuardian } from '../../../shared/functions/is-guardian';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import Icon from '../../../shared/components/Icon.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { truncated } from '../../../shared/assets/plugins/filters.js';

export default {
  components: {
    AulaButton,
    Icon,
    VacationIntervalsFragmentTimepicker,
    VacationIntervalsFragmentComeAllWeek,
    VacationIntervalsFragmentMobileComeAllWeek,
  },
  data: function () {
    return {
      timepickerSettings,
      isShown: false,
      isLoading: false,
      isSavingResponse: false,
      focusTarget: null,
      isReadonly: false,
      isEditable: false,
      isMissingData: false,
      vacationRequestTitle: null,
      comingTimes: [],
      comment: '',
      activeChild: null,
      hasDataChanged: false,
      presenceOpenHours: presenceOpenHours,
      generalOpeningsHours: [],
      vacationRequestDaysList: [],
    };
  },
  computed: {
    title() {
      const maximumLength = this.isMobile ? 40 : 120;
      return truncated(this.vacationRequestTitle, maximumLength);
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      guardianVacationResponse: types.GET_VACATION_REQUEST_RESPONSE,
      notifications: types.GET_NOTIFICATIONS,
      openHours: types.GET_GENERAL_OPENING_HOURS_FOR_GUARDIAN,
      specificOpenHours: types.GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
      presenceTimes: types.GET_PRESENCE_TIMES,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    childInstitutionGeneralOpeningHoursDictionary() {
      const dictionary = {};
      const openHour = this.openHours.find(openHour => openHour.institutionCode === this.activeChild.institutionCode);
      const openingHours = openHour?.openingHours || [];
      for (const openingHour of openingHours) {
        dictionary[openingHour.dayOfWeek] = openingHour;
      }
      return dictionary;
    },
    specificOpeningHoursDictionary() {
      const dictionary = {};
      this.specificOpenHours.openingHoursOverviewDto;
      const specificOpeningHourOverview = this.specificOpenHours.openingHoursOverviewDto.find(
        openHour => openHour.institutionCode === this.activeChild.institutionCode
      );
      const openingHours = specificOpeningHourOverview?.openingHoursDto || [];
      for (const openingHour of openingHours) {
        dictionary[openingHour.date] = openingHour;
      }
      return dictionary;
    },
    childPresenceDayTemplateDictionary() {
      const dictionary = {};
      const id = this.guardianVacationResponse.vacationRegistrationResponse.childId;
      const weekTemplates = this.presenceTimes.presenceWeekTemplates;
      const weekTemplate = weekTemplates.find(({ institutionProfile }) => institutionProfile.id === id);
      const dayTemplates = weekTemplate?.dayTemplates || [];
      for (const dayTemplate of dayTemplates) {
        const formattedDate = moment(dayTemplate.byDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        dictionary[formattedDate] = dayTemplate;
      }
      return dictionary;
    },
    vacationRequestWeeks() {
      const weekMap = new Map();
      if (this.guardianVacationResponse) {
        for (const { year, week } of this.vacationRequestDaysList) {
          weekMap.set(`${week},${year}`, { week, year });
        }
      }
      return Array.from(weekMap.values());
    },
    isComingEntireVacationPeriod() {
      return this.vacationRequestDaysList.every(
        vacationRequestDay => !vacationRequestDay.isDisabled && vacationRequestDay.isComing
      );
    },
  },
  methods: {
    ...mapActions({
      loadGuardianVacationResponse: types.LOAD_VACATION_REQUEST_RESPONSE,
      addVacation: types.ADD_VACATION,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      getSpecificOpeningHours: types.LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
      getGeneralOpeningHours: types.LOAD_GENERAL_OPENING_HOURS_FOR_GUARDIAN,
      loadPresenceTimes: types.LOAD_PRESENCE_TIMES,
    }),
    isComingAllWeek(vacationWeek, isComingValue) {
      const vacationDays = this.vacationRequestDaysList.filter(vacationDay => {
        if (vacationWeek.year !== vacationDay.year || vacationWeek.week !== vacationDay.week) {
          return false;
        }

        return !vacationDay.isDisabled;
      });
      let isChecked = true;
      for (const vacationDay of vacationDays) {
        if (vacationDay.isComing !== isComingValue) {
          isChecked = null;
          break;
        }
      }
      return vacationDays.length > 0 && isChecked;
    },
    checkIsComingAllWeek(vacationWeek, isComingValue) {
      let comingValue = isComingValue;
      if (this.isComingAllWeek(vacationWeek, isComingValue)) {
        comingValue = null;
      }

      const vacationDays = this.vacationRequestDaysList.filter(
        ({ year, week }) => vacationWeek.year === year && vacationWeek.week === week
      );
      comeGoUtil.updateIsComingValueForVacationRequestDays(vacationDays, comingValue);
    },
    onEditMode() {
      this.isReadonly = false;
    },
    populateVacationRequestDaysList() {
      const vacationRegistration = this.guardianVacationResponse.vacationRegistration;
      const fromDate = moment(vacationRegistration.startDate).startOf('day');
      const endDate = moment(vacationRegistration.endDate).endOf('day');
      this.comment = this.guardianVacationResponse.vacationRegistrationResponse.comment;
      this.vacationRequestDaysList = [];
      while (fromDate.isBefore(endDate)) {
        const isoWeekDay = fromDate.isoWeekday();
        if (isoWeekDay <= isoWeekdayEnum.FRIDAY) {
          const formattedDate = fromDate.format('YYYY-MM-DD');
          this.vacationRequestDaysList.push(
            new NewVacationInterval(
              {
                date: formattedDate,
                year: fromDate.isoWeekYear(),
                week: fromDate.isoWeek(),
                isComing: this.getVacationResponseIsComing(formattedDate),
                entryTime: this.getVacationResponseEntryTime(formattedDate),
                exitTime: this.getVacationResponseExitTime(formattedDate),
                useTimes: vacationRegistration.isPresenceTimesRequired,
              },
              vacationRegistration,
              this.specificOpeningHoursDictionary[formattedDate],
              this.childInstitutionGeneralOpeningHoursDictionary[isoWeekDay],
              this.childPresenceDayTemplateDictionary[formattedDate]
            )
          );
        }
        fromDate.add(1, 'day');
      }
    },
    getVacationResponseIsComing(date) {
      const vacationResponse = this.getVacationDayResponse(date);
      let isComing = null;
      if (vacationResponse) {
        isComing = vacationResponse.isComing;
      }
      return isComing;
    },
    getVacationResponseEntryTime(date) {
      const vacationResponse = this.getVacationDayResponse(date);
      let entryTime = '';
      if (vacationResponse?.entryTime) {
        entryTime = vacationResponse.entryTime;
      }
      return entryTime;
    },
    getVacationResponseExitTime(date) {
      const vacationResponse = this.getVacationDayResponse(date);
      let exitTime = '';
      if (vacationResponse?.exitTime) {
        exitTime = vacationResponse.exitTime;
      }
      return exitTime;
    },
    getVacationDayResponse(date) {
      const { days } = this.guardianVacationResponse.vacationRegistrationResponse;
      return days.find(day => moment(day.date).format('YYYY-MM-DD') === date);
    },
    show(vacationRequest, child, isReadonly) {
      this.isShown = true;
      this.focusTarget = this.isMobile ? '.icon-Aula_arrow_new_item.aula-close-arrow' : '.icon-Aula_close.aula-close';
      this.isReadonly = isReadonly;
      this.isEditable = vacationRequest.isEditable;
      if (isGuardian(this.profile)) {
        this.isEditable = this.hasCustodyOrExtendedAccess(child.id) && isTodayOrAfter(vacationRequest.responseDeadline);
      }
      this.vacationRequestTitle = vacationRequest.title;
      this.activeChild = child;

      this.resetData();
      this.getGuardianVacationResponse(vacationRequest);
    },
    loadChildPresenceTime(vacationRequest) {
      const dateFormat = 'YYYY-MM-DD';
      let startDate = moment(vacationRequest.startDate, dateFormat);
      const endDate = moment(vacationRequest.endDate, dateFormat);
      const today = now();

      if (endDate.isBefore(today)) {
        return;
      }

      if (startDate.isBefore(today)) {
        startDate = moment().add(1, 'days');
      }

      return this.loadPresenceTimes({
        institutionProfileIds: [this.activeChild.id],
        fromDate: startDate.format(dateFormat),
        toDate: endDate.format(dateFormat),
      });
    },
    hide() {
      this.$emit('onClosed');
      this.$nextTick(() => (this.isShown = false));
    },
    changeDrawerShow(value) {
      if (!value) {
        this.handleDiscard();
      }
    },
    convertDateFormat(datetime) {
      return moment(datetime).format('DD. MMM. YYYY');
    },
    getComingString(vacationDay) {
      if (vacationDay.getIsClosingDay()) {
        const { name } = vacationDay.specificOpeningHour;
        return Vue.filter('fromTextKey')('PRESENCE_VACATION_CLOSED_DAYS_NAME_CLOSED_PLACEHOLDER') + ' ' + name;
      }

      return Vue.filter('fromTextKey')('RESPONSE_VACATION_COMING');
    },
    getLeavingString(vacationDay) {
      if (vacationDay.getIsClosingDay()) {
        const { name } = vacationDay.specificOpeningHour;
        return Vue.filter('fromTextKey')('PRESENCE_VACATION_CLOSED_DAYS_NAME_CLOSED_PLACEHOLDER') + ' ' + name;
      }

      return Vue.filter('fromTextKey')('RESPONSE_VACATION_NOT_COMING');
    },
    getSpecificOpeningHoursString(vacationDay) {
      const { openTime, closeTime } = vacationDay.specificOpeningHour;
      const remarkString = Vue.filter('fromTextKey')('PRESENCE_VACATION_SPECIFIC_OPENING_HOURS_PLACEHOLDER');
      const changedOpeningString = Vue.filter('fromTextKey')(
        'PRESENCE_VACATION_SPECIFIC_OPENING_HOURS_NAME_PLACEHOLDER'
      );
      return `${remarkString} ${changedOpeningString} ${openTime} - ${closeTime}`;
    },
    getSpecificOpeningHourString(openingHour) {
      if (openingHour && openingHour.type === presenceOpenHours.SPECIFIC_OPEN_HOURS) {
        const remarkString = Vue.filter('fromTextKey')('PRESENCE_VACATION_SPECIFIC_OPENING_HOURS_PLACEHOLDER');
        const changedOpeningString = Vue.filter('fromTextKey')(
          'PRESENCE_VACATION_SPECIFIC_OPENING_HOURS_NAME_PLACEHOLDER'
        );
        return `${remarkString} ${changedOpeningString} ${openingHour.openTime} - ${openingHour.closeTime}`;
      }

      return Vue.filter('fromTextKey')('RESPONSE_VACATION_COMING');
    },
    onNotComingDateChange() {
      this.hasDataChanged = true;
    },
    validateComingTime(vacationDay) {
      vacationDay.isValid();
      this.hasDataChanged = true;
    },
    onChangeVacationRequest() {
      this.hasDataChanged = true;
    },
    getMonthLabelForWeek(week) {
      const startDayOfWeekMonth = moment().isoWeek(week).startOf('isoWeek').format('MMMM YYYY');
      const endDayOfWeekMonth = moment().isoWeek(week).endOf('isoWeek').format('MMMM YYYY');
      if (startDayOfWeekMonth === endDayOfWeekMonth) {
        return startDayOfWeekMonth;
      }
      return `${startDayOfWeekMonth} - ${endDayOfWeekMonth}`;
    },
    getShortDateLabel(date) {
      return moment(date).format('ddd - DD');
    },
    async getGuardianVacationResponse(vacationRegistration) {
      this.isLoading = true;
      await Promise.all([
        this.loadChildPresenceTime(vacationRegistration),
        this.loadGuardianVacationResponse({
          vacationRegistrationResponseId: vacationRegistration.responseId,
        }),
      ]);
      await this.loadOpeningHours();
      this.populateVacationRequestDaysList();
      this.isLoading = false;
    },
    validateForm() {
      let isValid = true;
      this.vacationRequestDaysList.forEach(vacationRequestDay => {
        vacationRequestDay.setSubmitClicked(true);
        if (vacationRequestDay.isValid()) {
          return;
        }

        isValid = false;
        if (!vacationRequestDay.validator.hasTimes || !vacationRequestDay.validator.isValidIncoming) {
          this.isMissingData = true;
        }
      });
      return isValid;
    },
    updateVacationResponses() {
      this.isSavingResponse = true;
      const days = this.vacationRequestDaysList
        .filter(vacationRequestDay => !vacationRequestDay.isDisabled)
        .map(vacationRequestDay => {
          let isComing = vacationRequestDay.isComing;
          let entryTime = vacationRequestDay.entryTime;
          let exitTime = vacationRequestDay.exitTime;
          const useTimes = vacationRequestDay.useTimes;
          const id = this.guardianVacationResponse.vacationRegistrationResponse.childId;
          const weekTemplates = this.presenceTimes.presenceWeekTemplates;
          const weekTemplate = weekTemplates.find(({ institutionProfile }) => institutionProfile.id === id);
          const dayTemplates = weekTemplate?.dayTemplates || [];

          if (dayTemplates === []) {
            return;
          }

          const presenceTimeOfTheVacationDay = dayTemplates.find(presenceTime =>
            isTheSameDate(presenceTime.byDate, vacationRequestDay.date)
          );
          if (vacationRequestDay.getIsClosingDay()) {
            isComing = false;
          }

          if (isComing === false || useTimes === false) {
            entryTime = '';
            exitTime = '';
          } else if (presenceTimeOfTheVacationDay?.activityType === exitTypes.SELF_DECIDER && isComing === true) {
            if (!exitTime) {
              exitTime = presenceTimeOfTheVacationDay.selfDeciderEndTime;
            }
          }
          return {
            date: vacationRequestDay.date,
            isComing,
            entryTime,
            exitTime,
          };
        });
      this.addVacation({
        vacationRegistrationResponseId:
          this.guardianVacationResponse.vacationRegistrationResponse.vacationRegistrationResponseId,
        childId: this.guardianVacationResponse.vacationRegistrationResponse.childId,
        days,
        comment: this.comment,
      })
        .then(() => {
          if (this.guardianVacationResponse.vacationRegistration.isMissingAnswer) {
            const notificationsToBeDeleted = this.notifications.filter(
              notification =>
                notification.vacationRegistrationResponseId ===
                this.guardianVacationResponse.vacationRegistrationResponse.vacationRegistrationResponseId
            );
            this.deleteNotifications({
              notifications: notificationsToBeDeleted,
            });
          }
          this.hide();
        })
        .finally(() => {
          this.isSavingResponse = false;
        });
    },
    onSaveClicked() {
      if (this.validateForm()) {
        this.updateVacationResponses();
      }
    },
    resetData() {
      this.comment = '';
      this.isMissingData = false;
      this.hasDataChanged = false;
    },
    discardResponse() {
      this.$refs.discardResponseWarning.hide();
      this.hide();
    },
    handleDiscard() {
      if (this.hasDataChanged) {
        this.$refs.discardResponseWarning.show();
      } else {
        this.hide();
      }
    },
    loadOpeningHours() {
      const startDate = this.guardianVacationResponse.vacationRegistration.startDate;
      const endDate = this.guardianVacationResponse.vacationRegistration.endDate;
      const institutionCodes = [this.activeChild.institutionCode];
      const payload = { institutionCodes, startDate, endDate };
      const generalHoursPayload = { institutionCodes };

      this.getGeneralOpeningHours(generalHoursPayload);
      return this.getSpecificOpeningHours(payload);
    },
    vacationDaysDisabled(weekNo) {
      return (
        this.isReadonly ||
        this.vacationRequestDaysList.filter(requestDay => requestDay.week === weekNo).every(day => day.isDisabled)
      );
    },
    isVacationFullWeek(vacationWeek) {
      const vacationDays = this.vacationRequestDaysList.filter(
        vacationDay => vacationDay.week === vacationWeek.week && vacationDay.year === vacationWeek.year
      );
      return vacationDays.length === 5;
    },
    canSelectPresenceTimeForAllWeek(vacationWeek, isComing, isPresenceTimeRequired) {
      const isFullWeek = this.isVacationFullWeek(vacationWeek);
      const isComingAllWeek = this.isComingAllWeek(vacationWeek, isComing);

      return isFullWeek && isComingAllWeek && isPresenceTimeRequired;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/_presence.scss';

.come-all-week {
  margin-top: 4px;
}

.vacation-intervals {
  --input-disabled-color: var(--color-help-text);
}

.circle-specific-times {
  align-items: center;
  background: $color-white;
  border-radius: 15px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 5px;
  width: 20px;
}

.circle-parent {
  padding-right: 0px;
}

.icon-size {
  font-size: 12px;
}
</style>
