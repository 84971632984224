





























import AulaAlert from '../AulaAlert.vue';
import { PropType } from 'vue';
import { NotificationModel } from '../../models/notification.model';
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import { notificationEventTypes } from '../../enums/notificationEventTypes';
import { mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types.js';
import * as dateUtil from '../../utils/dateUtil.js';
import { dateFormatEnum } from '../../enums/dateFormatEnum';
import button from '../../../shared/directives/button.js';
import truncate from 'lodash/truncate';
import { alertType } from '../../enums/alertType';
import { convertWeekdayMask } from '../../../src/components/calendar/convertWeekdayMask.js';
import { RepeatingPattern } from '../../../src/business/calendar/repeatingPattern';

export default {
  components: { Icon, AulaAlert },
  directives: {
    button,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationModel>,
      required: true,
    },
  },
  emits: ['open', 'delete'],
  data: function () {
    return {
      convertWeekdayMask: convertWeekdayMask,
    };
  },
  computed: {
    ...mapGetters({
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    alertType() {
      if (this.isDismissable) {
        return alertType.INFORMATION;
      }
      return alertType.ALERT;
    },
    isDismissable() {
      if (this.notification.notificationEventType === notificationEventTypes.VACATION_RESPONSE_REQUIRED) {
        return !this.hasCustodyOrExtendedAccess(this.notification.relatedChildInstitutionProfileId);
      }

      return [
        notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT,
        notificationEventTypes.SOMEONE_ELSE_REMOVED_YOUR_RESPONSE_TO_EVENT,
        notificationEventTypes.INVITED_TO_EVENT_NO_RESPONSE_REQUIRED,
        notificationEventTypes.EVENT_CHANGED_NO_RESPONSE_REQUIRED,
        notificationEventTypes.EVENT_CANCELLED_NO_REASON,
        notificationEventTypes.VACATION_REQUEST_CANCELLED_NO_REASON,
        notificationEventTypes.GRANTED_RIGHTS_FOR_OTHER_CALENDAR,
        notificationEventTypes.REMOVED_RIGHTS_FOR_OTHERS_CALENDAR,
        notificationEventTypes.POST_WAS_DELETED,
        notificationEventTypes.POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN,
        notificationEventTypes.LOST_ROOM_BECAUSE_OF_EXTERNAL_SCHEDULING,
        notificationEventTypes.ADDED_TO_COMMON_INBOX,
        notificationEventTypes.REMOVED_FROM_COMMON_INBOX,
        notificationEventTypes.EVENT_SUBSTITUTE_ADDED,
        notificationEventTypes.EVENT_LESSON_NOTE_CHANGED,
        notificationEventTypes.EVENT_CHANGED_BY_SOMEONE_ELSE,
        notificationEventTypes.EVENT_CANCELLED_BY_SOMEONE_ELSE,
        notificationEventTypes.SINGLE_OCCURRENCE_EVENT_CANCELLED_NO_REASON,
        notificationEventTypes.DELETION_RULES_INFO,
        notificationEventTypes.RESOURCE_RESERVATION_DECLINED,
      ].includes(this.notification.notificationEventType);
    },
    notificationClass() {
      return {
        interactable: this.canSeeMoreInformation,
      };
    },
    type() {
      switch (this.notification.notificationEventType) {
        case notificationEventTypes.EVENT_CANCELLED_NO_REASON:
        case notificationEventTypes.SINGLE_OCCURRENCE_EVENT_CANCELLED_NO_REASON:
        case notificationEventTypes.EVENT_CANCELLED_BY_SOMEONE_ELSE:
          return `${this.$options.filters.fromTextKey('ALERT_CANCELLED')}:`;
        case notificationEventTypes.ADDED_TO_COMMON_INBOX:
          return this.$options.filters.fromTextKey('ALERT_COMMON_INBOX_SHARED');
        case notificationEventTypes.REMOVED_FROM_COMMON_INBOX:
          return this.$options.filters.fromTextKey('ALERT_REMOVED_FROM_COMMON_INBOX');
        case notificationEventTypes.REMOVED_RIGHTS_FOR_OTHERS_CALENDAR:
          return this.$options.filters.fromTextKey('ALERT_RIGHTS_LOST_1');
        case notificationEventTypes.GRANTED_RIGHTS_FOR_OTHER_CALENDAR:
          return this.$options.filters.fromTextKey('ALERT_RIGHTS_GRANTED_1');
        case notificationEventTypes.VACATION_REQUEST_CANCELLED_NO_REASON:
          return this.$options.filters.fromTextKey('ALERT_VACATION_REQUEST');
        case notificationEventTypes.POST_WAS_DELETED:
        case notificationEventTypes.POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN:
          return this.$options.filters.fromTextKey('ALERT_TYPE_POST_WAS_DELETED');
        default:
          return '';
      }
    },
    separator() {
      switch (this.notification.notificationEventType) {
        case notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED:
        case notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED:
        case notificationEventTypes.INVITED_TO_REPEATING_EVENT:
        case notificationEventTypes.INVITED_TO_PERFORMANCE_MEETING:
        case notificationEventTypes.INVITED_TO_PARENTAL_MEETING:
        case notificationEventTypes.INVITED_TO_SCHOOL_HOME_MEETING:
        case notificationEventTypes.VACATION_RESPONSE_REQUIRED:
        case notificationEventTypes.INVITED_TO_EXCEPTION_EVENT:
        case notificationEventTypes.INVITED_TO_SINGLE_OCCURRENCE_OF_EVENT:
        case notificationEventTypes.INVITED_TO_SURVEY:
          return ' -';
        case notificationEventTypes.GENERAL_INFORMATION_SENT:
          return '';
        default:
          return ':';
      }
    },
    title() {
      if (!this.notification.title) {
        return '';
      }

      switch (this.notification.notificationEventType) {
        case notificationEventTypes.EVENT_CHANGED_BY_SOMEONE_ELSE:
        case notificationEventTypes.RESOURCE_RESERVATION_DECLINED:
          return '';
        case notificationEventTypes.GENERAL_INFORMATION_SENT:
          return `${truncate(this.notification.title, { length: 80 })} ${this.separator}`;
        default:
          return `${truncate(this.notification.title)} ${this.separator}`;
      }
    },
    hasDefaultIcon() {
      return (
        notificationEventTypes.INVITED_TO_SURVEY === this.notification.notificationEventType ||
        notificationEventTypes.GENERAL_INFORMATION_SENT === this.notification.notificationEventType
      );
    },
    canSeeMoreInformation() {
      return (
        notificationEventTypes.INVITED_TO_SURVEY === this.notification.notificationEventType ||
        notificationEventTypes.GENERAL_INFORMATION_SENT === this.notification.notificationEventType
      );
    },
    canReadMoreInformation() {
      return notificationEventTypes.DELETION_RULES_INFO === this.notification.notificationEventType;
    },
    canGoToEvent() {
      return notificationEventTypes.RESOURCE_RESERVATION_DECLINED === this.notification.notificationEventType;
    },
    canOpenNotification() {
      return this.canSeeMoreInformation || this.canReadMoreInformation || this.isResponseRequired || this.canGoToEvent;
    },
    isResponseRequired() {
      return [
        notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_REPEATING_EVENT,
        notificationEventTypes.INVITED_TO_PERFORMANCE_MEETING,
        notificationEventTypes.INVITED_TO_PARENTAL_MEETING,
        notificationEventTypes.INVITED_TO_SCHOOL_HOME_MEETING,
        notificationEventTypes.VACATION_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_EXCEPTION_EVENT,
        notificationEventTypes.INVITED_TO_SINGLE_OCCURRENCE_OF_EVENT,
      ].includes(this.notification.notificationEventType);
    },
    content() {
      switch (this.notification.notificationEventType) {
        case notificationEventTypes.ADDED_TO_COMMON_INBOX:
          return this.notification.commonInboxName;
        case notificationEventTypes.INVITED_TO_SURVEY:
          return this.getSurveyContent();
        case notificationEventTypes.EVENT_CANCELLED_NO_REASON:
        case notificationEventTypes.SINGLE_OCCURRENCE_EVENT_CANCELLED_NO_REASON:
        case notificationEventTypes.EVENT_CANCELLED_BY_SOMEONE_ELSE:
          return this.getCancelledEventContent();
        case notificationEventTypes.DELETION_RULES_INFO:
          return this.getDeletionRuleContent();
        case notificationEventTypes.VACATION_REQUEST_CANCELLED_NO_REASON:
          return this.getVacationRequestCancelledNoReasonContent();
        case notificationEventTypes.REMOVED_FROM_COMMON_INBOX:
          return this.getRemovedFromCommonInboxContent();
        case notificationEventTypes.REMOVED_RIGHTS_FOR_OTHERS_CALENDAR:
          return this.getRemovedRightsForOthersCalendarContent();
        case notificationEventTypes.GRANTED_RIGHTS_FOR_OTHER_CALENDAR:
          return this.getGrantedRightsForOtherCalendarContent();
        case notificationEventTypes.POST_WAS_DELETED:
          return this.getPostWasDeletedContent();
        case notificationEventTypes.POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN:
          return this.getPostWasRemovedFromGroupByAdminContent();
        case notificationEventTypes.EVENT_CHANGED_BY_SOMEONE_ELSE:
          return this.getEventChangedBySomeoneElseContent();
        case notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED:
        case notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED:
          return this.getDefaultEventNotificationContent();
        case notificationEventTypes.INVITED_TO_REPEATING_EVENT:
          return this.getInvitedToRepeatingEventContent();
        case notificationEventTypes.RESOURCE_RESERVATION_DECLINED:
          return this.getResourceReservationDeclinedContent();
        default:
          return '';
      }
    },
    moreInformation() {
      if (this.canSeeMoreInformation) {
        return this.$options.filters.fromTextKey('ALERT_SEE_MORE');
      }
      if (this.canReadMoreInformation) {
        return this.$options.filters.fromTextKey('ALERT_READ_MORE');
      }
      if (this.canGoToEvent) {
        return this.$options.filters.fromTextKey('ALERT_GO_TO_EVENT');
      }
      return '';
    },
  },
  methods: {
    emitOpen() {
      if (this.isResponseRequired) {
        return this.$emit('openInfoModal');
      }
      if (!this.canOpenNotification) {
        return;
      }
      this.$emit('open');
    },
    emitDelete() {
      this.$emit('delete');
    },
    getSurveyContent() {
      const prefix = this.$options.filters.fromTextKey('ALERT_EXPIRES');
      const expiryDate = dateUtil.format(this.notification.expires, dateFormatEnum.SHORT_DATE);
      return `${prefix} ${expiryDate}`;
    },
    getCancelledEventContent() {
      const triggeredTime = dateUtil.format(this.notification.triggered, dateFormatEnum.LONG_DATE_TIME);
      const action = this.$options.filters.fromTextKey('ALERT_IS_CANCELLED');
      let startTime = '';
      let excecutor = '';

      if (dateUtil.isValid(this.notification.startTime)) {
        startTime = dateUtil.format(this.notification.startTime, dateFormatEnum.LONG_DATE_TIME_WITH_DATE_PREFIX);
      }
      if (this.notification.cancelledBy) {
        excecutor = `${this.$options.filters.fromTextKey('ALERT_BY')} ${this.notification.cancelledBy}`;
      }

      return `${startTime} ${action} ${excecutor} (${triggeredTime})`.trim();
    },
    getDeletionRuleContent() {
      return this.notification.message;
    },
    getRemovedRightsForOthersCalendarContent() {
      return `${this.notification.otherCalendarPersonName}${this.$options.filters.fromTextKey('ALERT_RIGHTS_LOST_2')}`;
    },
    getGrantedRightsForOtherCalendarContent() {
      return `${this.notification.otherCalendarPersonName}${this.$options.filters.fromTextKey(
        'ALERT_RIGHTS_GRANTED_2'
      )}`;
    },
    getPostWasRemovedFromGroupByAdminContent() {
      return `
      "${truncate(this.notification.postTitle)}"
      ${this.$options.filters.fromTextKey('ALERT_POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN')}
      ${this.notification.groupName}
      `;
    },
    getVacationRequestCancelledNoReasonContent() {
      return this.$options.filters.fromTextKey('ALERT_DELETED');
    },
    getRemovedFromCommonInboxContent() {
      return this.notification.commonInboxName;
    },
    getPostWasDeletedContent() {
      return `
      "${truncate(this.notification.postTitle)}"
      ${this.$options.filters.fromTextKey('ALERT_POST_WAS_DELETED')}
      `;
    },
    getEventChangedBySomeoneElseContent() {
      const triggered = dateUtil.format(this.notification.triggered, dateFormatEnum.SHORT_DATE);
      return this.$options.filters.fromTextKey('ALERT_EVENT_IS_EDITED', {
        title: this.notification.title,
        triggered,
        updatedBy: this.notification.updatedBy,
      });
    },
    getDefaultEventNotificationContent() {
      const { isAllDayEvent, startTime, originalEventEndTime, endTime } = this.notification;

      let startDate: string;
      let formattedEndTime: string;

      if (isAllDayEvent) {
        startDate = dateUtil.utcFormat(startTime, dateFormatEnum.LONG_DATE_TIME);
        formattedEndTime = this.$options.filters.fromTextKey('CALENDAR_LABEL_ALL_DAY_END_TIME');
      } else {
        startDate = dateUtil.format(startTime, dateFormatEnum.LONG_DATE_TIME);
        formattedEndTime = originalEventEndTime
          ? dateUtil.format(originalEventEndTime, dateFormatEnum.SHORT_TIME)
          : dateUtil.format(endTime, dateFormatEnum.SHORT_TIME);
      }

      if (!endTime) {
        return this.$options.filters.fromTextKey('ALERT_DEFAULT_NOTIFICATION_CONTENT_WITHOUT_END_DATE', { startDate });
      }

      const endDate = dateUtil.format(endTime, dateFormatEnum.SHORT_DATE);

      return this.$options.filters.fromTextKey('ALERT_DEFAULT_NOTIFICATION_CONTENT_WITH_END_TIME', {
        startDate,
        endDate,
        endTime: formattedEndTime,
      });
    },
    getInvitedToRepeatingEventContent() {
      const { isAllDayEvent, startTime, originalEventEndTime, endTime } = this.notification;

      const startDateTime = isAllDayEvent
        ? dateUtil.utcFormat(startTime, dateFormatEnum.LONG_DATE_TIME)
        : dateUtil.format(startTime, dateFormatEnum.LONG_DATE_TIME);

      const formattedEndTime = isAllDayEvent
        ? this.$options.filters.fromTextKey('CALENDAR_LABEL_ALL_DAY_END_TIME')
        : dateUtil.format(originalEventEndTime ?? endTime, dateFormatEnum.SHORT_TIME);

      return this.$options.filters.fromTextKey('ALERT_REPEATED_EVENT_NOTIFICATION_CONTENT', {
        startDateTime,
        endTime: formattedEndTime,
        repeatingPattern: this.getRepeatingEventPattern(),
      });
    },
    getRepeatingEventPattern() {
      return RepeatingPattern.getPattern(
        this.notification.repeatingType,
        this.getRepeatingEventLastOccurrenceDate(),
        this.notification.weekdayMask,
        this.notification.dayInMonth,
        this.notification.dailyInterval
      );
    },
    getRepeatingEventLastOccurrenceDate() {
      return this.notification.endTime ? this.getRepeatingEventEndDate() : '';
    },
    getRepeatingEventEndDate() {
      const { isAllDayEvent, endTime } = this.notification;
      return this.$t('calendar.end_at', {
        endDate: isAllDayEvent
          ? dateUtil.utcFormat(endTime, dateFormatEnum.SHORT_DATE_WITHOUT_LEADING_ZERO)
          : dateUtil.format(endTime, dateFormatEnum.SHORT_DATE_WITHOUT_LEADING_ZERO),
      });
    },
    getResourceReservationDeclinedContent() {
      const textReservationDeclined = this.$options.filters.fromTextKey('ALERT_RESOURCE_RESERVATION_DECLINED');
      const dateTime = dateUtil.format(this.notification.startTime, 'dddd [d.] D. MMM[.] YYYY [kl.] LT');
      return `${textReservationDeclined}: ${this.notification.resourceName}. <b>'${this.notification.title}'</b> ${dateTime}`;
    },
  },
};
